import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import { selectedBoxIdsState } from '../../../../../recoil/texteditor/editor/selectors'
import ApplyAllOptions from './ApplyAllOptions'
import ResetOptions from './ResetOptions'
import SentenceBoxOptionController from './SentenceBoxOptionController'

export default function SentenceBoxOption() {
  const selectedSentenceBoxIds = useRecoilValue(selectedBoxIdsState)
  const { t } = useTranslation('project-option')

  const selectedSentencesCount = selectedSentenceBoxIds.length
  return (
    <>
      <div className="flex justify-between">
        <div className="text-headline03">{t('문장 옵션')}</div>
        <ResetOptions />
      </div>
      <div className="text-headline05 my-[12px]">
        <Trans t={t}>
          {/* @ts-ignore */}
          <span className="text-bcblue-500">{{ selectedSentencesCount }}개 문장</span> 선택
        </Trans>
      </div>
      <SentenceBoxOptionController />
      <div className="min-h-[160px]">
        <ApplyAllOptions />
      </div>
    </>
  )
}
