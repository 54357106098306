/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import { twMerge } from 'tailwind-merge'
import reactStringReplace from 'react-string-replace'
import IconArrow from './IconArrow'

type Props = {
  languageGroup: string
  languages: string[]
  searchKeyword: string
  scrollToOffset?: number
}

export default function LanguageToggle({ languageGroup, languages, searchKeyword, scrollToOffset = 200 }: Props) {
  const [opened, setOpened] = useState(false)

  const scrollIntoLanguage = (viewId: string) => {
    const voiceArea = document.getElementById('voice-area')
    const scrollView = document.getElementById(viewId)

    if (voiceArea && scrollView) voiceArea?.scrollTo({ top: scrollView.offsetTop - scrollToOffset })
  }

  return (
    <>
      <button
        type="button"
        className="text-list text-gs01-800 flex h-[41px] w-full items-center justify-between overflow-hidden px-[20px] py-[12px]"
        onClick={() => setOpened((prev) => !prev)}
      >
        <span>{languageGroup}</span>
        <IconArrow className={twMerge(!opened && 'rotate-180')} />
      </button>
      {opened && (
        <div className="flex max-h-[262px] flex-col justify-start overflow-auto p-[8px]">
          {languages.map((language) => (
            <button
              key={language}
              type="button"
              className="text-list text-gs01-500 max-h-[41px] min-h-[41px] overflow-x-hidden text-ellipsis whitespace-nowrap px-[32px] py-[12px] text-left hover:bg-blue-50"
              onClick={() => scrollIntoLanguage(`scroll-${language}`)}
              title={language}
            >
              {reactStringReplace(language, searchKeyword, (match) => (
                <span className="bg-blue-100">{match}</span>
              ))}
            </button>
          ))}
        </div>
      )}
    </>
  )
}
