import React from 'react'

export default function IconStar() {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.89981 18.2834C5.71647 18.2834 5.54147 18.225 5.38314 18.1167C5.06647 17.8834 4.94147 17.475 5.05814 17.1L6.62481 12.05L2.52481 8.92503C2.21647 8.6917 2.09147 8.28337 2.20814 7.90837C2.32481 7.53337 2.66647 7.28337 3.04981 7.28337H8.09981L9.65814 2.25003C9.77481 1.87503 10.1165 1.6167 10.4998 1.6167C10.8831 1.6167 11.2248 1.8667 11.3415 2.25003L12.8998 7.28337H17.9498C18.3331 7.28337 18.6665 7.53337 18.7915 7.90837C18.9081 8.28337 18.7915 8.6917 18.4831 8.92503L14.3831 12.05L15.9498 17.1C16.0665 17.475 15.9331 17.8834 15.6248 18.1167C15.3081 18.35 14.8915 18.3417 14.5831 18.1084L10.5081 15.0084L6.43314 18.1167C6.27481 18.2334 6.09147 18.3 5.90814 18.3L5.89981 18.2834ZM10.4998 13.6584C10.6831 13.6584 10.8665 13.7167 11.0248 13.8417L14.4581 16.4584L13.1331 12.1834C13.0165 11.8167 13.1415 11.4084 13.4498 11.175L16.9331 8.52503H12.6498C12.2665 8.52503 11.9248 8.27503 11.8081 7.8917L10.4915 3.65003L9.17481 7.8917C9.05814 8.2667 8.71647 8.52503 8.33314 8.52503H4.04981L7.52481 11.175C7.82481 11.4084 7.95814 11.8084 7.84147 12.1834L6.51647 16.45L9.95814 13.8334C10.1165 13.7167 10.2998 13.65 10.4831 13.65L10.4998 13.6584Z"
        fill="#9CAEBE"
      />
    </svg>
  )
}
