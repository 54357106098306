import {useState} from 'react'
import {useRecoilValue} from 'recoil'
import {motion} from 'framer-motion'
import Switch from 'react-switch'
import {isAvatarEditorShowState} from '../../../recoil/navigator/selectors'
import AvatarPlay from './AvatarPlay'
import {currentSelectedCharacterState} from "../../../recoil/workspace/selectors";

function AvatarEditor() {
  /** Recoils */
  const isAvatarEditorShow = useRecoilValue(isAvatarEditorShowState)
  const currentSelectedCharacter = useRecoilValue(currentSelectedCharacterState)

  const [checked, setChecked] = useState(true)

  const isSelfCustomAvatar = currentSelectedCharacter?.styleType === 'SELF_CUSTOM'

  const handleChange = (nextChecked) => {
    if (!isSelfCustomAvatar) {
      setChecked(nextChecked)
    }
  }

  return (
    <motion.section
      animate={{
        minWidth: isAvatarEditorShow ? '400px' : 0,
        width: isAvatarEditorShow ? '400px' : 0,
        marginLeft: isAvatarEditorShow ? '20px' : 0,
      }}
      className={`relative h-full min-w-0 overflow-hidden rounded-[24px] bg-[#FAFAFB] drop-shadow-xl ${
        isAvatarEditorShow ? 'opacity-100' : 'opacity-0'
      }`}
    >
      <div className="absolute right-[17px] top-[15px] z-[10] flex w-fit">
        <Switch
          onChange={handleChange}
          checked={isSelfCustomAvatar ? false : checked}
          disabled={isSelfCustomAvatar}
          uncheckedHandleIcon={
            <div className="flex h-full w-full items-center justify-center text-[10px] font-bold text-[#CACACA]">
              OFF
            </div>
          }
          checkedHandleIcon={
            <div className="main_c flex h-full w-full items-center justify-center text-[10px] font-bold">ON</div>
          }
          onColor="#3a74f5"
          offColor="#CACACA"
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          height={26}
          width={48}
        />
      </div>
      <AvatarPlay checked={isSelfCustomAvatar ? false : checked}/>
    </motion.section>
  )
}

export default AvatarEditor
