import React from 'react'

export default function IconInfo() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99992 1.45837C5.28325 1.45837 1.45825 5.28337 1.45825 10C1.45825 14.7167 5.28325 18.5417 9.99992 18.5417C14.7166 18.5417 18.5416 14.7167 18.5416 10C18.5416 5.28337 14.7166 1.45837 9.99992 1.45837ZM10.9166 14.0334C10.9166 14.4917 10.5416 14.8667 10.0833 14.8667C9.62492 14.8667 9.24992 14.4917 9.24992 14.0334V8.31671C9.24992 7.85837 9.62492 7.48337 10.0833 7.48337C10.5416 7.48337 10.9166 7.85837 10.9166 8.31671V14.0334ZM10.6749 6.05837C10.5166 6.21671 10.2999 6.30004 10.0833 6.30004C9.97492 6.30004 9.86659 6.28337 9.76659 6.24171C9.65825 6.20004 9.56659 6.13337 9.49159 6.05837C9.41659 5.98337 9.35825 5.89171 9.31659 5.79171C9.27492 5.68337 9.24992 5.57504 9.24992 5.46671C9.24992 5.25004 9.34159 5.03337 9.49159 4.88337C9.80825 4.56671 10.3666 4.56671 10.6749 4.88337C10.8249 5.03337 10.9166 5.25004 10.9166 5.46671C10.9166 5.68337 10.8249 5.90004 10.6749 6.05837Z"
        fill="#2F333C"
      />
    </svg>
  )
}
