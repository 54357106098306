import React from 'react'

export default function IconPlus() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.7167 9.15835H10.8333V2.27501C10.8333 1.81668 10.4583 1.44168 10 1.44168C9.54168 1.44168 9.16668 1.81668 9.16668 2.27501V9.15835H2.28335C1.82501 9.15835 1.45001 9.53335 1.45001 9.99168C1.45001 10.45 1.82501 10.825 2.28335 10.825H9.16668V17.7083C9.16668 18.1667 9.54168 18.5417 10 18.5417C10.4583 18.5417 10.8333 18.1667 10.8333 17.7083V10.825H17.7167C18.175 10.825 18.55 10.45 18.55 9.99168C18.55 9.53335 18.175 9.15835 17.7167 9.15835Z"
        fill="white"
      />
    </svg>
  )
}
