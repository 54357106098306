/* eslint-disable @typescript-eslint/no-unused-vars */
import { useRef, useState } from 'react'
import { Popover } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import useAivatarModal from '../../../../../hooks/useAivatarModal'
import ExportModal from './ExportModal'
import { ReactComponent as ExportIcon } from '../../../../../assets/images/icon-export.svg'
import useUpdateProject from '../../../../../services/project/useUpdateProject'
import { useCurrentWorkspaceDetailQuery } from '../../../../../hooks/queries'
import useDialog from '../../../../../hooks/useDialog'
import SubscriptionPlanDialog from '../../../../../components/SubscriptionPlan/SubscriptionPlanDialog'
import AivatarButton from '../../../../../components/buttons/AivatarButton'
import { editorValidationState } from '../../../../../recoil/texteditor/editor/selectors'
// import CreditModal from "./CreditModal";

function ExportItem({ title, type, handleClose, openExportModal }) {
  const { fetchProject } = useUpdateProject()
  const { data: workspace } = useCurrentWorkspaceDetailQuery()
  const dialog = useDialog()
  const onClickExportItem = () => {
    if (type === 'voice' && workspace?.subscription?.plan?.name === 'FREE') {
      dialog.open(<SubscriptionPlanDialog expanded />)
    } else {
      fetchProject()
      openExportModal()
    }

    handleClose()
  }
  return (
    <button
      type="button"
      className="w-full cursor-pointer rounded-md py-[8px] pl-[15px] text-left font-normal hover:bg-[#F5F7FE] hover:text-[#3A74F5]"
      onClick={onClickExportItem}
    >
      {title}
    </button>
  )
}

function ExportButton() {
  const { t } = useTranslation('project')
  const wrapperRef = useRef(null)
  const dialog = useDialog()

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const editorValidation = useRecoilValue(editorValidationState)

  const { data: workspace } = useCurrentWorkspaceDetailQuery()

  const handleModalClose = () => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    closeExportAudioModal()
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    closeExportVideoModal()
  }
  /** Modal */
  const {
    modalComponent: ExportAudioModalComponent,
    openModal: openExportAudioModal,
    closeModal: closeExportAudioModal,
  } = useAivatarModal({
    children: <ExportModal type="AUDIO" handleModalClose={handleModalClose} />,
    closeOnDocumentClick: false,
    contentStyle: { width: 388, padding: 40 },
  })

  const {
    modalComponent: ExportVideoModalComponent,
    openModal: openExportVideoModal,
    closeModal: closeExportVideoModal,
  } = useAivatarModal({
    children: <ExportModal type="VIDEO" handleModalClose={handleModalClose} />,
    closeOnDocumentClick: false,
    contentStyle: { width: 388, padding: 40 },
  })

  /** Recoils */

  /** Event Handlers */
  const handleClick = () => {
    if (workspace?.subscription?.status === 'PAUSE') {
      dialog.open(<SubscriptionPlanDialog expanded />)
    } else {
      setAnchorEl(wrapperRef.current)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  /** Memos */

  return (
    <div ref={wrapperRef}>
      {ExportAudioModalComponent}
      {ExportVideoModalComponent}
      <AivatarButton type="button" variant="m1" onClick={handleClick} disabled={!editorValidation.valid}>
        <span className="mr-[5px]">{t('내보내기')}</span>
        <ExportIcon />
      </AivatarButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          top: '5px',
          zIndex: '2001',
        }}
        PaperProps={{
          sx: {
            borderRadius: '10px',
            width: '200px',
            boxShadow: '1px 3px 17px 0px rgba(0, 40, 104, 0.18)',
          },
        }}
      >
        <div className="aivatar_scroll_wrapper flex w-full flex-col overflow-hidden overflow-y-auto rounded-md p-[10px]">
          <ExportItem
            title={t('음성 내보내기')}
            type="voice"
            handleClose={handleClose}
            openExportModal={openExportAudioModal}
          />
          <ExportItem
            title={t('영상 내보내기')}
            type="video"
            handleClose={handleClose}
            openExportModal={openExportVideoModal}
          />
        </div>
      </Popover>
    </div>
  )
}

export default ExportButton
