import React from 'react'

export default function IconWarning() {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.0003 3.7915C13.737 3.7915 3.79199 13.7365 3.79199 25.9998C3.79199 38.2632 13.737 48.2082 26.0003 48.2082C38.2637 48.2082 48.2087 38.2632 48.2087 25.9998C48.2087 13.7365 38.2637 3.7915 26.0003 3.7915ZM26.997 13.2165C27.387 13.2165 27.712 13.5415 27.712 13.9532L27.4737 31.4165H24.527L24.2887 13.9532C24.2887 13.5632 24.6137 13.2165 25.0037 13.2165H26.9753H26.997ZM26.0003 38.7832C24.7653 38.7832 23.7253 37.7648 23.7687 36.5082C23.7253 35.2732 24.787 34.2765 26.0003 34.2765C27.2137 34.2765 28.232 35.2948 28.232 36.5082C28.232 37.7215 27.2137 38.7832 26.0003 38.7832Z"
        fill="#001F54"
      />
    </svg>
  )
}
