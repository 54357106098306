import React from 'react'

export default function IconClose() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.84 4.73L12 10.57L6.16002 4.73C5.77002 4.34 5.14002 4.34 4.75002 4.73C4.36002 5.12 4.36002 5.75 4.75002 6.14L10.59 11.98L4.75002 17.82C4.36002 18.21 4.36002 18.84 4.75002 19.23C5.14002 19.62 5.77002 19.62 6.16002 19.23L12 13.39L17.84 19.23C18.23 19.62 18.86 19.62 19.25 19.23C19.64 18.84 19.64 18.21 19.25 17.82L13.41 11.98L19.25 6.14C19.64 5.75 19.64 5.12 19.25 4.73C18.86 4.34 18.23 4.34 17.84 4.73Z"
        fill="#485060"
      />
    </svg>
  )
}
