import { useParams } from 'react-router-dom'
import { useRecoilCallback, useRecoilState } from 'recoil'
import useAudioControllerCallbacks from '../../recoil/audiocontroller/useAudioControllerCallbacks'
import {
  AUDIO_CRATE_STATUS,
  sentenceBoxAudioState,
  SentenceBoxValuesForFetch,
} from '../../recoil/texteditor/sentenceBox/atoms'

import AivatarClient from '../AivatarClient'
import { CALL_STATE } from '../constants'

export default function useUpdateAudio() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { workspaceId, projectId } = useParams()
  /** Recoils */

  const [sentenceBoxValues, setSentenceBoxValues] = useRecoilState(SentenceBoxValuesForFetch)

  const { getAudioUpdateBody, setAudioById } = useAudioControllerCallbacks()

  const fetch = useRecoilCallback(({ snapshot }) => async ({ sentenceBoxIds }) => {
    //! 이미
    const alreadyCreatedAudioBoxIds = sentenceBoxIds.filter((id) => {
      const audioBox = snapshot.getLoadable(sentenceBoxAudioState(id)).getValue()
      return audioBox.audioId !== null
    })
    if (alreadyCreatedAudioBoxIds.length === 0) {
      return
    }

    const body = getAudioUpdateBody({ sentenceBoxIds: alreadyCreatedAudioBoxIds, projectId })

    try {
      alreadyCreatedAudioBoxIds.forEach((id) => {
        setAudioById({
          id,
          newValues: {
            apiState: CALL_STATE.FETCHING,
          },
        })
      })
      const res = await AivatarClient().patch(`audios`, body)
      const { data, status } = res
      // setSentenceBoxValues({ ...sentenceBoxValues , space: });
      if (status !== 200) throw new Error('Invalid Response Status')
      // TODO 나중에 setSentenceBoxValues(마지막 건든 속성 저장용)를 여기로 집중시켜야된다. 너무 많은 부분에서 set을 진행하고있다.
      setSentenceBoxValues({
        ...sentenceBoxValues,
        voiceId: data[0].voiceId,
        language: data[0].language,
        pitch: data[0].pitch,
        space: data[0].space,
        speed: data[0].speed,
        volume: data[0].volume,
      })
      alreadyCreatedAudioBoxIds.forEach((id, index) => {
        const updatedAudio = data[index]
        const { audioId, status: audioStatus } = updatedAudio
        setAudioById({
          id,
          newValues: {
            apiState: CALL_STATE.NEW,
            audioId,
            // TODO 백엔드에서 READY 반환해줘야됨
            status: audioStatus,
            url: null,
          },
        })
      })
    } catch (error) {
      alreadyCreatedAudioBoxIds.forEach((id) => {
        setAudioById({
          id,
          newValues: {
            apiState: CALL_STATE.ERROR,
            audioId: null,
            status: AUDIO_CRATE_STATUS.READY,
            url: null,
          },
        })
      })
    }
  })

  // const fetch = async ({ sentenceBoxIds }) => {
  //   const body = getAudioUpdateBody({ sentenceBoxIds, projectId })

  //   try {
  //     sentenceBoxIds.forEach((id) => {
  //       setAudioById({
  //         id,
  //         newValues: {
  //           apiState: CALL_STATE.FETCHING,
  //         },
  //       })
  //     })
  //     const res = await AivatarClient().patch(`audios`, body)
  //     const { data, status } = res
  //     // setSentenceBoxValues({ ...sentenceBoxValues , space: });
  //     if (status !== 200) throw new Error('Invalid Response Status')
  //     // TODO 나중에 setSentenceBoxValues(마지막 건든 속성 저장용)를 여기로 집중시켜야된다. 너무 많은 부분에서 set을 진행하고있다.
  //     setSentenceBoxValues({
  //       ...sentenceBoxValues,
  //       voiceId: data[0].voiceId,
  //       language: data[0].language,
  //       pitch: data[0].pitch,
  //       space: data[0].space,
  //       speed: data[0].speed,
  //       volume: data[0].volume,
  //     })
  //     sentenceBoxIds.forEach((id, index) => {
  //       const updatedAudio = data[index]
  //       const { audioId, status: audioStatus } = updatedAudio
  //       setAudioById({
  //         id,
  //         newValues: {
  //           apiState: CALL_STATE.NEW,
  //           audioId,
  //           // TODO 백엔드에서 READY 반환해줘야됨
  //           status: audioStatus,
  //           url: null,
  //         },
  //       })
  //     })
  //   } catch (error) {
  //     sentenceBoxIds.forEach((id) => {
  //       setAudioById({
  //         id,
  //         newValues: {
  //           apiState: CALL_STATE.ERROR,
  //           audioId: null,
  //           status: AUDIO_CRATE_STATUS.READY,
  //           url: null,
  //         },
  //       })
  //     })
  //   }
  // }

  return {
    fetchAudioUpdate: fetch,
  }
}
