import React from 'react'

export default function IconClose() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.99986 1.18665C4.23319 1.18665 1.18652 4.23331 1.18652 7.99998C1.18652 11.7666 4.23986 14.8133 7.99986 14.8133C11.7599 14.8133 14.8132 11.76 14.8132 7.99998C14.8132 4.23998 11.7665 1.18665 7.99986 1.18665ZM11.4465 10.7733C11.6332 10.96 11.6332 11.26 11.4465 11.4466C11.2599 11.6333 10.9599 11.6333 10.7732 11.4466L7.99986 8.67331L5.22652 11.4466C5.03986 11.6333 4.73986 11.6333 4.55319 11.4466C4.36652 11.26 4.36652 10.96 4.55319 10.7733L7.32652 7.99998L4.55319 5.22665C4.36652 5.03998 4.36652 4.73998 4.55319 4.55331C4.73986 4.36665 5.03986 4.36665 5.22652 4.55331L7.99986 7.32665L10.7732 4.55331C10.9599 4.36665 11.2599 4.36665 11.4465 4.55331C11.6332 4.73998 11.6332 5.03998 11.4465 5.22665L8.67319 7.99998L11.4465 10.7733Z"
        fill="#576076"
      />
    </svg>
  )
}
