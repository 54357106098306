import React from 'react'

export default function IconLoading({ className }: { className: string }) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        opacity="0.44"
        d="M30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15ZM2.25 15C2.25 22.0416 7.95837 27.75 15 27.75C22.0416 27.75 27.75 22.0416 27.75 15C27.75 7.95837 22.0416 2.25 15 2.25C7.95837 2.25 2.25 7.95837 2.25 15Z"
        fill="white"
      />
      <path
        d="M1.125 15C0.50368 15 -0.00439047 15.5044 0.042161 16.124C0.235321 18.6948 1.08861 21.1794 2.52796 23.3336C4.17618 25.8003 6.51886 27.7229 9.25975 28.8582C12.0006 29.9935 15.0166 30.2906 17.9264 29.7118C20.8361 29.133 23.5088 27.7044 25.6066 25.6066C27.7044 23.5088 29.133 20.8361 29.7118 17.9264C30.2906 15.0166 29.9935 12.0006 28.8582 9.25975C27.7229 6.51886 25.8003 4.17618 23.3336 2.52796C21.1794 1.08862 18.6948 0.235322 16.124 0.0421612C15.5044 -0.0043904 15 0.50368 15 1.125C15 1.74632 15.5047 2.24485 16.1236 2.2996C18.2489 2.4876 20.2995 3.20674 22.0835 4.39876C24.1802 5.79975 25.8144 7.79103 26.7795 10.1208C27.7445 12.4505 27.997 15.0141 27.505 17.4874C27.0131 19.9607 25.7987 22.2325 24.0156 24.0156C22.2325 25.7987 19.9607 27.0131 17.4874 27.505C15.0141 27.997 12.4505 27.7445 10.1208 26.7795C7.79103 25.8144 5.79975 24.1802 4.39876 22.0835C3.20674 20.2995 2.4876 18.2489 2.29959 16.1236C2.24485 15.5047 1.74632 15 1.125 15Z"
        fill="white"
      />
    </svg>
  )
}
