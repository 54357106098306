/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation } from '@tanstack/react-query'
import { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { voiceConversion } from '../../../../../../../../services/audio'
import CustomsMain from './1-main'
import CustomsWriteInfo from './2-writeInfo'
import CustomsUploadFile from './3-uploadFile'
import CustomsNavigation from './components/CustomsNavigation'

type Page = 'main' | 'writeInfo' | 'uploadFile'
type Data = {
  name: string
  gender: 'male' | 'female'
  denoising: number
  userFile: File | undefined
}

export const CustomsContext = createContext({
  data: {
    name: '',
    gender: 'female' as 'male' | 'female',
    denoising: 0.5,
    userFile: undefined as File | undefined,
  },
  changeData: (data: Data) => {},
  page: 'main' as Page,
  goPrev: () => {},
  goNext: () => {},
  changePage: (page: Page) => {},
  canProceed: false,
  changeCanProceed: (canProceed: boolean) => {},
})

export default function Customs() {
  const { workspaceId } = useParams()

  const [data, setData] = useState<Data>({
    name: '',
    gender: 'female',
    denoising: 0.3,
    userFile: undefined,
  })
  const [page, setPage] = useState<Page>('main')
  const [canProceed, setCanProceed] = useState(false)

  const { mutate, isPending } = useMutation({ mutationFn: voiceConversion })

  const goPrev = useCallback(() => {
    if (page === 'writeInfo') setPage('main')
    if (page === 'uploadFile') setPage('writeInfo')
  }, [page])

  const goNext = useCallback(() => {
    if (page === 'main') setPage('writeInfo')
    if (page === 'writeInfo') setPage('uploadFile')
    if (page === 'uploadFile') {
      // API 호출
    }
  }, [page])

  const contextValue = useMemo(
    () => ({
      page,
      changePage: setPage,
      goPrev,
      goNext,
      canProceed,
      changeCanProceed: setCanProceed,
      data,
      changeData: setData,
    }),
    [goNext, goPrev, page, canProceed, data],
  )

  const submit = () => {
    console.log('seop vc submit', data)
    mutate(
      {
        workspaceId: Number(workspaceId!),
        name: data.name,
        gender: data.gender,
        denosing: data.denoising,
        userFile: data.userFile!,
      },
      {
        onSuccess: () => {
          setPage('main')
        },
      },
    )
  }

  return (
    <CustomsContext.Provider value={contextValue}>
      <div className="relative h-full">
        <CustomsNavigation onComplete={submit} isPending={isPending} />
        {
          {
            main: <CustomsMain onStartCreateVoice={() => setPage('writeInfo')} />,
            writeInfo: <CustomsWriteInfo />,
            uploadFile: <CustomsUploadFile />,
          }[page]
        }
      </div>
    </CustomsContext.Provider>
  )
}
