import React from 'react'

export default function IconMinus() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0617 7.5455H1.94902C1.64902 7.5455 1.40356 7.30004 1.40356 7.00004C1.40356 6.70004 1.64902 6.45459 1.94902 6.45459H12.0617C12.3617 6.45459 12.6072 6.70004 12.6072 7.00004C12.6072 7.30004 12.3617 7.5455 12.0617 7.5455Z"
        fill="white"
      />
    </svg>
  )
}
