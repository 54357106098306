import React from 'react'

export default function IconCustomBadge() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="16" rx="2" fill="url(#paint0_linear_822_2204)" />
      <path
        d="M13.0898 6.89006C13.0848 6.86506 13.0798 6.84006 13.0698 6.81506C13.0648 6.80506 13.0548 6.79506 13.0498 6.78006C13.0348 6.75006 13.0198 6.72506 12.9998 6.69506C12.9948 6.68506 12.9898 6.68006 12.9848 6.67006C12.9748 6.65506 12.9648 6.63506 12.9498 6.62506L10.6598 4.33506C10.5948 4.27006 10.5148 4.23506 10.4298 4.21506C10.3748 4.19506 10.3198 4.18506 10.2598 4.18506H5.6698C5.6048 4.18506 5.5498 4.20006 5.4898 4.22006C5.4748 4.22506 5.4598 4.23506 5.4398 4.24506C5.3998 4.26506 5.3648 4.29006 5.3298 4.32006C5.3198 4.33006 5.3098 4.33006 5.2998 4.34006L3.0098 6.63006C2.9598 6.68006 2.9248 6.74006 2.8998 6.80506C2.8998 6.80506 2.8948 6.81006 2.8948 6.81506C2.8948 6.82506 2.8948 6.83006 2.8898 6.84006C2.8798 6.86506 2.8748 6.89506 2.8698 6.92006C2.8398 7.08506 2.8848 7.26006 3.0098 7.38506L7.6098 11.9901C7.7098 12.0901 7.8398 12.1451 7.9798 12.1451C8.1198 12.1451 8.2498 12.0901 8.3498 11.9901L12.9548 7.38506C13.0848 7.25506 13.1298 7.07006 13.0898 6.89506V6.89006ZM6.4198 7.65006H9.5198L8.0098 10.8451L7.9748 10.8801L7.9048 10.8101L6.4148 7.65506L6.4198 7.65006ZM9.2548 6.47006L10.2898 5.43506L11.3248 6.47006H9.2498H9.2548ZM8.9998 5.22006L7.9598 6.26006L7.3448 5.64506L6.9198 5.22006H8.9948H8.9998ZM5.6698 5.43506L6.7098 6.47006H4.6348L5.6698 5.43506Z"
        fill="white"
      />
      <defs>
        <linearGradient id="paint0_linear_822_2204" x1="14" y1="16" x2="4.5" y2="2.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#18C95D" />
          <stop offset="1" stopColor="#0086FF" />
        </linearGradient>
      </defs>
    </svg>
  )
}
