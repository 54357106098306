/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactNode, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { twMerge } from 'tailwind-merge'
import { ReactComponent as IconPlanPro } from '../../assets/images/plan-pro.svg'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ReactComponent as IconPlanBasic } from '../../assets/images/plan-basic.svg'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ReactComponent as IconPlanEnterprise } from '../../assets/images/plan-enterprise.svg'
import { ReactComponent as IconPlanLabel } from '../../../assets/images/icon-plan-label.svg'
import AivatarClient from '../../../services/AivatarClient'
import PriceFormat from '../../Text/PriceFormater'
import { getPlanColor } from '../../../utils/colors'
import { useCurrentWorkspaceDetailQuery } from '../../../hooks/queries'
import CheckoutLink from './CheckoutLink'
import IconCheckGray from './IconCheckGray'
import ColumnHeader from './ColumnHeader'
import RowHeader from './RowHeader'
import Data from './Data'
import ContactLink from './ContactLink'
import { BasicPlanName, ProPlanName } from '../../../@types/common'

const FREE_VIDEO_MINUTE = 1

export default function SubscriptionPlanFeatureSheet({ className = '' }) {
  const [plans, setPlans] = useState<any[]>([])
  const { data: currentWorkspace } = useCurrentWorkspaceDetailQuery()
  const { t } = useTranslation('plan-info-modal')
  const isPause = currentWorkspace?.subscription?.status === 'PAUSE'

  const [selectedBasicPlanName, setSelectedBasicPlanName] = useState<BasicPlanName>('BASIC-1')
  const [selectedProPlanName, setSelectedProPlanName] = useState<ProPlanName>('PRO-1')

  useEffect(() => {
    if (currentWorkspace) {
      if (currentWorkspace?.subscription?.plan?.name?.startsWith('BASIC-')) {
        setSelectedBasicPlanName(currentWorkspace?.subscription?.plan?.name)
      }
      if (currentWorkspace?.subscription?.plan?.name?.startsWith('PRO-')) {
        setSelectedProPlanName(currentWorkspace?.subscription?.plan?.name)
      }
    }
  }, [currentWorkspace])

  const rows = useMemo<[string, ReactNode, ReactNode, ReactNode, ReactNode][]>(
    () => [
      // [
      //   '월 구독료',
      //   <span>
      //     <b>0원</b>
      //   </span>,
      //   <span>
      //     <b>{{ 'BASIC-1': '29,000', 'BASIC-2': '89,000' }[selectedBasicPlanName]}원</b>
      //     /월
      //   </span>,
      //   <span>
      //     <b>{{ 'PRO-1': '99,000', 'PRO-2': '290,000', 'PRO-3': '560,000' }[selectedProPlanName]}원</b>
      //     /월
      //   </span>,
      //   <div className="grid h-full w-full place-items-center bg-yellow-50">
      //     <b>{t('별도 문의',)}</b>
      //   </div>,
      // ],
      [
        t('크레딧'),
        <b>{t('{{credits}} 크레딧/1회', { credits: 600 })}</b>,
        <div className="border-gs01-400 flex w-fit rounded-[500px] border p-[4px]">
          <button
            onClick={() => setSelectedBasicPlanName('BASIC-1')}
            className={twMerge(
              'text-gs01-400 grid h-[24px] place-items-center whitespace-nowrap px-[12px]',
              selectedBasicPlanName === 'BASIC-1' && 'bg-bcblue-500 rounded-[50px] font-[500] text-white',
            )}
          >
            <span className="text-button02">
              1,800 {`${t('크레딧')}/${t('월', { ns: 'period', context: 'short' })}`}
            </span>
          </button>
          <button
            onClick={() => setSelectedBasicPlanName('BASIC-2')}
            className={twMerge(
              'text-gs01-400 grid h-[24px] place-items-center whitespace-nowrap px-[12px]',
              selectedBasicPlanName === 'BASIC-2' && 'bg-bcblue-500 rounded-[50px] font-[500] text-white',
            )}
          >
            <span className="text-button02">
              6,000 {`${t('크레딧')}/${t('월', { ns: 'period', context: 'short' })}`}
            </span>
          </button>
        </div>,
        <div className="border-gs01-400 flex w-fit rounded-[500px] border p-[4px]">
          <button
            onClick={() => setSelectedProPlanName('PRO-1')}
            className={twMerge(
              'text-gs01-400 grid h-[24px] place-items-center whitespace-nowrap px-[12px]',
              selectedProPlanName === 'PRO-1' && 'bg-bcblue-500 rounded-[50px] font-[500] text-white',
            )}
          >
            <span className="text-button02">
              6,000 {`${t('크레딧')}/${t('월', { ns: 'period', context: 'short' })}`}
            </span>
          </button>
          <button
            onClick={() => setSelectedProPlanName('PRO-2')}
            className={twMerge(
              'text-gs01-400 grid h-[24px] place-items-center whitespace-nowrap px-[12px]',
              selectedProPlanName === 'PRO-2' && 'bg-bcblue-500 rounded-[50px] font-[500] text-white',
            )}
          >
            <span className="text-button02">
              18,000 {`${t('크레딧')}/${t('월', { ns: 'period', context: 'short' })}`}
            </span>
          </button>
          <button
            onClick={() => setSelectedProPlanName('PRO-3')}
            className={twMerge(
              'text-gs01-400 grid h-[24px] place-items-center whitespace-nowrap px-[12px]',
              selectedProPlanName === 'PRO-3' && 'bg-bcblue-500 rounded-[50px] font-[500] text-white',
            )}
          >
            <span className="text-button02">
              36,000 {`${t('크레딧')}/${t('월', { ns: 'period', context: 'short' })}`}
            </span>
          </button>
        </div>,
        <div className="grid h-full w-full place-items-center bg-yellow-50">
          <b>{t('별도 문의', { context: 'flexible' })}</b>
        </div>,
      ],
      [
        t('사용시간'),
        <>
          <span className="text-[14px]">{t('영상 {{minutes}}분', { minutes: 1, count: 1 })}</span>
        </>,
        <>
          <span className="text-[14px]">
            {
              {
                'BASIC-1': t('영상 {{video_minutes}}분 또는 음성 {{audio_minutes}}분', {
                  video_minutes: 3,
                  audio_minutes: 30,
                }),
                'BASIC-2': t('영상 {{video_minutes}}분 또는 음성 {{audio_minutes}}분', {
                  video_minutes: 10,
                  audio_minutes: 100,
                }),
              }[selectedBasicPlanName]
            }
          </span>
        </>,
        <>
          <span className="text-[14px]">
            {
              {
                'PRO-1': t('영상 {{video_minutes}}분 또는 음성 {{audio_minutes}}분', {
                  video_minutes: 10,
                  audio_minutes: 100,
                }),
                'PRO-2': t('영상 {{video_minutes}}분 또는 음성 {{audio_minutes}}분', {
                  video_minutes: 30,
                  audio_minutes: 300,
                }),
                'PRO-3': t('영상 {{video_minutes}}분 또는 음성 {{audio_minutes}}분', {
                  video_minutes: 60,
                  audio_minutes: 600,
                }),
              }[selectedProPlanName]
            }
          </span>
        </>,
        <div className="grid h-full w-full place-items-center bg-yellow-50">
          <b>{t('별도 문의', { context: 'flexible' })}</b>
        </div>,
      ],
      [t('아바타'), <>5</>, <>5</>, <>140</>, <>140+</>],
      [t('제스처'), <>1</>, <>1</>, <span>{t('전체')}</span>, <span>{t('전체')}</span>],
      [
        t('프라임 보이스'),
        <>-</>,
        <>-</>,
        <>
          <IconCheckGray />
        </>,
        <>
          <IconCheckGray />
        </>,
      ],
      [
        t('맞춤형 고객지원'),
        <>-</>,
        <>-</>,
        <>
          <IconCheckGray />
        </>,
        <>
          <IconCheckGray />
        </>,
      ],
      [
        t('팀 워크스페이스'),
        <>-</>,
        <>-</>,
        <>-</>,
        <>
          <IconCheckGray />
        </>,
      ],
      [
        t('상업적 이용'),
        <>-</>,
        <>
          <IconCheckGray />
        </>,
        <>
          <IconCheckGray />
        </>,
        <>
          <IconCheckGray />
        </>,
      ],
      [
        t('워터마크 제거'),
        <>-</>,
        <>
          <IconCheckGray />
        </>,
        <>
          <IconCheckGray />
        </>,
        <>
          <IconCheckGray />
        </>,
      ],
      [
        t('무제한 다운로드'),
        <>
          <IconCheckGray />
        </>,
        <>
          <IconCheckGray />
        </>,
        <>
          <IconCheckGray />
        </>,
        <>
          <IconCheckGray />
        </>,
      ],
      [
        '💡Select Avatar',
        <>-</>,
        <>-</>,
        <>
          <b>{t('별도 문의')}</b>
        </>,
        <>
          <div className="grid h-full w-full place-items-center bg-yellow-50">
            <b>{t('별도 문의')}</b>
          </div>
        </>,
      ],
      [
        '💡AI Mascot',
        <>-</>,
        <>-</>,
        <>
          <b>{t('별도 문의')}</b>
        </>,
        <>
          <div className="grid h-full w-full place-items-center bg-yellow-50">
            <b>{t('별도 문의')}</b>
          </div>
        </>,
      ],
      [
        '💡Custom Avatar',
        <>-</>,
        <>-</>,
        <>-</>,
        <>
          <div className="grid h-full w-full place-items-center bg-yellow-50">
            <b>{t('별도 문의')}</b>
          </div>
        </>,
      ],
      [
        '💡Custom Voice',
        <>-</>,
        <>-</>,
        <>-</>,
        <>
          <div className="grid h-full w-full place-items-center bg-yellow-50">
            <b>{t('별도 문의')}</b>
          </div>
        </>,
      ],
    ],
    [selectedBasicPlanName, selectedProPlanName, t],
  )

  useEffect(() => {
    AivatarClient()
      .get('/plans')
      .then(({ data }) => {
        setPlans(_.sortBy(data.content, 'level') as any)
      })
  }, [])

  const valuePrice = (amount: number) => {
    if (amount === 0) {
      return t('무료')
    }

    return <PriceFormat amount={amount} hideSuffix enablePrefix />
  }

  return (
    <div className={`bg-gray-7 relative overflow-hidden rounded-[20px] border border-[#A9BDEA] shadow ${className}`}>
      <table className="w-full">
        <colgroup>
          <col />
          <col width="220" />
          <col width="220" />
          <col width="220" />
          <col width="220" />
        </colgroup>
        <thead>
          <tr>
            <ColumnHeader />
            {plans.map((plan, i) => {
              if (plan.name.startsWith('BASIC-') && plan.name !== selectedBasicPlanName) return null
              if (plan.name.startsWith('PRO-') && plan.name !== selectedProPlanName) return null

              return (
                <ColumnHeader key={plan.id}>
                  <div
                    translate="no"
                    className="mb-[10px] text-[22px] font-[900] text-[var(--color)]"
                    // eslint-disable-next-line no-useless-computed-key
                    style={{ ['--color' as any]: getPlanColor(plan.displayName) }}
                  >
                    {plan.displayName}
                  </div>
                  <span className="mt-[6px] text-[20px] font-[900] text-[#3d3d3d]">
                    {plan.amount === 0 ? (
                      t('무료', { context: 'value' })
                    ) : (
                      <>
                        <PriceFormat amount={plan.price} hideSuffix enablePrefix />/
                        {t('월', { ns: 'period', context: 'short' })}
                      </>
                    )}
                  </span>
                  {/* {plan.name.startsWith('BASIC-') && (
                    <div className="border-gs01-400 flex rounded-[500px] border p-[4px]">
                      <button
                        onClick={() => setSelectedBasicPlanName('BASIC-1')}
                        className={twMerge(
                          'text-gs01-400 grid h-[24px] place-items-center whitespace-nowrap px-[12px]',
                          selectedBasicPlanName === 'BASIC-1' && 'bg-bcblue-500 rounded-[50px] font-[500] text-white',
                        )}
                      >
                        <span className="text-button02">1,800 크레딧</span>
                      </button>
                      <button
                        onClick={() => setSelectedBasicPlanName('BASIC-2')}
                        className={twMerge(
                          'text-gs01-400 grid h-[24px] place-items-center whitespace-nowrap px-[12px]',
                          selectedBasicPlanName === 'BASIC-2' && 'bg-bcblue-500 rounded-[50px] font-[500] text-white',
                        )}
                      >
                        <span className="text-button02">6,000 크레딧</span>
                      </button>
                    </div>
                  )}
                  {plan.name.startsWith('PRO-') && (
                    <div className="border-gs01-400 flex rounded-[500px] border p-[4px]">
                      <button
                        onClick={() => setSelectedProPlanName('PRO-1')}
                        className={twMerge(
                          'text-gs01-400 grid h-[24px] place-items-center whitespace-nowrap px-[12px]',
                          selectedProPlanName === 'PRO-1' && 'bg-bcblue-500 rounded-[50px] font-[500] text-white',
                        )}
                      >
                        <span className="text-button02">6,000 크레딧</span>
                      </button>
                      <button
                        onClick={() => setSelectedProPlanName('PRO-2')}
                        className={twMerge(
                          'text-gs01-400 grid h-[24px] place-items-center whitespace-nowrap px-[12px]',
                          selectedProPlanName === 'PRO-2' && 'bg-bcblue-500 rounded-[50px] font-[500] text-white',
                        )}
                      >
                        <span className="text-button02">18,000 크레딧</span>
                      </button>
                      <button
                        onClick={() => setSelectedProPlanName('PRO-3')}
                        className={twMerge(
                          'text-gs01-400 grid h-[24px] place-items-center whitespace-nowrap px-[12px]',
                          selectedProPlanName === 'PRO-3' && 'bg-bcblue-500 rounded-[50px] font-[500] text-white',
                        )}
                      >
                        <span className="text-button02">36,000 크레딧</span>
                      </button>
                    </div>
                  )} */}
                  <span className="mt-[18px]">
                    <CheckoutLink
                      isCurrent={!isPause && plan.id === currentWorkspace?.subscription?.plan?.id}
                      planId={plan.id}
                      price={plan.price}
                    />
                  </span>
                  {plan.name.startsWith('PRO-') ? <IconPlanLabel className="absolute right-[10px] top-[-1px]" /> : null}
                </ColumnHeader>
              )
            })}
            <ColumnHeader key="enterprise">
              <span className="text-main-1 mb-[10px] text-[22px] font-[900]">EXCLUSIVE</span>
              <span className="text-[20px] font-[900] text-[#3d3d3d]">{t('문의하기')}</span>
              <span className="mt-[18px]">
                <ContactLink />
              </span>
            </ColumnHeader>
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <tr key={row[0]}>
              <RowHeader>{row[0]}</RowHeader>
              <Data>{row[1]}</Data>
              <Data>{row[2]}</Data>
              <Data>{row[3]}</Data>
              <Data>{row[4]}</Data>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
