/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { NodeViewContent, NodeViewProps, NodeViewWrapper } from '@tiptap/react'
import React, { useEffect, useReducer, useRef, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { v4 as uuidV4 } from 'uuid'
import { twJoin, twMerge } from 'tailwind-merge'
import {
  editorState,
  selectedParagraphBoxIdsState,
  sentenceBoxIdsState,
} from '../../../../../recoil/texteditor/editor/atoms'
import { editorValueState, focusedBoxValueState } from '../../../../../recoil/texteditor/editor/selectors'
import {
  AUDIO_CRATE_STATUS,
  sentenceBoxAudioState,
  sentenceBoxOptionState,
  sentenceBoxState,
  SENTENCEBOX_CATEGORY,
} from '../../../../../recoil/texteditor/sentenceBox/atoms'
import { isValidSentenceBoxState, sentenceBoxValueState } from '../../../../../recoil/texteditor/sentenceBox/selectors'
import { CALL_STATE } from '../../../../../services/constants'
import AudioManager from '../../components/SentenceBox/AudioManager'
import SentenceInterval from '../../components/SentenceBox/SentenceInterval'
import { isPlayingState } from '../../../../../recoil/audiocontroller/selectors'
import useUpdateAudio from '../../../../../services/audio/useUpdateAudio'
import useDebouncedCallback from '../../../../../hooks/useDebouncedCallback'
import useAivatarDidMount from '../../../../../hooks/useAivatarDidMount'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const defaultSentenceBoxValuesForFetch = {
  sentenceBox: {
    sentenceId: null,
  },
  sentenceBoxAudio: {
    audioId: null,
    status: AUDIO_CRATE_STATUS.READY,
    apiState: CALL_STATE.NEW,
    url: null,
  },
}

// 글자 바뀌면
// - sentenceBox 초기화
//  - sentencebox: defaultSentenceBoxValuesForFetch.sentenceBox
//  - option: 그대로
//  - audio: defaultSentenceBoxValuesForFetch.sentenceBoxAudio

// 문장 생성되면
// - 분리된 문장일 경우
//  - 이전 문장의 option을 그대로 가지고 온다
// - 새로 생성된 문장일 경우
//  - 디폴트로 둔다. (일단은) 이 때, voiceId는 현재 속한 문단의 값으로 한다.
//  - sentenceBoxValues의 옵션을 가지고 온다. 이 때, voiceId 세팅은 현재 속한 문단의 값으로 한다

// 문단 생성되면
//   - 이전문단과 같은 voiceId를 가지도록 한다.

export default function SentenceComponent({
  node: { attrs, textContent },
  updateAttributes,
  view,
  getPos,
  editor,
}: NodeViewProps) {
  // 초기값 지정 필요 없음
  // TODO state말고 순수 attr로 관리하기
  const sentenceBoxId = attrs['data-sentencebox-id']
  const splitted = attrs['data-splitted'] === 'true'
  const isSelected = useRecoilValue(sentenceBoxValueState({ id: sentenceBoxId, key: 'isSelected' }))
  const sentenceBox = useRecoilValue(sentenceBoxState(sentenceBoxId))
  const setSentenceBox = useSetRecoilState(sentenceBoxState(sentenceBoxId))
  const setSentenceBoxAudio = useSetRecoilState(sentenceBoxAudioState(sentenceBoxId))
  const setSentenceBoxOption = useSetRecoilState(sentenceBoxOptionState(sentenceBoxId))
  const sentenceBoxIds = useRecoilValue(sentenceBoxIdsState)
  const sentenceBoxIndex = sentenceBoxIds.findIndex((item) => item === sentenceBoxId)
  const isValidSentenceBox = useRecoilValue(isValidSentenceBoxState(sentenceBoxId))
  const { id, lexo, ...prevSentenceBoxOption } = useRecoilValue(
    sentenceBoxOptionState(sentenceBoxIds[sentenceBoxIndex - 1]!),
  )
  const setPlayed = useSetRecoilState(
    focusedBoxValueState({
      category: SENTENCEBOX_CATEGORY.AUDIO,
      key: 'played',
    }),
  )
  const setIntervalPlayed = useSetRecoilState(
    focusedBoxValueState({
      category: SENTENCEBOX_CATEGORY.AUDIO,
      key: 'intervalPlayed',
    }),
  )
  const setEditorState = useSetRecoilState(editorState)
  const focusedBoxId = useRecoilValue(editorValueState({ key: 'focusedBoxId' }))
  //! 이걸로 포커스 구현하자

  console.log('seop sentence', sentenceBox)
  // // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [prevText, setPrevText] = useState(textContent)

  /**
   * 문장의 글자 바뀌는 경우
   */
  useEffect(() => {
    if (sentenceBoxId) {
      console.log('seop sentenceboxtext', sentenceBox.text, textContent)
      if (sentenceBox.text !== textContent) {
        setSentenceBox((prev) => ({
          ...prev,
          ...defaultSentenceBoxValuesForFetch.sentenceBox,
          text: textContent,
        }))
        setSentenceBoxAudio((prev) => ({
          ...prev,
          ...defaultSentenceBoxValuesForFetch.sentenceBoxAudio,
        }))
        setPlayed(0)
        setIntervalPlayed(0)
      }
    }
  }, [
    attrs,
    sentenceBox.text,
    sentenceBoxId,
    setIntervalPlayed,
    setPlayed,
    setSentenceBox,
    setSentenceBoxAudio,
    textContent,
  ])

  /**
   * 문장이 새로 생성되는 경우
   */
  // useEffect(() => {
  //   if (!sentenceBoxId) {
  //     const generatedSentenceBoxId = uuidV4()
  //     updateAttributes({ 'data-sentencebox-id': generatedSentenceBoxId }) // UUID 할당
  //     // setSentenceBoxId(generatedSentenceBoxId)
  //   }
  // }, [sentenceBoxId, updateAttributes, view])

  useEffect(() => {
    if (sentenceBoxId) {
      if (splitted) {
        console.log('seop prevSentenceOption', prevSentenceBoxOption)
        setSentenceBoxOption((prev) => ({
          ...prev,
          ...prevSentenceBoxOption,
        }))
      } else {
        const { state } = view
        const pos = getPos()
        const resolvedPos = state.doc.resolve(pos) // 현재 위치를 기준으로 노드 경로를 확인
        const paragraphNode = resolvedPos.node(resolvedPos.depth)

        setSentenceBoxOption((prev) => ({
          ...prev,
          voiceId: paragraphNode.attrs['data-voice-id'],
        }))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sentenceBoxId, splitted])

  console.log('seop 1818', focusedBoxId, sentenceBoxId)

  const isPlaying = useRecoilValue(isPlayingState)

  const { selection } = editor.view.state
  const selectionRangeEnabled = selection.$head.pos !== selection.$anchor.pos

  const selectedParagraphBoxIds = useRecoilValue(selectedParagraphBoxIdsState)
  const paragraphSelected = selectedParagraphBoxIds.length > 0

  const getParagraphInfo = () => {
    const { state } = view

    let paragraphNode = null
    let paragraphIndex = null

    try {
      const pos = getPos()
      const resolvedPos = state.doc.resolve(pos) // 현재 위치를 기준으로 노드 경로를 확인
      paragraphNode = resolvedPos.node(resolvedPos.depth)
      paragraphIndex = resolvedPos.index(resolvedPos.depth - 1)
    } catch {
      // empty
    }

    return { paragraphNode, paragraphIndex }
  }

  const { paragraphNode, paragraphIndex } = getParagraphInfo()
  const paragraphVoiceId = paragraphNode?.attrs['data-voice-id']

  const { fetchAudioUpdate } = useUpdateAudio()

  const debouncedAudioUpdate = useDebouncedCallback((sentenceBoxId: string) => {
    fetchAudioUpdate({ sentenceBoxIds: [sentenceBoxId] })
  }, 300)

  const initialized = useRef(false)
  useEffect(() => {
    if (paragraphVoiceId && sentenceBoxId) {
      setSentenceBoxOption((prev) => ({
        ...prev,
        voiceId: paragraphVoiceId,
      }))

      if (initialized.current) {
        debouncedAudioUpdate(sentenceBoxId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paragraphVoiceId, sentenceBoxId, setSentenceBoxOption])

  useEffect(() => {
    // if (paragraphIndex) {
    setSentenceBox((prev) => ({
      ...prev,
      ...defaultSentenceBoxValuesForFetch.sentenceBox,
      paragraph: paragraphIndex ?? 0,
    }))

    setSentenceBoxAudio((prev) => ({
      ...prev,
      ...defaultSentenceBoxValuesForFetch.sentenceBoxAudio,
    }))

    setEditorState((prev) => ({
      ...prev,
      touched: true,
    }))

    // setSentenceBox((prev) => ({
    //   ...prev,
    //   paragraph: Number(paragraphIndex),
    // }))
    // }
  }, [paragraphIndex, setEditorState, setSentenceBox, setSentenceBoxAudio])

  useAivatarDidMount(() => {
    initialized.current = true
  }, [])

  return (
    <NodeViewWrapper as="span" {...attrs}>
      {sentenceBoxId && (
        <>
          <AudioManager sentenceBoxId={sentenceBoxId} />
          <SentenceInterval sentenceBoxId={sentenceBoxId} />
        </>
      )}
      <span className={twMerge(isPlaying ? 'p-0' : 'p-[1px]')}>
        <NodeViewContent
          as="span"
          className={twJoin(
            'text-[16px] font-[400] leading-[1.2]',
            twMerge(
              'text-gs01-900 mr-[4px] break-keep rounded-[52px] border border-transparent px-[8px] py-[4px] leading-[2.57]',
              !paragraphSelected && isSelected && 'border-bcblue-500',
              isPlaying && isSelected && 'border-transparent bg-blue-100',
              isPlaying && focusedBoxId === sentenceBoxId && 'border-bcblue-500',
              // !isSelected && 'bg-blue-100',
              isPlaying && 'border-[2px]',
              !isValidSentenceBox && 'border-red-500',
              // isSelected && 'bg-red-500',
            ),
          )}
        />
      </span>
    </NodeViewWrapper>
  )
}
