import React from 'react'

export default function IconFemale() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="16" rx="2" fill="#C893ED" />
      <path
        d="M10.02 10.53H8.50002V8.795C9.90502 8.55501 10.98 7.33 10.98 5.86C10.98 4.215 9.64002 2.88 8.00002 2.88C6.36002 2.88 5.02002 4.22 5.02002 5.86C5.02002 7.335 6.09502 8.56 7.50002 8.795V10.53H5.98002C5.70502 10.53 5.48002 10.755 5.48002 11.03C5.48002 11.305 5.70502 11.53 5.98002 11.53H7.50002V12.635C7.50002 12.91 7.72502 13.135 8.00002 13.135C8.27502 13.135 8.50002 12.91 8.50002 12.635V11.53H10.02C10.295 11.53 10.52 11.305 10.52 11.03C10.52 10.755 10.295 10.53 10.02 10.53ZM6.02002 5.855C6.02002 4.76 6.91002 3.875 8.00002 3.875C9.09002 3.875 9.98002 4.765 9.98002 5.855C9.98002 6.945 9.09002 7.835 8.00002 7.835C6.91002 7.835 6.02002 6.945 6.02002 5.855Z"
        fill="white"
      />
    </svg>
  )
}
