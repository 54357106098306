import React, { ButtonHTMLAttributes } from 'react'
import { twJoin } from 'tailwind-merge'
import AivatarButtonV2 from '../../../../../../../components/buttons/AivatarButtonV2'

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  selected: boolean
}

export default function TabButton({ className, children, selected, ...rest }: Props) {
  return (
    <AivatarButtonV2
      variant={selected ? 'contained-square' : 'outlined-square'}
      size="L"
      className={twJoin(
        'text-headline06 border-gs01-200 text-gs01-400 [&_path]:fill-gs01-400 h-[48px] w-[167px] hover:z-10',
        className,
      )}
      {...rest}
    >
      {children}
    </AivatarButtonV2>
  )
}
