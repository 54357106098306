/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo } from 'react'
import { Slider } from '@mui/material'
import { useRecoilState, useRecoilValue } from 'recoil'

import * as dayjs from 'dayjs'
import { twMerge } from 'tailwind-merge'
import useAivatarDidMount from '../../../../hooks/useAivatarDidMount'
import {
  editorAudioValueState,
  focusedBoxValueState,
  isEmptyFocusedBoxState,
} from '../../../../recoil/texteditor/editor/selectors'
import { SENTENCEBOX_CATEGORY } from '../../../../recoil/texteditor/sentenceBox/atoms'
import { setSentenceBoxAudioManager } from '../../../../recoil/audiocontroller/audioControllerHelpers'
import { currentPlayingTypeState, CURRENT_PLAYING_TYPE } from '../../../../recoil/audiocontroller/selectors'

function AudioControllerSlider() {
  /** Recoils */
  const isEmptyFocusedBox = useRecoilValue(isEmptyFocusedBoxState)
  const id = useRecoilValue(focusedBoxValueState({ category: SENTENCEBOX_CATEGORY.AUDIO, key: 'id' }))
  const duration = useRecoilValue(
    focusedBoxValueState({
      category: SENTENCEBOX_CATEGORY.AUDIO,
      key: 'duration',
    }),
  )
  const [playing, setPlaying] = useRecoilState(
    focusedBoxValueState({
      category: SENTENCEBOX_CATEGORY.AUDIO,
      key: 'playing',
    }),
  )
  const [played, setPlayed] = useRecoilState(
    focusedBoxValueState({
      category: SENTENCEBOX_CATEGORY.AUDIO,
      key: 'played',
    }),
  )

  const intervalPlayed = useRecoilValue(
    focusedBoxValueState({
      category: SENTENCEBOX_CATEGORY.AUDIO,
      key: 'intervalPlayed',
    }),
  )

  const intervalPlaying = useRecoilValue(
    focusedBoxValueState({
      category: SENTENCEBOX_CATEGORY.AUDIO,
      key: 'intervalPlaying',
    }),
  )

  const firstIntervalPlayed = useRecoilValue(
    editorAudioValueState({
      key: 'played',
    }),
  )

  const currentPlayingType = useRecoilValue(currentPlayingTypeState)

  /** Life Cycle */
  useAivatarDidMount(() => {})

  /** Event Handler */
  const onChangePlayerSlider = (event, value) => {
    if (isEmptyFocusedBox) return
    if (playing) {
      setPlaying(false)
    }

    // TODO 111번째줄 TODO 과제 해결되면  이 코드 지워야됨.
    if (value === 0) {
      setSentenceBoxAudioManager({ id, value: (duration * value) / 100 })
    }

    setPlayed(value / 100)
  }

  const onChangeCommitted = (event, value) => {
    if (isEmptyFocusedBox) return
    setSentenceBoxAudioManager({ id, value: (duration * value) / 100 })
    setPlaying(true)
  }

  /** Methods */

  /** Displays */

  const progressValue = useMemo(() => {
    if (isEmptyFocusedBox) return 0
    switch (currentPlayingType) {
      case CURRENT_PLAYING_TYPE.FIRST_INTERVAL:
        return firstIntervalPlayed * 100
      case CURRENT_PLAYING_TYPE.SENTENCE:
        return played * 100

      case CURRENT_PLAYING_TYPE.SENTENCE_INTERVAL:
        // return intervalPlayed * 100
        return 100

      case CURRENT_PLAYING_TYPE.NEW:
        console.log('seop played', played, intervalPlaying)
        if (intervalPlaying) return 100

      // eslint-disable-next-line no-fallthrough
      default:
        return 0
    }
  }, [isEmptyFocusedBox, currentPlayingType, firstIntervalPlayed, played, intervalPlaying])

  const durationTime = useMemo(() => {
    if (isEmptyFocusedBox) return '00:00'

    // if (currentPlayingType !== CURRENT_PLAYING_TYPE.SENTENCE) return null

    return dayjs.duration(played * duration, 's').format('mm:ss')
  }, [duration, isEmptyFocusedBox, played])

  const isAvailableSlider = useMemo(() => {
    // TODO 여기에 나중에 현재오디오가 COMPLETED 상태인지의 조건을 추가해야된다.
    if (currentPlayingType === CURRENT_PLAYING_TYPE.SENTENCE) return true
    return false
  }, [currentPlayingType])

  return (
    <div className="flex w-[482px] items-center justify-between">
      {/* <LinearProgress variant="buffer" value={50} valueBuffer={60} /> */}
      <Slider
        disabled={!isAvailableSlider}
        aria-label="Always visible"
        defaultValue={0}
        size="small"
        // getAriaValueText={valuetext}
        step={1}
        valueLabelDisplay="off"
        value={progressValue}
        className={twMerge(
          '[&_.MuiSlider-thumb]:bg-bcblue-400 [&_.MuiSlider-track]:bg-bcblue-400 [&_.MuiSlider-rail]:text-gs01-200',
          intervalPlaying &&
            '[&_.MuiSlider-track]:bg-gradient-animation [&_.MuiSlider-track]:animate-gradientMove [&_.MuiSlider-track]:bg-[length:200%_200%]',
        )}
        sx={{
          // color: "rgba(0,0,0,0.87)",
          height: 8,
          '& .MuiSlider-thumb': {
            width: 12,
            height: 12,
            // backgroundColor: 'bcblue-400',
            boxShadow: '0px 0px 0px 4px rgba(0, 108, 255, 0.46)',

            transition: '0.1s linear',
            '&:before': {
              boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
            },
            // "&:hover, &.Mui-focusVisible": {
            //   boxShadow: "0px 0px 0px 8px rgb(0 0 0 / 16%)"
            // },
            '&.Mui-active': {
              width: 12,
              height: 12,
            },
            // transition: 'none',
          },
          '& .MuiSlider-rail': {
            opacity: 1,
            // color: '#CACACA !important',
          },
          '& .MuiSlider-track': {
            // opacity: 0.28,
            transition: '0.1s linear',
            // transition: 'none',
            // color: '#3A74F5 !important',
            // backgroundColor: '#3A74F5',
          },
        }}
        onChange={onChangePlayerSlider}
        onChangeCommitted={onChangeCommitted}
      />
      <div className={twMerge('ml-[16px] text-[#9F9F9F]', intervalPlayed && 'text-gs01-200')}>
        <span className="text-subtitle02">{durationTime}</span>
      </div>
    </div>
  )
}

export default AudioControllerSlider
