/* eslint-disable @typescript-eslint/no-unused-vars */
import { useRef, useState, useMemo, useEffect, useCallback } from 'react'
import { motion } from 'framer-motion'
import ReactPlayer from 'react-player'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import IntervalImage from '../../../../../../assets/images/texteditor_interval-background.png'

import NotSelectedIntervalImage from '../../../../../../assets/images/texteditor_interval-background-notselected.png'

import {
  isValidSentenceBoxState,
  sentenceBoxAudioValueState,
  sentenceBoxOptionValueState,
  sentenceBoxValueState,
} from '../../../../../../recoil/texteditor/sentenceBox/selectors'
import useAudioControllerCallbacks from '../../../../../../recoil/audiocontroller/useAudioControllerCallbacks'
import { SENTENCEBOX_CATEGORY } from '../../../../../../recoil/texteditor/sentenceBox/atoms'
import { focusedBoxValueState } from '../../../../../../recoil/texteditor/editor/selectors'
import createSilentAudioUrl from '../../../../../../utils/createSilentAudioUrl'

function SentenceInterval({ sentenceBoxId }) {
  const [intervalUrl, setIntervalUrl] = useState(null)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const player = useRef(null)
  const [isEnded, setIsEnded] = useState(false)

  /** Recoils */
  // const { isSelected } = sentenceBox;

  const isSelected = useRecoilValue(sentenceBoxValueState({ id: sentenceBoxId, key: 'isSelected' }))

  const focusedBoxId = useRecoilValue(focusedBoxValueState({ key: 'id', category: SENTENCEBOX_CATEGORY.GENERAL }))

  const space = useRecoilValue(sentenceBoxOptionValueState({ id: sentenceBoxId, key: 'space' }))
  const sentenceBoxPlaying = useRecoilValue(sentenceBoxAudioValueState({ id: sentenceBoxId, key: 'playing' }))
  const intervalPlaying = useRecoilValue(sentenceBoxAudioValueState({ id: sentenceBoxId, key: 'intervalPlaying' }))
  const setIntervalPlayed = useSetRecoilState(sentenceBoxAudioValueState({ id: sentenceBoxId, key: 'intervalPlayed' }))

  const isValidSentenceBox = useRecoilValue(isValidSentenceBoxState(sentenceBoxId))

  const { updateNextFocused } = useAudioControllerCallbacks()

  const cleanupIntervalUrl = useCallback(() => {
    URL.revokeObjectURL(intervalUrl)
    setIntervalUrl(null)
  }, [intervalUrl])

  /** Event Handlers */

  const onEnded = () => {
    console.log(`on Interval Ended - ${sentenceBoxId}`)
    //! 만약 묵음 건너뛰기 기능이 개발된다면 건너뛰기시에는 이것이 실행 안되므로, 범용적인 intervalUrl 해제방법이 필요함
    cleanupIntervalUrl()
    updateNextFocused(sentenceBoxId)
    setIsEnded(true)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onProgress = ({ played, playedSeconds, loadedSeconds }) => {
    if (isEnded) return
    setIntervalPlayed(played)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onReady = (ready) => {
    setIsEnded(false)
  }

  /** Memos */
  // // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const isOpen = useMemo(() => isSelected, [isSelected])

  // const isFocusedBox = useMemo(() => focusedBoxId === sentenceBoxId, [focusedBoxId, sentenceBoxId])

  // // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const intervalImageSrc = useMemo(
  //   () => (!isValidSentenceBox ? NotSelectedIntervalImage : isFocusedBox ? IntervalImage : NotSelectedIntervalImage),
  //   [isFocusedBox, isValidSentenceBox],
  // )

  useEffect(() => {
    if ((sentenceBoxPlaying && !intervalPlaying) || intervalPlaying) {
      if (!intervalUrl) {
        if (space > 99.9 || space < 0.1) {
          setIntervalUrl(null)
          console.log('seop silence cleanup')
        } else {
          createSilentAudioUrl(space).then((url) => setIntervalUrl(url))
          console.log('seop silence create')
        }
      }
    }
  }, [intervalPlaying, intervalUrl, sentenceBoxPlaying, space])

  useEffect(() => {
    if (!sentenceBoxPlaying && !intervalPlaying) {
      console.log('seop silence cleanup')
      cleanupIntervalUrl()
    }
  }, [cleanupIntervalUrl, intervalPlaying, sentenceBoxPlaying])

  return (
    <motion.span
      className={`inline-block align-middle ${intervalPlaying ? 'animate-pulse bg-blue-100' : ''}`}
      // animate={{
      //   width: isOpen ? 15 : 0
      // }}
    >
      <ReactPlayer
        key={intervalUrl}
        id={`IntervalPlayer-${sentenceBoxId}`}
        ref={player}
        playing={intervalPlaying}
        className="react-player"
        style={{
          display: 'none',
        }}
        url={intervalUrl}
        autoPlay
        controls
        playsinline
        progressInterval={100}
        width={0}
        height={0}
        config={{
          file: {
            attributes: {
              // autoPlay: false,
              // controlsList: "nodownload",
              crossOrigin: 'anonymous',
              onContextMenu: (e) => e.preventDefault(),
            },
          },
        }}
        onReady={onReady}
        // onSeek={onSeek}
        onProgress={onProgress}
        onEnded={onEnded}
        onSeek={() => {
          console.log('interval seek')
        }}
        // onError={onError}
        // onDuration={onDuration}
        // onBuffer={onBuffer}
      />
      {/* <motion.span
        className="relative w-full h-full inline-block align-middle text-cetner cursor-pointer"
        animate={{
          opacity: isOpen ? 1 : 0
        }}
      >
        {isFocusedBox && (
          <div id="tooltip" className="bottom">
            <div className="tooltip-arrow" />
            <div className="tooltip-label">{space}</div>
          </div>
        )}
        <div className="w-[15px] h-[30px] absolute top-[-18px]  inline-flex  justify-center items-center overflow-hidden">
          <div className="relative  w-full h-full flex justify-center items-center">
            <img
              className="absolute w-full h-full "
              src={intervalImageSrc}
              alt="space"
            />
          </div>
        </div>
      </motion.span> */}
    </motion.span>
  )
}

export default SentenceInterval
