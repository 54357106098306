/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { NodeViewProps } from '@tiptap/core'
import { TextSelection } from '@tiptap/pm/state'
import { NodeViewWrapper } from '@tiptap/react'
import { MouseEventHandler, useEffect, useMemo, useState } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { twJoin, twMerge } from 'tailwind-merge'
import { isPlayingState } from '../../../../../recoil/audiocontroller/selectors'
import { selectedParagraphBoxIdsState, sentenceBoxIdsState } from '../../../../../recoil/texteditor/editor/atoms'
import { editorValueState } from '../../../../../recoil/texteditor/editor/selectors'
import {
  isValidSentenceBoxState,
  sentenceBoxOptionValueState,
  sentenceBoxValueState,
} from '../../../../../recoil/texteditor/sentenceBox/selectors'
import useSentenceBoxCallbacks from '../../../../../recoil/texteditor/sentenceBox/useSentenceBoxCallbacks'
import { getNextCursor } from '../../utils/cursor'

export default function SpaceComponent({ node, updateAttributes, getPos, editor }: NodeViewProps) {
  const [sentenceBoxId, setSentenceBoxId] = useState<string | null>(null)
  const focusedBoxId = useRecoilValue(editorValueState({ key: 'focusedBoxId' }))
  const sentenceBoxIds = useRecoilValue(sentenceBoxIdsState)
  const [sentenceBoxSpace, setSentenceBoxSpace] = useRecoilState(
    sentenceBoxOptionValueState({ id: sentenceBoxId, key: 'space' }),
  )
  const isValidSentenceBox = useRecoilValue(isValidSentenceBoxState(sentenceBoxId))

  const prevSentence = useMemo(() => {
    const { state } = editor.view

    try {
      // 현재 노드의 시작 위치
      const currentPos = getPos()

      if (!currentPos || currentPos <= 0) return null // 이전 노드가 없는 경우

      // 이전 위치를 기준으로 노드를 가져옴
      const resolvedPos = state.doc.resolve(currentPos - 1)
      const resultSentence = resolvedPos.parent

      return resultSentence
    } catch {
      return null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor.view, getPos, sentenceBoxIds])
  const prevSentenceBoxId = prevSentence?.attrs['data-sentencebox-id']

  const handleButtonClicked: MouseEventHandler<HTMLButtonElement> = (e) => {
    const { state, view } = editor
    const { tr } = state
    let foundPos = null
    // eslint-disable-next-line consistent-return
    state.doc.descendants((node, pos) => {
      if (node === prevSentence) {
        foundPos = pos + 1
        return false
      }
    })
    console.log('seop pos', foundPos)
    // 문서 시작 위치로 Selection 초기화
    const transaction = tr.setSelection(TextSelection.create(state.doc, foundPos ?? 0))
    view.dispatch(transaction)
    view.focus()
  }

  useEffect(() => {
    if (prevSentenceBoxId) {
      setSentenceBoxId(prevSentenceBoxId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sentenceBoxIds])

  const isSelected = useRecoilValue(sentenceBoxValueState({ id: sentenceBoxId, key: 'isSelected' }))
  const selectedParagraphBoxIds = useRecoilValue(selectedParagraphBoxIdsState)
  const paragraphSelected = selectedParagraphBoxIds.length > 0
  const isPlaying = useRecoilValue(isPlayingState)

  return (
    <NodeViewWrapper as="span" {...node.attrs}>
      <button
        type="button"
        className={twJoin(
          'text-body01',
          twMerge(
            'text-bcblue-500 border-bcblue-500 mr-[8px] inline-block rounded-[50px] border px-[8px] py-[4px]',
            paragraphSelected && isPlaying && isSelected && 'bg-bcblue-500 text-white',
            !paragraphSelected && isSelected && 'bg-bcblue-500 text-white',
            !isValidSentenceBox && 'border-red-500 bg-red-500 text-white',
          ),
        )}
        onClick={handleButtonClicked}
      >
        {sentenceBoxSpace.toFixed(1)}s
      </button>
    </NodeViewWrapper>
  )
}
