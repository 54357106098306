import React, { InputHTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'
import CheckIcon from './icon-check.svg'

type Props = InputHTMLAttributes<HTMLInputElement>

export default function Checkbox({ className, ...rest }: Props) {
  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label
      className={twMerge(
        'border-gs01-200 h-[25px] w-[25px] rounded-[4px] border-[2px] bg-white',
        `has-[:checked]:bg-bcblue-500 has-[:checked]:border-bcblue-500 has-[:checked]:after:content-[var(--check-url)]`,
        className,
      )}
      style={{ ['--check-url' as any]: `url(${CheckIcon})` }}
    >
      <input type="checkbox" className="hidden" {...rest} />
    </label>
  )
}
