import { CircularProgress } from '@mui/material'
import React, { useContext } from 'react'
import { twMerge } from 'tailwind-merge'
import { CustomsContext } from '..'
import AivatarButtonV2 from '../../../../../../../../../components/buttons/AivatarButtonV2'
import { useTranslation } from 'react-i18next'

function ArrowLeftIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.08643 8.02667C4.08643 7.85334 4.15309 7.68667 4.27976 7.55334L10.4864 1.34667C10.7464 1.08667 11.1664 1.08667 11.4264 1.34667C11.6864 1.60667 11.6864 2.02667 11.4264 2.28667L5.68643 8.02667L11.3264 13.6667C11.5864 13.9267 11.5864 14.3467 11.3264 14.6067C11.0664 14.8667 10.6464 14.8667 10.3864 14.6067L4.27976 8.50001C4.14643 8.36667 4.08643 8.20001 4.08643 8.02667Z"
        fill="#006CFF"
      />
    </svg>
  )
}

export default function CustomsNavigation({ onComplete, isPending }: { onComplete: () => void; isPending: boolean }) {
  const { page, changePage, goPrev, goNext, canProceed } = useContext(CustomsContext)
  const { t } = useTranslation('voice-conversion')

  return (
    <div className={twMerge('mx-[32px] mt-[24px] flex justify-between', page === 'main' && 'hidden')}>
      <div>
        <AivatarButtonV2 variant="outlined-rounded" size="M" onClick={() => changePage('main')}>
          <ArrowLeftIcon />
          <span>{t('뒤로가기')}</span>
        </AivatarButtonV2>
      </div>
      <div className="flex gap-[16px]">
        <AivatarButtonV2
          variant="contained-rounded"
          size="L"
          onClick={goPrev}
          className={twMerge('bg-gs01-300', page === 'writeInfo' && 'hidden')}
        >
          <ArrowLeftIcon />
          <span>{t('이전으로')}</span>
        </AivatarButtonV2>
        <AivatarButtonV2
          variant="contained-rounded"
          size="L"
          onClick={page === 'uploadFile' ? onComplete : goNext}
          disabled={!canProceed || isPending}
        >
          <span>{page === 'uploadFile' ? t('생성하기') : t('다음으로')}</span>
          {isPending ? <CircularProgress size={19} color="inherit" /> : <ArrowLeftIcon className="rotate-180" />}
        </AivatarButtonV2>
      </div>
    </div>
  )
}
