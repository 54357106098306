/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { isMacOs } from 'react-device-detect'
import useEditorCallbacks from '../../recoil/texteditor/editor/useEditorCallbacks'
import useUpdateProject from '../../services/project/useUpdateProject'

const useMutationHotkeys = () => {
  // const { deleteSelectedBoxes } = useEditorCallbacks()
  const { fetchProject } = useUpdateProject()
  // const keyCombination = useMemo(() => {
  //   if (isMacOs) return 'mod+backspace'
  //   return 'delete'
  // }, [])
  // useHotkeys(
  //   keyCombination,
  //   () => {
  //     // console.debug("useMutationHotkeys");
  //     deleteSelectedBoxes()
  //     fetchProject()
  //   },
  //   {
  //     preventDefault: true,
  //   },
  // )

  useHotkeys(
    [
      // mac
      'meta+s',
      // window
      'ctrl+s',
    ],
    () => {
      fetchProject()
    },
    {
      preventDefault: true,
    },
  )
}

export default useMutationHotkeys
