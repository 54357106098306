import React from 'react'

export default function IconStarEmpty() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.47977 21.9399C6.25977 21.9399 6.04977 21.8699 5.85977 21.7399C5.47977 21.4599 5.32977 20.9699 5.46977 20.5199L7.34977 14.4599L2.42977 10.7099C2.05977 10.4299 1.90977 9.93994 2.04977 9.48994C2.18977 9.03994 2.59977 8.73994 3.05977 8.73994H9.11977L10.9898 2.69994C11.1298 2.24994 11.5398 1.93994 11.9998 1.93994C12.4598 1.93994 12.8698 2.23994 13.0098 2.69994L14.8798 8.73994H20.9398C21.3998 8.73994 21.7998 9.03994 21.9498 9.48994C22.0898 9.93994 21.9498 10.4299 21.5798 10.7099L16.6598 14.4599L18.5398 20.5199C18.6798 20.9699 18.5198 21.4599 18.1498 21.7399C17.7698 22.0199 17.2698 22.0099 16.8998 21.7299L12.0098 18.0099L7.11977 21.7399C6.92977 21.8799 6.70977 21.9599 6.48977 21.9599L6.47977 21.9399ZM11.9998 16.3899C12.2198 16.3899 12.4398 16.4599 12.6298 16.6099L16.7498 19.7499L15.1598 14.6199C15.0198 14.1799 15.1698 13.6899 15.5398 13.4099L19.7198 10.2299H14.5798C14.1198 10.2299 13.7098 9.92994 13.5698 9.46994L11.9898 4.37994L10.4098 9.46994C10.2698 9.91994 9.85977 10.2299 9.39977 10.2299H4.25977L8.42977 13.4099C8.78977 13.6899 8.94977 14.1699 8.80977 14.6199L7.21977 19.7399L11.3498 16.5999C11.5398 16.4599 11.7598 16.3799 11.9798 16.3799L11.9998 16.3899Z"
        fill="#8497AD"
      />
    </svg>
  )
}
