import { Select, SelectProps } from '@mui/material'
import React from 'react'
import IconArrowDown from './IconArrowDown'

type Props = SelectProps

export default function FilterSelect({ children, ...rest }: Props) {
  return (
    <Select
      className="[&_fieldset]:border-gs01-200 h-[40px] items-center [&_fieldset]:rounded-[4px]"
      classes={{ select: '!text-list !text-gs01-950 !p-0 !pl-[12px] !pr-[40px] !flex !items-center' }}
      // eslint-disable-next-line react/no-unstable-nested-components
      IconComponent={() => (
        <div className="absolute right-[12px] h-[16px] w-[16px]">
          <IconArrowDown />
        </div>
      )}
      displayEmpty
      {...rest}
    >
      {children}
    </Select>
  )
}
