import React from 'react'

type Props = {
  className?: string
}

export default function IconArrow({ className }: Props) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.97324 4.58667C8.14658 4.58667 8.31324 4.65334 8.44658 4.78L14.6532 10.9867C14.9132 11.2467 14.9132 11.6667 14.6532 11.9267C14.3932 12.1867 13.9732 12.1867 13.7132 11.9267L7.97324 6.18667L2.33324 11.8333C2.07324 12.0933 1.65324 12.0933 1.39324 11.8333C1.13324 11.5733 1.13324 11.1533 1.39324 10.8933L7.49991 4.78C7.63324 4.64667 7.79991 4.58667 7.97324 4.58667Z"
        fill="#485060"
      />
    </svg>
  )
}
