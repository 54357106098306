import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const useLocaleList = () => {
  const { t } = useTranslation('locale')

  const localeList = useMemo(
    () => ({
      'af-ZA': t('아프리칸스어 (남아프리카)'),
      'am-ET': t('암하라어 (에티오피아)'),
      'ar-AE': t('아랍어 (아랍에미리트)'),
      'ar-DZ': t('아랍어 (알제리)'),
      'ar-EG': t('아랍어 (이집트)'),
      'ar-IQ': t('아랍어 (이라크)'),
      'ar-JO': t('아랍어 (요르단)'),
      'ar-KW': t('아랍어 (쿠웨이트)'),
      'ar-LB': t('아랍어 (레바논)'),
      'ar-LY': t('아랍어 (리비아)'),
      'ar-MA': t('아랍어 (모로코)'),
      'ar-OM': t('아랍어 (오만)'),
      'ar-QA': t('아랍어 (카타르)'),
      'ar-SA': t('아랍어 (사우디아라비아)'),
      'ar-SY': t('아랍어 (시리아)'),
      'ar-TN': t('아랍어 (튀니지)'),
      'ar-YE': t('아랍어 (예멘)'),
      'az-AZ': t('아제르바이잔어 (아제르바이잔)'),
      'bg-BG': t('불가리아어 (불가리아)'),
      'bn-BD': t('벵골어 (방글라데시)'),
      'bn-IN': t('벵골어 (인도)'),
      'bs-BA': t('보스니아어 (보스니아 헤르체고비나)'),
      'ca-ES': t('카탈루냐어'),
      'cs-CZ': t('체코어 (체코)'),
      'cy-GB': t('웨일스어 (영국)'),
      'da-DK': t('덴마크어 (덴마크)'),
      'de-AT': t('독일어 (오스트리아)'),
      'de-CH': t('독일어 (스위스)'),
      'de-DE': t('독일어 (독일)'),
      'el-GR': t('그리스어 (그리스)'),
      'en-AU': t('영어 (호주)'),
      'en-CA': t('영어 (캐나다)'),
      'en-GB': t('영어 (영국)'),
      'en-HK': t('영어 (홍콩)'),
      'en-IE': t('영어 (아일랜드)'),
      'en-IN': t('영어 (인도)'),
      'en-KE': t('영어 (케냐)'),
      'en-NG': t('영어 (나이지리아)'),
      'en-NZ': t('영어 (뉴질랜드)'),
      'en-PH': t('영어 (필리핀)'),
      'en-SG': t('영어 (싱가포르)'),
      'en-TZ': t('영어 (탄자니아)'),
      'en-US': t('영어 (미국)'),
      'en-ZA': t('영어 (남아프리카 공화국)'),
      'es-AR': t('스페인어 (아르헨티나)'),
      'es-BO': t('스페인어 (볼리비아)'),
      'es-CL': t('스페인어 (칠레)'),
      'es-CO': t('스페인어 (콜롬비아)'),
      'es-CR': t('스페인어 (코스타리카)'),
      'es-CU': t('스페인어 (쿠바)'),
      'es-DO': t('스페인어 (도니미카 공화국)'),
      'es-EC': t('스페인어 (에콰도르)'),
      'es-ES': t('스페인어 (스페인)'),
      'es-GQ': t('스페인어 (적도 기니)'),
      'es-GT': t('스페인어 (과테말라)'),
      'es-HN': t('스페인어 (온두라스)'),
      'es-MX': t('스페인어 (멕시코)'),
      'es-NI': t('스페인어 (니카라과)'),
      'es-PA': t('스페인어 (파나마)'),
      'es-PE': t('스페인어 (페루)'),
      'es-PR': t('스페인어 (푸에르토리코)'),
      'es-PY': t('스페인어 (파라과이)'),
      'es-SV': t('스페인어 (엘살바도르)'),
      'es-US': t('스페인어 (미국)'),
      'es-UY': t('스페인어 (우루과이)'),
      'es-VE': t('스페인어 (베네수엘라)'),
      'et-EE': t('에스토니아어 (에스토니아)'),
      'eu-ES': t('바스크어'),
      'fa-IR': t('페르시아어 (이란)'),
      'fi-FI': t('핀란드어 (핀란드)'),
      'fil-PH': t('필리핀어 (필리핀)'),
      'fr-BE': t('프랑스어 (벨기에)'),
      'fr-CA': t('프랑스어 (캐나다)'),
      'fr-CH': t('프랑스어 (스위스)'),
      'fr-FR': t('프랑스어 (프랑스)'),
      'ga-IE': t('아일랜드어 (아일랜드)'),
      'gl-ES': t('갈리시아어'),
      'gu-IN': t('구자라트어 (인도)'),
      'he-IL': t('히브리어 (이스라엘)'),
      'hi-IN': t('힌디어 (인도)'),
      'hr-HR': t('크로아티아어 (크로아티아)'),
      'hu-HU': t('헝가리어 (헝가리)'),
      'hy-AM': t('아르메니아어 (아르메니아)'),
      'id-ID': t('인도네시아어 (인도네시아)'),
      'is-IS': t('아이슬란드어 (아이슬란드)'),
      'it-IT': t('이탈리아어 (이탈리아)'),
      'ja-JP': t('일본어 (일본)'),
      'jv-ID': t('자와어 (인도네시아)'),
      'kk-KZ': t('카자흐어 (카자흐스탄)'),
      'km-KH': t('크메르어 (캄보디아)'),
      'kn-IN': t('칸나다어 (인도)'),
      'ko-KR': t('한국어 (한국)'),
      'lo-LA': t('라오어 (라오스)'),
      'lt-LT': t('리투아니아어 (리투아니아)'),
      'lv-LV': t('라트비아어 (라트비아)'),
      'mk-MK': t('마케도니아어 (북마케도니아)'),
      'ml-IN': t('말라얄람어 (인도)'),
      'mn-MN': t('몽골어 (몽골)'),
      'mr-IN': t('마라티어 (인도)'),
      'ms-MY': t('말레이어 (말레이시아)'),
      'mt-MT': t('몰타어 (몰타)'),
      'my-MM': t('버마어 (미얀마)'),
      'nb-NO': t('노르웨이어 (노르웨이)'),
      'ne-NP': t('네팔어 (네팔)'),
      'nl-BE': t('네덜란드어 (벨기에)'),
      'nl-NL': t('네덜란드어 (네덜란드)'),
      'pl-PL': t('폴란드어 (폴란드)'),
      'ps-AF': t('파슈토어 (아프가니스탄)'),
      'pt-BR': t('포르투갈어 (브라질)'),
      'pt-PT': t('포르투갈어 (포르투갈)'),
      'ro-RO': t('루마니아어 (루마니아)'),
      'ru-RU': t('러시아어 (러시아)'),
      'si-LK': t('싱할라어 (스리랑카)'),
      'sk-SK': t('슬로바키아어 (슬로바키아)'),
      'sl-SI': t('슬로베니아어 (슬로베니아)'),
      'so-SO': t('소말리어 (소말리아)'),
      'sq-AL': t('알바니아어 (알바니아)'),
      'sr-RS': t('세르비아어 (세르비아, 키릴 문자)'),
      'su-ID': t('순다어 (인도네시아)'),
      'sv-SE': t('스웨덴어 (스웨덴)'),
      'sw-KE': t('스와힐리어 (케냐)'),
      'sw-TZ': t('스와힐리어 (탄자니아)'),
      'ta-IN': t('타밀어 (인도)'),
      'ta-LK': t('타밀어 (스리랑카)'),
      'ta-MY': t('타밀어 (말레이시아)'),
      'ta-SG': t('타밀어 (싱가포르)'),
      'te-IN': t('텔루구어 (인도)'),
      'th-TH': t('태국어 (태국)'),
      'tr-TR': t('튀르키예어 (튀르키예)'),
      'uk-UA': t('우크라이나어 (우크라이나)'),
      'ur-IN': t('우르두어 (인도)'),
      'ur-PK': t('우르두어 (파키스탄)'),
      'uz-UZ': t('우즈베크어 (우즈베키스탄)'),
      'vi-VN': t('베트남어 (베트남)'),
      'wuu-CN': t('중국어 (오어, 간체)'),
      'zh-CN': t('중국어 (표준, 간체)'),
      'zh-HK': t('중국어 (광동어, 번체)'),
      'zh-TW': t('중국어 (대만, 번체)'),
      'zu-ZA': t('줄루어 (남아프리카 공화국)'),
    }),
    [t],
  )

  return { localeList }
}

export default useLocaleList
