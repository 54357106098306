/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable prefer-destructuring */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { twMerge } from 'tailwind-merge'
import { CustomsContext } from '..'
import AivatarButtonV2 from '../../../../../../../../../components/buttons/AivatarButtonV2'
import { Trans, useTranslation } from 'react-i18next'

export default function CustomsUploadFile() {
  const [error, setError] = useState<string | null>(null)

  const { data, changeData, changeCanProceed } = useContext(CustomsContext)
  const { t } = useTranslation('voice-conversion')

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      changeData({ ...data, userFile: acceptedFiles[0] })
      setError(null)
    },
    [changeData, data],
  )

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
    accept: {
      'audio/mpeg': ['.mp3'],
      'audio/wav': ['.wav'],
    },
  })

  const validateAudioFile = async (file: File): Promise<boolean> => {
    // 1. 파일 확장자 및 MIME 타입 검사
    const validFormats = ['audio/mpeg', 'audio/wav']
    if (!validFormats.includes(file.type)) {
      setError('MP3 또는 WAV 파일만 업로드할 수 있습니다.')
      return false
    }

    try {
      // 2. Web Audio API를 사용하여 파일 길이와 샘플링 레이트 검사
      const audioContext = new AudioContext()
      const arrayBuffer = await file.arrayBuffer()
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer)

      const duration = audioBuffer.duration // 초 단위 길이
      const sampleRate = audioBuffer.sampleRate // Hz 단위 샘플링 레이트

      if (duration < 10 || duration > 30) {
        setError(t('업로드한 파일의 음성 길이를 다시 확인해 주세요.'))
        return false
      }

      if (sampleRate < 16000) {
        setError('음성 품질은 16000Hz 이상이어야 합니다.')
        return false
      }

      return true
    } catch (error) {
      setError('오디오 파일을 분석하는 중 오류가 발생했습니다.')
      console.error(error)
      return false
    }
  }

  useEffect(() => {
    if (data.userFile) {
      validateAudioFile(data.userFile)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.userFile])

  useEffect(() => {
    if (data.userFile && !error) {
      changeCanProceed(true)
    } else {
      changeCanProceed(false)
    }
  }, [changeCanProceed, error, data.userFile])

  useEffect(() => {
    const modalFooter = document.getElementById('voice-modal-footer')

    if (modalFooter) {
      modalFooter.style.display = 'none'
    }

    return () => {
      if (modalFooter) {
        modalFooter.style.display = ''
      }
    }
  }, [])

  return (
    <div className="px-[32px] py-[24px]" {...getRootProps()}>
      <div className="border-gs01-100 h-[322px] rounded-[8px] border p-[32px]">
        <div className="text-headline06 text-gs01-900">{t('파일 업로드', { context: 'heading' })}</div>
        {data.userFile ? (
          <div
            className={twMerge(
              'border-gs01-100 mx-auto flex h-[84px] w-[621px] items-center justify-between rounded-[8px] border px-[34px]',
              error && 'border-red-500',
            )}
          >
            <div className="flex items-center gap-[12px]">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1655_12954)">
                  <path
                    d="M15 1H4C2.9 1 2 1.9 2 3V16C2 16.55 2.45 17 3 17C3.55 17 4 16.55 4 16V4C4 3.45 4.45 3 5 3H15C15.55 3 16 2.55 16 2C16 1.45 15.55 1 15 1ZM15.59 5.59L20.42 10.42C20.79 10.79 21 11.3 21 11.83V21C21 22.1 20.1 23 19 23H7.99C6.89 23 6 22.1 6 21L6.01 7C6.01 5.9 6.9 5 8 5H14.17C14.7 5 15.21 5.21 15.59 5.59ZM15 12H19.5L14 6.5V11C14 11.55 14.45 12 15 12Z"
                    fill="#576076"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1655_12954">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span className="text-body01 text-gs01-900">{data.userFile.name}</span>
            </div>

            <div className="flex items-center gap-[9px]">
              {error && <div className="text-body01 text-red-500">*{error}</div>}
              <button type="button" onClick={() => changeData({ ...data, userFile: undefined })}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.9998 1.78C6.34979 1.78 1.77979 6.35 1.77979 12C1.77979 17.65 6.35979 22.22 11.9998 22.22C17.6398 22.22 22.2198 17.64 22.2198 12C22.2198 6.36 17.6498 1.78 11.9998 1.78ZM17.1698 16.16C17.4498 16.44 17.4498 16.89 17.1698 17.17C16.8898 17.45 16.4398 17.45 16.1598 17.17L11.9998 13.01L7.83979 17.17C7.55979 17.45 7.10979 17.45 6.82979 17.17C6.54979 16.89 6.54979 16.44 6.82979 16.16L10.9898 12L6.82979 7.84C6.54979 7.56 6.54979 7.11 6.82979 6.83C7.10979 6.55 7.55979 6.55 7.83979 6.83L11.9998 10.99L16.1598 6.83C16.4398 6.55 16.8898 6.55 17.1698 6.83C17.4498 7.11 17.4498 7.56 17.1698 7.84L13.0098 12L17.1698 16.16Z"
                    fill="#576076"
                  />
                </svg>
              </button>
            </div>
          </div>
        ) : (
          <div className="mt-[50px] flex items-center justify-center">
            <input {...getInputProps()} />
            <AivatarButtonV2 variant="contained-rounded" size="L" type="button" onClick={open}>
              {t('파일 업로드', { context: 'button' })}
            </AivatarButtonV2>
            <div className="text-subtitle01 text-gs01-800 ml-[16px]">or</div>
            <div className="text-subtitle01 text-gs01-800 ml-[13px]">Drag File</div>
          </div>
        )}

        <div className="bg-gs01-50 mx-auto mt-[30px] flex h-[107px] w-[621px] items-center justify-center rounded-[8px]">
          <ul className="text-body01 text-gs01-700 flex list-disc flex-col gap-[4px]">
            <li>
              <Trans t={t}>
                <b>WAV와 MP3</b> 파일 형식만 업로드할 수 있습니다.
              </Trans>
            </li>
            <li>
              <Trans t={t}>
                음성 길이가 <b>10초에서 30초 사이</b>인 파일만 업로드할 수 있습니다.
              </Trans>
            </li>
            <li>
              <Trans t={t}>
                음성 품질이 <b>16000Hz 이상</b>인 파일만 업로드할 수 있습니다.
              </Trans>
            </li>
          </ul>
        </div>
      </div>
      <div className="border-gs01-100 mt-[20px] rounded-[8px] border px-[32px] py-[23px]">
        <div className="text-body01 text-gs01-700 mt-[5px] leading-[1.5]">
          {t(
            '업로드하신 음성 파일은 오직 워크스페이스에서 커스텀 목소리 샘플을 생성하고, 커스텀 목소리로 음성을 생성하는 데에만 사용됩니다. (주)에이아이파크는 이외의 어떠한 목적으로도 해당 데이터를 활용하지 않습니다. 또한 업로드하신 음성 파일은 워크스페이스에서 커스텀 목소리 서비스가 제공되는 기간 동안 보관 및 이용됩니다.',
          )}
          <br />
          {t(
            '회원은 AiVATAR 플랫폼 및 AiVATAR 기술이 적용된 모든 서비스(API 포함)에서 생성된 콘텐츠를 불법적이거나 유해한 목적으로 사용하지 않을 것을 확인합니다. 회원은 타인의 권리를 침해하는 음성 파일의 사용을 금지하며, 저작권이 있는 음성을 무단으로 사용할 경우 법적 책임이 발생할 수 있음을 확인합니다. 또한 회원은 (주)에이아이파크의 서비스 약관, 금지 콘텐츠 및 사용 정책, 개인정보 보호정책을 준수할 것을 확인합니다.',
          )}
          <br />
          {t("'생성하기' 버튼을 클릭하시면 위 내용을 모두 확인하고 이에 동의하신 것으로 간주됩니다.")}
        </div>
      </div>
    </div>
  )
}
