import React from 'react'
import { ReactComponent as Icon } from '../../../../../assets/images/icon-trashbin.svg'

type Props = {
  className?: string
}

export default function TrashBin({ className }: Props) {
  return <Icon className={className} />
}
