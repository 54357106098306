import React from 'react'
import Dialog from '../../Dialog'
import { ReactComponent as IconClose } from '../../../assets/images/icon-close.svg'
import { useTranslation } from 'react-i18next'
import useDialog from '../../../hooks/useDialog'
import { LocalStorage, SessionStorage } from '../../../utils/storage'
import imgKo from './img-ko.png'
import imgEn from './img-en.png'
import useURI from '../../../hooks/useURI'

export default function NewFeaturePopup() {
  const { t, i18n } = useTranslation('popup')
  const { uri } = useURI()

  const dialog = useDialog()

  const doNotShowAgain = () => {
    LocalStorage.addNotShownPopupList('NEW_FEATURE')
    dialog.close()
  }

  const close = () => {
    SessionStorage.addClosedPopupList('NEW_FEATURE')
    dialog.close()
  }

  return (
    <Dialog classes={{ paper: '!p-0 !overflow-hidden' }}>
      <div>
        <div className="absolute w-full">
          <button
            type="button"
            className="typo-body1 absolute left-[24px] top-[14px] text-white underline"
            onClick={doNotShowAgain}
          >
            {t('다시보지 않기')}
          </button>
          <button
            type="button"
            className="absolute right-[20px] top-[14px] [&_path]:stroke-white"
            onClick={close}
            aria-label={t('닫기', { ns: 'modal' })}
          >
            <IconClose />
          </button>
        </div>
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <a href={uri['guide-type-text']} target="_blank" rel="noreferrer" className="overflow-hidden">
          <img src={i18n.language.startsWith('ko') ? imgKo : imgEn} width={500} alt="" />
        </a>
      </div>
    </Dialog>
  )
}
