/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, useMemo } from 'react'
import { useRecoilState } from 'recoil'
import { InputAdornment, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import useUpdateProject from '../../../../../services/project/useUpdateProject'
import { projectValueState } from '../../../../../recoil/project/selectors'

import { ReactComponent as EditIcon } from '../../../../../assets/images/pencle.svg'
import AivatarBackdrop from '../../../../../components/AivatarBackdrop'

function ProjectTitle() {
  /** Recoils */
  const [projectTitle, setProjectTitle] = useRecoilState(projectValueState({ key: 'title' }))

  const DEFAULT_INPUT_WIDTH = 99
  const INPUT_WIDTH_MAX = 480
  const FONT_SIZE = 20

  const { t } = useTranslation('project')

  // const [textValue, setTextValue] = React.useState(projectTitle);
  // setTextValue(projectTitle)

  const [isEditing, setIsEditing] = useState(false)

  const [inputWidth, setInputWidth] = useState(DEFAULT_INPUT_WIDTH)

  const [errorMessage, setErrorMessage] = useState(null)

  useEffect(() => {
    if (projectTitle.length * FONT_SIZE >= INPUT_WIDTH_MAX) {
      setInputWidth(inputWidth)
    } else if (projectTitle.length * FONT_SIZE > DEFAULT_INPUT_WIDTH) {
      setInputWidth((projectTitle.length + 1) * FONT_SIZE)
    } else {
      setInputWidth(DEFAULT_INPUT_WIDTH)
    }
  }, [inputWidth, projectTitle])

  const onValueChange = (e) => {
    setProjectTitle(e.target.value)
  }

  /** Services */
  const { fetchProject } = useUpdateProject()

  /** Memos */

  const isValidTitleUpdate = useMemo(() => {
    if (projectTitle.length > 30) {
      setErrorMessage(t('30글자 이내로 입력해야합니다.'))
    }
    if (projectTitle.length < 1) {
      setErrorMessage(t('1글자 이상 입력해야합니다.'))
    }
    const b = projectTitle.length <= 30 && projectTitle.length > 0
    // if (b) {
    //   setIsEditing(false)
    // } else {
    //   setIsEditing(true)
    // }
    return b
  }, [projectTitle.length, t])

  const borderColor = useMemo(() => (isValidTitleUpdate ? '#3A74F5' : '#f05e5e'), [isValidTitleUpdate])

  const onBlur = (e) => {
    if (!isValidTitleUpdate) {
      e.target.focus()
    } else {
      setProjectTitle(projectTitle)
      fetchProject(projectTitle)
    }
  }

  return (
    <div className="ml-[34px] flex w-full items-center">
      <AivatarBackdrop open={isEditing} />

      <TextField
        onChange={onValueChange}
        onBlur={onBlur}
        value={projectTitle ?? ''}
        size="small"
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              {' '}
              <EditIcon />{' '}
            </InputAdornment>
          ),
          style: { width: `${inputWidth}px` },
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: '22px',
            transition: 'border-color 5s ease',
            '& fieldset': {
              borderWidth: '0px',
              borderColor,
            },
            '&:hover fieldset': {
              borderWidth: '2px',
              borderColor,
            },
            '&.Mui-focused fieldset': {
              borderWidth: '2px',
              borderColor,
            },
            '&:hover': {
              background: 'var(--color-main-3)',
            },
          },
        }}
      />
      {!isValidTitleUpdate ? (
        <span className="ml-[20px] py-[8px]" style={{ fontSize: '16px', color: borderColor }}>
          ※{errorMessage}
        </span>
      ) : (
        ''
      )}
    </div>
  )
}

export default ProjectTitle
