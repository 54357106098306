import {useTranslation} from 'react-i18next'
import uri from '../../public/locales/ko/uri.json'

export default function useURI() {
    // eslint-disable-next-line no-restricted-syntax
    const {t} = useTranslation('uri')

    // i18n 키를 그대로 가져오고 싶은데, 키가 달라지는 실수는 방지하고 싶다.
    // 새로운 키를 추가 후 파싱해보면, 키가 다르다면 바로 에러가 뜰것이다.
    const resourceBundle: Record<keyof typeof uri, string> = {
        'navbar-banner-image': t('navbar-banner-image'),
        'update-note': t('update-note'),
        'aivatar-home': t('aivatar-home'),
        'aivatar-term': t('aivatar-term'),
        'aivatar-privacy': t('aivatar-privacy'),
        'help-center': t('help-center'),
        'contact-us': t('contact-us'),
        'bug-report': t('bug-report'),
        guide: t('guide'),
        'guide-type-text': t('guide-type-text'),
        'self-avatar-guide': t('self-avatar-guide'),
        'aivatar-contact-us': t('aivatar-contact-us'),
        'self-avatar-upload': t('self-avatar-upload'),
    }

    return {
        uri: resourceBundle,
    }
}
