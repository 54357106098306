/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { InputHTMLAttributes } from 'react'
import { twJoin } from 'tailwind-merge'

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'children'> & {
  children: string
}

export default function Radio({ children, className, ...rest }: Props) {
  return (
    <label className="flex items-center gap-[8px]">
      <div className="has-[:checked]:bg-bcblue-500 has-[:checked]:outline-bcblue-500 outline-gs01-200 box-content h-[10px] w-[10px] rounded-full border-[4px] border-white outline outline-[2px]">
        <input type="radio" style={{ display: 'none' }} {...rest} />
      </div>
      <span className={twJoin('text-gs01-900 text-body01')}>{children}</span>
    </label>
  )
}
