import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import CustomImage from '../../../../../../assets/images/videooption_image-custom.png'
import NtImage from '../../../../../../assets/images/videooption_image-nothing.png'

export type BackgroundType = 'CHROMA' | 'NOTHING' | 'CUSTOM'

export type BackgroundSelectContentNormalItemProps = {
    type: BackgroundType
    onClickItem: (type: BackgroundSelectContentNormalItemProps['type']) => void
    title: string
    selectedBackgroundType: BackgroundSelectContentNormalItemProps['type'] | null
    color?: `#${string}`
    isDisabled?: boolean
}

export default function NormalItem({
                                       type,
                                       onClickItem,
                                       title,
                                       selectedBackgroundType,
                                       color,
                                       isDisabled = false,
                                   }: BackgroundSelectContentNormalItemProps) {
    const {t} = useTranslation('project-option')

    const bgColor = useMemo(() => {
        switch (type) {
            case 'CHROMA':
                return 'bg-[#008000]'

            case 'NOTHING':
                return 'bg-white'

            case 'CUSTOM':
                return `bg-[${color}]`

            default:
                return ''
        }
    }, [type, color])

    /** Styles */
    const isSelected = useMemo(() => type === selectedBackgroundType, [selectedBackgroundType, type])

    const border = useMemo(() => {
        switch (type) {
            case 'CHROMA':
                return isSelected ? 'border-[2px] border-[#3A74F5]' : 'border-[2px] border-[#CACACA]'

            case 'NOTHING':
                return isSelected ? 'border-[2px] border-[#3A74F5]' : 'border-[2px] border-[#9F9F9F]'

            case 'CUSTOM':
                return isSelected ? 'border-[2px] border-[#3A74F5]' : 'border-[2px] border-[#9F9F9F]'

            default:
                return ''
        }
    }, [isSelected, type])

    const textColor = useMemo(() => (isSelected ? 'text-[#3D3D3D]' : 'text-[#3D3D3D]'), [isSelected])

    const shadow = useMemo(() => (isSelected ? 'shadow_selected' : ''), [isSelected])

    /** Displays */

    const imgComponent = useMemo(() => {
        let src
        let alt
        switch (type) {
            case 'CHROMA':
                return null
            case 'NOTHING':
                src = NtImage
                alt = t('무채색의 투명 아이콘')
                break
            case 'CUSTOM':
                src = CustomImage
                alt = t('빛의 스펙트럼이 원형으로 펼쳐져있는 아이콘')
                break
            default:
                break
        }
        return <img className="h-full w-full" src={src} alt={alt}/>
    }, [t, type])

    return (
        <button
            type="button"
            className={`group flex w-[60px] flex-col items-center ${isDisabled ? 'cursor-not-allowed opacity-50' : ''}`}
            onClick={() => !isDisabled && onClickItem(type)}
            disabled={isDisabled}
        >
            <div
                className={`h-[60px] w-[60px] overflow-hidden rounded-[12px] 
                ${shadow} ${bgColor} ${border} 
                ${isDisabled ? '' : 'group-hover:border-[#004fff]'}
            `}
            >
                {imgComponent}
            </div>
            <p
                className={`mt-[10px] text-center text-[14px] font-medium 
                ${isDisabled ? '' : 'group-hover:text-[#004fff]'} 
                ${textColor}
            `}
            >
                {title}
            </p>
        </button>
    )
}
