import React from 'react'

export default function IconPause() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.8782 16.4291C7.3032 16.4291 7.65092 16.0968 7.65092 15.6873V4.32043C7.65092 3.91089 7.3032 3.57861 6.8782 3.57861C6.4532 3.57861 6.10547 3.91089 6.10547 4.32043V15.6873C6.10547 16.0968 6.4532 16.4291 6.8782 16.4291Z"
        fill="white"
      />
      <path
        d="M13.1214 16.4291C13.5464 16.4291 13.8941 16.0968 13.8941 15.6873V4.32043C13.8941 3.91089 13.5464 3.57861 13.1214 3.57861C12.6964 3.57861 12.3486 3.91089 12.3486 4.32043V15.6873C12.3486 16.0968 12.6964 16.4291 13.1214 16.4291Z"
        fill="white"
      />
    </svg>
  )
}
