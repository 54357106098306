/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import useAivatarDidMount from '../../../../hooks/useAivatarDidMount'
import useAudioControllerCallbacks from '../../../../recoil/audiocontroller/useAudioControllerCallbacks'

import { ReactComponent as PlayIcon } from './icon-play.svg'
import { ReactComponent as PauseIcon } from './icon-pause.svg'
import { ReactComponent as PlayerBackIcon } from './icon-skip-previous.svg'
import { ReactComponent as PlayerNextIcon } from './icon-skip-next.svg'
import spinnerIcon from './icon-spinner.png'
import {
  editorValidationState,
  editorValueState,
  focusedBoxValueState,
} from '../../../../recoil/texteditor/editor/selectors'
import { SENTENCEBOX_CATEGORY } from '../../../../recoil/texteditor/sentenceBox/atoms'
import useUpdateProject from '../../../../services/project/useUpdateProject'
import {
  currentPlayingTypeState,
  CURRENT_PLAYING_TYPE,
  isPlayingState,
} from '../../../../recoil/audiocontroller/selectors'

import { setSentenceBoxAudioManager } from '../../../../recoil/audiocontroller/audioControllerHelpers'
import useFirstAudioCreationHandler from '../hooks/useFirstAudioCreationHandler'
import {
  isLoadingAudioState,
  isValidSentenceBoxState,
  sentenceBoxValueState,
} from '../../../../recoil/texteditor/sentenceBox/selectors'
import { isLoadingProjectState } from '../../../../recoil/project/selectors'
import usePlaceholderSentenceText from '../../../../hooks/usePlaceholderSentenceText'
import { sentenceBoxIdsState } from '../../../../recoil/texteditor/editor/atoms'
import { HotkeysContextV2 } from '../../../../providers/AivatarHotKeysRootV2'

function AudioControllerButtonWrapper() {
  const { t } = useTranslation('project')
  const { playButtonRef, prevButtonRef, nextButtonRef } = useContext(HotkeysContextV2)
  const { placeholderSentenceText } = usePlaceholderSentenceText()

  /** Recoils */
  const editorTouched = useRecoilValue(editorValueState({ key: 'touched' }))
  const editorValidation = useRecoilValue(editorValidationState)
  const id = useRecoilValue(focusedBoxValueState({ category: SENTENCEBOX_CATEGORY.AUDIO, key: 'id' }))
  const sentenceBoxIds = useRecoilValue(sentenceBoxIdsState)
  const firstSentenceBoxText = useRecoilValue(sentenceBoxValueState({ id: sentenceBoxIds[0], key: 'text' }))
  const played = useRecoilValue(
    focusedBoxValueState({
      category: SENTENCEBOX_CATEGORY.AUDIO,
      key: 'played',
    }),
  )
  const duration = useRecoilValue(
    focusedBoxValueState({
      category: SENTENCEBOX_CATEGORY.AUDIO,
      key: 'duration',
    }),
  )

  const currentPlayingType = useRecoilValue(currentPlayingTypeState)

  const isPlaying = useRecoilValue(isPlayingState)

  const isLoadingProject = useRecoilValue(isLoadingProjectState)
  const isLoadingAudio = useRecoilValue(isLoadingAudioState({ id }))

  const isValidSentenceBox = useRecoilValue(isValidSentenceBoxState(id))

  const {
    updateStartFocused,
    updateNextFocused,
    updatePrevFocused,
    nextPlayInFirstInterval,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    checkIsValidAudioPreplay,
  } = useAudioControllerCallbacks()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { actionType, setActionType, FIRST_CREATION_ACTION } = useFirstAudioCreationHandler()
  /** Services */
  const { fetchProject, isCallSuccess: isCallSuccessFetchProject } = useUpdateProject()

  /** Values */
  const currentPlayingSeconds = useMemo(() => played * duration, [played, duration])

  const isMore2Seconds = useMemo(() => currentPlayingSeconds > 2, [currentPlayingSeconds])

  /** Displays */
  const displayPlayIcon = useMemo(() => (isPlaying ? <PauseIcon /> : <PlayIcon />), [isPlaying])

  const isLoadingPlay = useMemo(() => isLoadingProject || isLoadingAudio, [isLoadingProject, isLoadingAudio])

  /** Validations */

  const isCurrentPlayingNew = useMemo(() => currentPlayingType === CURRENT_PLAYING_TYPE.NEW, [currentPlayingType])

  const isValidPlayAction = useMemo(
    () =>
      !isLoadingPlay &&
      isValidSentenceBox &&
      (currentPlayingType === CURRENT_PLAYING_TYPE.SENTENCE ||
        currentPlayingType === CURRENT_PLAYING_TYPE.NEW ||
        currentPlayingType === CURRENT_PLAYING_TYPE.SENTENCE_INTERVAL),
    [isLoadingPlay, currentPlayingType, isValidSentenceBox],
  )

  const isValidMoveTract = useMemo(() => isValidPlayAction && isPlaying, [isValidPlayAction, isPlaying])

  const isInitializedSentenceBox = sentenceBoxIds.length === 1 && firstSentenceBoxText === placeholderSentenceText
  const disabledPlayButton = !isValidPlayAction || isLoadingPlay || isInitializedSentenceBox || !editorValidation.valid

  /** Event Handler */

  const prePlayProcess = async () => {
    // 1. Validation
    // const isValid = checkIsValidAudioPreplay()
    // if (!isValid) return
    // 2. Update Focused
    updateStartFocused()

    // 3 Update Project
    try {
      if (editorTouched) {
        await fetchProject()
      }
      // setTimeout(() => {
      setActionType(FIRST_CREATION_ACTION.CREATION)
      console.log('seop preplay')
      // }, 3000)
    } catch (error) {
      /* empty */
    }
  }

  // const [prePlayProcessing, setPrePlayProcessing] = useState(false)

  // useEffect(() => {
  //   if (prePlayProcessing && isCallSuccessFetchProject) {
  //     setPrePlayProcessing(false)
  //   }
  // }, [FIRST_CREATION_ACTION.CREATION, isCallSuccessFetchProject, prePlayProcessing, setActionType])

  const handlePlayer = async () => {
    console.log('seop handleplayer', isPlaying, isCurrentPlayingNew)
    if (disabledPlayButton) return
    try {
      if (isPlaying) {
        // 일시정지
        setActionType(FIRST_CREATION_ACTION.PLAY)
      } else if (isCurrentPlayingNew) {
        // setPrePlayProcessing(true)
        // 재생 시도
        await prePlayProcess()
      } else {
        // 중간부터 다시 재생
        setActionType(FIRST_CREATION_ACTION.PLAY)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onClickPrev = () => {
    // 2초
    if (isMore2Seconds) {
      setSentenceBoxAudioManager({ id })
      return
    }
    updatePrevFocused(id)
  }

  const onClickNext = () => {
    if (currentPlayingType === CURRENT_PLAYING_TYPE.FIRST_INTERVAL) {
      nextPlayInFirstInterval()
      return
    }
    updateNextFocused(id)
  }
  /** Life Cycle */
  useAivatarDidMount(async () => {})

  return (
    <div className="justify-center` relative mr-[27px] flex items-center gap-[10px]">
      <button
        type="button"
        ref={prevButtonRef}
        className={twMerge(
          'border-bcblue-500 flex h-[36px] w-[36px] cursor-pointer items-center justify-center rounded-full border bg-white',
          !isValidMoveTract && 'pointer-events-none opacity-70',
        )}
        onClick={onClickPrev}
        aria-label={t('이전 문장 재생')}
      >
        <PlayerBackIcon />
      </button>
      <button
        disabled={!isValidPlayAction || !editorValidation.valid}
        type="button"
        ref={playButtonRef}
        className={twMerge(
          `bg-bcblue-500 group relative flex h-[44px] w-[44px] cursor-pointer items-center justify-center rounded-full transition-all`,
          disabledPlayButton ? 'pointer-events-none opacity-70' : null,
        )}
        onClick={handlePlayer}
        aria-label={isPlaying ? t('일시정지') : t('재생')}
      >
        {displayPlayIcon}
        {isLoadingPlay && (
          <img src={spinnerIcon} className="absolute left-[2px] top-[2px] h-[40px] w-[40px] animate-spin" alt="" />
        )}
      </button>
      <button
        type="button"
        ref={nextButtonRef}
        className={twMerge(
          'border-bcblue-500 flex h-[36px] w-[36px] cursor-pointer items-center justify-center rounded-full border bg-white',
          !isValidMoveTract && 'pointer-events-none opacity-70',
        )}
        onClick={onClickNext}
        aria-label={t('다음 문장 재생')}
      >
        <PlayerNextIcon />
      </button>
    </div>
  )
}

export default AudioControllerButtonWrapper
