import React from 'react'

export default function IconPlus() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0508 6.4491H7.54538V1.94365C7.54538 1.64365 7.29993 1.39819 6.99993 1.39819C6.69993 1.39819 6.45447 1.64365 6.45447 1.94365V6.4491H1.94902C1.64902 6.4491 1.40356 6.69456 1.40356 6.99456C1.40356 7.29456 1.64902 7.54001 1.94902 7.54001H6.45447V12.0455C6.45447 12.3455 6.69993 12.5909 6.99993 12.5909C7.29993 12.5909 7.54538 12.3455 7.54538 12.0455V7.54001H12.0508C12.3508 7.54001 12.5963 7.29456 12.5963 6.99456C12.5963 6.69456 12.3508 6.4491 12.0508 6.4491Z"
        fill="white"
      />
    </svg>
  )
}
