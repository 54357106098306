/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo, useState } from 'react'
import { useMeasure } from 'react-use'

import { useRecoilState, useRecoilValue } from 'recoil'
import { twMerge } from 'tailwind-merge'
import { Alert, Skeleton, Snackbar, Tooltip } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import useOptionValueInput from './useOptionValueInput'
import useOptionValueSlider from './useOptionValueSlider'
import { focusedBoxValueState, selectedBoxValueState } from '../../../../../../../recoil/texteditor/editor/selectors'
import {
  SENTENCEBOX_CATEGORY,
  SentenceBoxValuesForFetch,
} from '../../../../../../../recoil/texteditor/sentenceBox/atoms'
import AivatarButtonV2 from '../../../../../../../components/buttons/AivatarButtonV2'
import OptionButton from './OptionButton'
import IconInfo from './IconInfo'
import AdjustButton from './AdjustButton'
import VolumeComponent from './VolumeComponent'
import { isPlayingState } from '../../../../../../../recoil/audiocontroller/selectors'
import useUpdateProject from '../../../../../../../services/project/useUpdateProject'
import useDisplayOptionValue from './useDisplayOptionValue'

function OptionContainer({ children }) {
  return (
    <div className="border-gs01-100 relative mb-[12px] w-full rounded-[8px] border bg-white p-[20px]">
      <div>{children}</div>
    </div>
  )
}

function OptionBox({ optionProps }) {
  const { getDisplayOptionValue } = useDisplayOptionValue()
  const { optionKey, name, icon, minTitle, maxTitle } = optionProps
  /** Recoils */
  const { component: InputComponent, setOptionInput: setInputValue } = useOptionValueInput(optionKey)
  const { component: SliderComponent } = useOptionValueSlider(optionKey)
  const { t, i18n } = useTranslation('project-option')

  const isPlaying = useRecoilValue(isPlayingState)
  const isInterval = useMemo(() => optionKey === 'space', [optionKey])
  const [v, setV] = useRecoilState(
    selectedBoxValueState({
      category: SENTENCEBOX_CATEGORY.OPTION,
      key: optionKey,
    }),
  )
  const [sentenceBoxValues, setSentenceBoxValues] = useRecoilState(SentenceBoxValuesForFetch)
  const [errorSnackbarOpened, setErrorSnackbarOpened] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const minValue = isInterval ? 0.1 : 0.5
  const maxValue = isInterval ? 99.9 : 1.5
  const minValueLabel = isInterval ? minValue : getDisplayOptionValue(minValue)
  const maxValueLabel = isInterval ? maxValue : getDisplayOptionValue(maxValue)
  const minusActivated = v > minValue
  const plusActivated = v < maxValue

  const dispatchError = (messageElement) => {
    setErrorSnackbarOpened(true)
    setErrorMessage(messageElement)
  }

  const onClickMinus = () => {
    if (minusActivated) {
      const number = Number((v - 0.1).toFixed(1))
      setV(number)
      setInputValue(number)
      setSentenceBoxValues({ ...sentenceBoxValues, [optionKey]: number })
      // dirtyInput()
    } else {
      console.log('seop error', errorSnackbarOpened)
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const optionName = name.toLowerCase()

      dispatchError(
        <Trans t={t}>
          {{ optionName }}은(는) <b>{{ minValueLabel }} 이상</b>으로 설정해 주세요.
        </Trans>,
      )
    }
  }

  const onClickPlus = () => {
    if (plusActivated) {
      const number = Number((v + 0.1).toFixed(1))
      setV(number)
      setInputValue(number)
      setSentenceBoxValues({ ...sentenceBoxValues, [optionKey]: number })
      // dirtyInput()
    } else {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const optionName = name.toLowerCase()

      dispatchError(
        <Trans t={t}>
          {{ optionName }}은(는) <b>{{ maxValueLabel }} 이하</b>로 설정해 주세요.
        </Trans>,
      )
    }
  }

  // const setValue = (value) => {
  //   setV(value)
  //   setInputValue(value)
  //   setSentenceBoxValues({ ...sentenceBoxValues, [optionKey]: value })
  // }

  /** Event handler */

  /** Memos */

  const handleErrorSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setErrorSnackbarOpened(false)
  }

  const focusedOptionValue = useRecoilValue(
    focusedBoxValueState({
      category: SENTENCEBOX_CATEGORY.OPTION,
      key: optionKey,
    }),
  )

  let component = null
  switch (optionKey) {
    case 'space':
      component = (
        <OptionContainer>
          <div className="flex justify-center">
            <div className="flex w-full items-center gap-[5px]">
              {/* <div className="mr-[5px] flex h-[35px] w-[35px] items-center justify-center rounded-sm bg-[#F5F7FE]">
          <img className="h-auto w-[20px]" src={icon} alt="optionbox" />
        </div> */}
              <div className={twMerge('w-min', i18n.language.startsWith('ko') ? 'whitespace-nowrap' : 'break-words')}>
                <span className="text-subtitle01 text-gs01-950">{name}</span>
              </div>
              <Tooltip
                title={
                  <div className="text-caption text-gs01-600 flex flex-col gap-[12px]">
                    <Trans t={t}>
                      <div className="font-[700]">최소 0.1초, 최대 99.9초로 설정할 수 있습니다.</div>
                      <div>✦ 문장 간격: 각 문장 뒤에 이어지는 쉼 구간을 의미합니다.</div>
                    </Trans>
                  </div>
                }
                className="z-10"
                classes={{ tooltip: '!bg-white !rounded-[8px] !px-[24px] !py-[12px] shadow-1' }}
              >
                <button type="button">
                  <IconInfo />
                </button>
              </Tooltip>
            </div>

            <div className="flex items-center gap-[5px]">
              <AdjustButton sign="minus" onClick={onClickMinus} notValid={!minusActivated} />
              <div className="flex items-center gap-[6px]">
                {InputComponent}
                <span className="text-button01 text-gs01-900">{t('초')}</span>
              </div>
              <AdjustButton sign="plus" onClick={onClickPlus} notValid={!plusActivated} />
            </div>
          </div>
        </OptionContainer>
      )
      break
    case 'volume':
      component = (
        <OptionContainer>
          <div className="mb-[20px] flex items-center justify-between">
            <div className="text-subtitle01 text-gs01-950">{t('{{optionName}} 조절', { optionName: name })}</div>
            {/* <div className="text-gs01-800 text-[16px] font-[700]">{v}</div> */}
          </div>
          <VolumeComponent />
        </OptionContainer>
      )
      break
    default:
      component = (
        <OptionContainer>
          <div className="mb-[20px] flex items-center justify-between">
            <div className="text-subtitle01 text-gs01-950">{t('{{optionName}} 조절', { optionName: name })}</div>
            <div className="text-gs01-800 text-[16px] font-[700]">
              {isPlaying ? getDisplayOptionValue(focusedOptionValue) : v !== 'Mixed' ? getDisplayOptionValue(v) : v}
            </div>
          </div>

          <div className="flex justify-between">
            <AdjustButton sign="minus" onClick={onClickMinus} notValid={!minusActivated} />
            {SliderComponent}
            <AdjustButton sign="plus" onClick={onClickPlus} notValid={!plusActivated} />
          </div>
        </OptionContainer>
      )
  }

  return (
    <>
      <Snackbar
        open={errorSnackbarOpened}
        autoHideDuration={6000}
        onClose={handleErrorSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        // message={errorMessage}
        // action={
        //   <button type="button" onClick={handleErrorSnackbarClose}>
        //     X
        //   </button>
        // }
      >
        <Alert
          onClose={handleErrorSnackbarClose}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
          icon={
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12 1.75C6.34 1.75 1.75 6.34 1.75 12C1.75 17.66 6.34 22.25 12 22.25C17.66 22.25 22.25 17.66 22.25 12C22.25 6.34 17.66 1.75 12 1.75ZM12.46 6.1C12.64 6.1 12.79 6.25 12.79 6.44L12.68 14.5H11.32L11.21 6.44C11.21 6.26 11.36 6.1 11.54 6.1H12.45H12.46ZM12 17.9C11.43 17.9 10.95 17.43 10.97 16.85C10.95 16.28 11.44 15.82 12 15.82C12.56 15.82 13.03 16.29 13.03 16.85C13.03 17.41 12.56 17.9 12 17.9Z"
                fill="#E54345"
              />
            </svg>
          }
          className="!rounded-[8px] !text-red-500 [&.MuiAlert-filled.MuiAlert-colorError]:bg-red-100"
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      {component}
    </>
  )
}

export default OptionBox
