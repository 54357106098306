import { useRecoilValue, useSetRecoilState } from 'recoil'
import { workspaceVoiceState } from '../../recoil/workspace/atoms'
import {
  isCallSuccessWorkspaceVoiceState,
  isLoadingWorkspaceVoiceState,
  workspaceVoiceValueState,
} from '../../recoil/workspace/selectors'
import useAivatarDidMount from '../../hooks/useAivatarDidMount'

import AivatarClient from '../AivatarClient'
import { CALL_STATE } from '../constants'

//! 이거 이렇게 만든사람 누구야
export default function useVoiceList({ workspaceId }) {
  /** Recoils */
  const setWorkspaceVoice = useSetRecoilState(workspaceVoiceState)
  const setWorkspaceVoiceApiState = useSetRecoilState(workspaceVoiceValueState({ key: 'apiState' }))

  const isCallSuccess = useRecoilValue(isCallSuccessWorkspaceVoiceState)
  const isLoading = useRecoilValue(isLoadingWorkspaceVoiceState)

  const fetch = async () => {
    try {
      setWorkspaceVoiceApiState(CALL_STATE.FETCHING)
      const res = await AivatarClient().get(`workspaces/${workspaceId}/voices`)
      const { data, status } = res
      if (status !== 200) throw new Error('Invalid Response Status')
      setWorkspaceVoice((prev) => ({
        ...prev,
        workspaceId,
        voices: data.voices.map((voiceData) => ({ ...voiceData.voice, alias: voiceData.alias })),
      }))

      setWorkspaceVoiceApiState(CALL_STATE.SUCCESS)
    } catch (error) {
      console.error(error.message)
      setWorkspaceVoiceApiState(CALL_STATE.ERROR)
    }
  }

  useAivatarDidMount(() => {
    fetch()
  }, [])

  return {
    fetchWorkspaceVoice: fetch,
    isLoading,
    isCallSuccess,
  }
}

export function useUpdateVoiceList({ workspaceId }) {
  /** Recoils */
  const setWorkspaceVoice = useSetRecoilState(workspaceVoiceState)

  const fetch = async () => {
    try {
      const res = await AivatarClient().get(`workspaces/${workspaceId}/voices`)
      const { data, status } = res
      if (status !== 200) throw new Error('Invalid Response Status')
      setWorkspaceVoice((prev) => ({
        ...prev,
        workspaceId,
        voices: data.voices.map((voiceData) => ({ ...voiceData.voice, alias: voiceData.alias })),
      }))
    } catch (error) {
      console.error(error.message)
    }
  }

  return {
    updateVoiceList: fetch,
  }
}
