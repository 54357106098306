/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useMemo, useCallback } from 'react'
import { NumericFormat } from 'react-number-format'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useParams } from 'react-router-dom'
import { debounce } from 'lodash'
import {
  editorValueState,
  focusedBoxValueState,
  selectedBoxIdsState,
  selectedBoxValueState,
} from '../../../../../../../recoil/texteditor/editor/selectors'
import {
  SENTENCEBOX_CATEGORY,
  SentenceBoxValuesForFetch,
} from '../../../../../../../recoil/texteditor/sentenceBox/atoms'
import useEditorCallbacks from '../../../../../../../recoil/texteditor/editor/useEditorCallbacks'
import useUpdateAudio from '../../../../../../../services/audio/useUpdateAudio'
import useAudioControllerCallbacks from '../../../../../../../recoil/audiocontroller/useAudioControllerCallbacks'
import useAivatarDidMount from '../../../../../../../hooks/useAivatarDidMount'
import { isPlayingState } from '../../../../../../../recoil/audiocontroller/selectors'

const useOptionValueInput = (optionKey) => {
  /** Recoilds */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const selectedBoxIds = useRecoilValue(selectedBoxIdsState)
  // const focusedBoxId = useRecoilValue(editorValueState({ key: 'focusedBoxId' }))

  const setPlayed = useSetRecoilState(
    selectedBoxValueState({
      category: SENTENCEBOX_CATEGORY.AUDIO,
      key: 'played',
    }),
  )
  const setIntervalPlayed = useSetRecoilState(
    selectedBoxValueState({
      category: SENTENCEBOX_CATEGORY.AUDIO,
      key: 'intervalPlayed',
    }),
  )

  const optionValue = useRecoilValue(
    selectedBoxValueState({
      category: SENTENCEBOX_CATEGORY.OPTION,
      key: optionKey,
    }),
  )

  const { setOptionValues } = useEditorCallbacks()

  /** Services */
  const { fetchAudioUpdate } = useUpdateAudio()

  /** Update Audio Effect */
  const [optionInput, setOptionInput] = useState(null)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { getAudioUpdateBody, setAudioById } = useAudioControllerCallbacks()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { workspaceId, projectId } = useParams()
  const setSentenceBoxValues = useSetRecoilState(SentenceBoxValuesForFetch)

  const isInterval = useMemo(() => optionKey === 'space', [optionKey])

  const getValidValue = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (floatValue) => {
      const oneDecimalPlaceValue = Math.floor(floatValue * 10) / 10

      if (isInterval) {
        return Math.max(Math.min(oneDecimalPlaceValue, 99.9), 0.1)
      }

      return Math.max(Math.min(oneDecimalPlaceValue, 1.5), 0.5)
    },
    [isInterval],
  )

  // TODO 이거 상위로 빼고, 옵션 선택하는 코드(다중셀렉포함)랑 같이 커플링되야됨
  const setOption = useMemo(
    () =>
      debounce((newValue) => {
        const validValue = getValidValue(newValue)

        setOptionInput(validValue)
        setOptionValues({ optionKey, optionValue: validValue })

        // TODO 다중옵션 적용시 [focusedBoxId] -> selectedBoxId로 바꿔야됨
        const body = getAudioUpdateBody({ sentenceBoxIds: selectedBoxIds, projectId })
        console.log('seop body', body, selectedBoxIds)
        fetchAudioUpdate({ sentenceBoxIds: selectedBoxIds })
        setSentenceBoxValues((prev) => ({ ...prev, ...body.attributes[0] }))
        setPlayed(0) // 재생바를 초기화해야 새로운음성이 생성되고 재생됨
        setIntervalPlayed(0)
      }, 500),
    //! 나머지 디팬던시는 없어도된다. 애초에 memoization이 안돼있어서 포함하면 망한다. 그래도 초기값이 분명한 함수들이라서 포함시키지 않아도 된다.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedBoxIds],
  )

  const changeOptionInput = useCallback(
    (newValue) => {
      setOptionInput(newValue)
      setOption(Number(newValue ?? '0'))
    },
    [setOption],
  )

  useAivatarDidMount(() => {
    setOptionInput(optionValue)
  }, [optionValue])

  // useDebounce(
  //   () => {
  //     if (!didMount) return
  //     if (!audioId) return
  //     if (value === -1) return

  //     const validValue = getValidValue(newValue ?? 0)

  //     setFloatValue(validValue)
  //     setOptionInput(validValue)
  //     setOptionValues({ optionKey, optionValue: validValue })

  //     // TODO 다중옵션 적용시 [focusedBoxId] -> selectedBoxId로 바꿔야됨
  //     const body = getAudioUpdateBody({ sentenceBoxIds: [focusedBoxId], projectId })
  //     fetchAudioUpdate({ sentenceBoxIds: [focusedBoxId] })
  //     setSentenceBoxValues({ ...sentenceBoxValues, ...body.attributes[0] })
  //     setPlayed(0) // 재생바를 초기화해야 새로운음성이 생성되고 재생됨
  //   },
  //   500,
  //   [value],
  // )

  /** Displays */

  const isPlaying = useRecoilValue(isPlayingState)
  const focusedOptionValue = useRecoilValue(
    focusedBoxValueState({
      category: SENTENCEBOX_CATEGORY.OPTION,
      key: optionKey,
    }),
  )

  const component = useMemo(
    () => (
      <div className="border-gs01-200 flex h-[32px] w-[59px] overflow-hidden rounded-[4px] border">
        <NumericFormat
          name="optionValue"
          className="text-placeholder text-gs01-700 w-full text-center"
          thousandSeparator={false}
          allowNegative={false}
          valueIsNumericString
          value={isPlaying ? focusedOptionValue : optionInput === 'Mixed' ? '' : optionInput}
          placeholder={optionInput === 'Mixed' ? 'Mixed' : undefined}
          onValueChange={(values, sourceInfo) => {
            if (sourceInfo.source === 'event') {
              changeOptionInput(values.value)
            }
          }}
          onKeyDown={(e) => {
            if (e.code === 'Escape') {
              e.currentTarget.blur()
            }
          }}
        />
      </div>
    ),
    [isPlaying, focusedOptionValue, optionInput, changeOptionInput],
  )

  return {
    optionValue,
    component,
    setOptionInput,
  }
}

export default useOptionValueInput
