import React from 'react'

export default function IconStarFilled() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.3399 2.37006L14.4799 9.09006C14.5299 9.24006 14.6699 9.34006 14.8199 9.34006H21.7599C22.1099 9.34006 22.2499 9.78006 21.9699 9.99006L16.3399 14.1601C16.2199 14.2501 16.1599 14.4101 16.2099 14.5601L18.3599 21.3001C18.4699 21.6301 18.0799 21.9101 17.7999 21.7001L12.1999 17.5501C12.0699 17.4601 11.8999 17.4601 11.7699 17.5501L6.16993 21.7001C5.88993 21.9101 5.50993 21.6301 5.60993 21.3001L7.75993 14.5601C7.80993 14.4101 7.75993 14.2501 7.62993 14.1601L1.99993 9.99006C1.71993 9.78006 1.86993 9.34006 2.20993 9.34006H9.14993C9.30993 9.34006 9.43993 9.24006 9.48993 9.09006L11.6299 2.37006C11.7399 2.04006 12.2099 2.04006 12.3099 2.37006H12.3399Z"
        fill="#F7DF28"
      />
    </svg>
  )
}
