/* eslint-disable import/prefer-default-export */

import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export default function useDisplayOptionValue() {
  const { t } = useTranslation('project-option')

  const getDisplayOptionValue = useCallback(
    (value: number) => {
      if (value === 1.0) {
        return t('기본')
      }

      const result = parseFloat(((value - 1.0) * 10).toFixed())
      return result > 0 ? `+${result}` : result
    },
    [t],
  )

  return { getDisplayOptionValue }
}
