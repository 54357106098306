import {ReactComponent as CrystalIcon} from '../../../../assets/images/icon-crystal.svg'
import AivatarButton from "../../../../components/buttons/AivatarButton";
import {useState} from "react";
import {useCurrentWorkspaceDetailQuery} from "../../../../hooks/queries";
import SelfCustomAvatarModal from "./SelfCustomAvatarModal";

function SelfCustomAvatarButton() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {data: workspace} = useCurrentWorkspaceDetailQuery()

  const isProPlan = workspace?.subscription?.plan?.name?.startsWith('PRO');

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <AivatarButton type="button" variant="w1" className="w-full" onClick={handleButtonClick}>
        <span className="mr-[5px]">셀프 커스텀 아바타 제작하기</span>
        <CrystalIcon/>
      </AivatarButton>

      {isModalOpen && (
        <SelfCustomAvatarModal
          isProPlan={isProPlan}
          closeModal={closeModal}
        />
      )}
    </>
  );
}

export default SelfCustomAvatarButton
