/* eslint-disable @typescript-eslint/no-unused-vars */
import { useTranslation } from 'react-i18next'
import Dialog from '.'

import { ReactComponent as IconComplete } from '../../assets/images/modal-complete.svg'
import AivatarButton from '../buttons/AivatarButton'
import AivatarButtonV2 from '../buttons/AivatarButtonV2'
import IconWarning from './IconWarning'

export default function Confirm({
  iconType,
  highlightColor = 'main',
  title,
  message,
  useCancel = false,
  cancelButtonName,
  confirmButtonName,
  onClickCancel,
  onClickConfirm,
  children,
  ...otherProps
}) {
  const { t } = useTranslation('modal')
  const Icon = iconType === 'complete' ? IconComplete : IconWarning

  const finalCancelButtonName = cancelButtonName ?? t('취소')
  const finalConfirmButtonName = confirmButtonName ?? t('확인')

  return (
    <Dialog PaperProps={{ className: '!p-[40px] shadow-1' }} {...otherProps}>
      <Icon />
      <span
        className={`text-gray-2 text-headline05 mt-[12px] whitespace-pre text-center [&>strong]:font-bold ${
          highlightColor === 'point-3' ? '[&>strong]:text-point-3' : '[&>strong]:text-main'
        }`}
      >
        {title}
      </span>
      {message && <span className="text-body01 text-gs01-600 mt-[8px] whitespace-pre text-center">{message}</span>}
      {children}
      <div className="mt-[23px] flex gap-[15px]">
        {useCancel && (
          <AivatarButtonV2
            type="button"
            variant="outlined-square"
            size="L"
            className="min-w-[130px]"
            onClick={onClickCancel}
          >
            {finalCancelButtonName}
          </AivatarButtonV2>
        )}
        <AivatarButtonV2
          type="button"
          variant="contained-square"
          size="L"
          className="min-w-[130px]"
          // className={`${
          //   highlightColor === 'point-3' ? 'btn-point-3' : 'btn-main'
          // } h-[45px] w-auto min-w-[120px] shrink-0 whitespace-nowrap px-[25px] font-bold`}
          onClick={onClickConfirm}
        >
          {finalConfirmButtonName}
        </AivatarButtonV2>
      </div>
    </Dialog>
  )
}
