/* eslint-disable @typescript-eslint/no-unused-vars */
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import { useRecoilState } from 'recoil'
import { projectValueState } from '../../../../../../recoil/project/selectors'

const max = 99.9
const min = 0
const step = 0.1

export default function FirstIntervalInput() {
  const [firstInterval, setFirstInterval] = useRecoilState(projectValueState({ key: 'firstInterval' }))
  const { t } = useTranslation('project-export')

  const changeFirstInterval = (value: string) => {
    const filteredValue = Math.min(Math.max(parseFloat(parseFloat(value).toFixed(1)), 0), 99.9)
    console.log('seop fiteredValue', filteredValue)
    setFirstInterval(filteredValue)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value

    // 숫자와 소수점 이외의 값 제거
    inputValue = inputValue.replace(/[^0-9.]/g, '')

    // 소수점 여러 개 입력 방지
    const parts = inputValue.split('.')
    if (parts.length > 2) {
      inputValue = `${parts[0]}.${parts[1]}`
    }

    // 소수점 이하 자릿수 제한
    if (parts[1]?.length && parts[1]?.length > 1) {
      parts[1] = parts[1].slice(0, 1)
      inputValue = parts.join('.')
    }

    // 숫자로 변환 후 최소값/최대값 제한
    const numericValue = parseFloat(inputValue)
    if (!Number.isNaN(numericValue)) {
      if (numericValue < min) inputValue = min.toString()
      if (numericValue > max) inputValue = max.toString()
    }

    setFirstInterval(inputValue)
  }

  const handleDecrement = () => {
    let numericValue = parseFloat(firstInterval) || 0
    numericValue = Math.max(min, numericValue - step)
    setFirstInterval(numericValue.toFixed(1))
  }

  const handleIncrement = () => {
    let numericValue = parseFloat(firstInterval) || 0
    numericValue = Math.min(max, numericValue + step)
    setFirstInterval(numericValue.toFixed(1))
  }

  return (
    <div className="flex items-center gap-[7px]">
      <div className="relative">
        <button onClick={handleDecrement} aria-label="minus" className="absolute left-[6px] top-1/2 -translate-y-1/2">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="20" height="20" rx="10" fill="#006CFF" />
            <path
              d="M14.2182 10.4545H5.79091C5.54091 10.4545 5.33636 10.25 5.33636 9.99996C5.33636 9.74996 5.54091 9.54541 5.79091 9.54541H14.2182C14.4682 9.54541 14.6727 9.74996 14.6727 9.99996C14.6727 10.25 14.4682 10.4545 14.2182 10.4545Z"
              fill="white"
            />
          </svg>
        </button>
        <input
          type="text"
          value={firstInterval}
          onChange={handleInputChange}
          className="border-gs01-200 text-placeholder h-[40px] w-[95px] rounded-[4px] border text-center"
        />
        <button onClick={handleIncrement} aria-label="plus" className="absolute right-[6px] top-1/2 -translate-y-1/2">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="20" height="20" rx="10" fill="#006CFF" />
            <path
              d="M14.2091 9.54088H10.4545V5.78633C10.4545 5.53633 10.25 5.33179 10 5.33179C9.75 5.33179 9.54546 5.53633 9.54546 5.78633V9.54088H5.79091C5.54091 9.54088 5.33636 9.74542 5.33636 9.99542C5.33636 10.2454 5.54091 10.45 5.79091 10.45H9.54546V14.2045C9.54546 14.4545 9.75 14.6591 10 14.6591C10.25 14.6591 10.4545 14.4545 10.4545 14.2045V10.45H14.2091C14.4591 10.45 14.6636 10.2454 14.6636 9.99542C14.6636 9.74542 14.4591 9.54088 14.2091 9.54088Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
      <span className="text-subtitle02 text-gs01-600">{t('초')}</span>
    </div>
  )
}
