import clsx from 'clsx'
import React, { ButtonHTMLAttributes } from 'react'
import { twJoin } from 'tailwind-merge'

export type AivatarButtonV2Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  variant: 'contained-square' | 'contained-rounded' | 'outlined-square' | 'outlined-rounded'
  size: 'L' | 'M' | 'S'
}

export default function AivatarButtonV2({ className, children, variant, size = 'M', ...rest }: AivatarButtonV2Props) {
  const variantStyle = clsx(
    variant === 'outlined-rounded' &&
      'border-bcblue-500 hover:border-bcblue-300 hover:text-bcblue-300 [&_path]:hover:fill-bcblue-300 text-bcblue-500 disabled:border-gs01-100 disabled:text-gs01-400 [&_path]:fill-bcblue-500 [&_path]:disabled:fill-gs01-100 rounded-[50px] border',
    variant === 'outlined-square' &&
      'border-bcblue-500 hover:border-bcblue-300 hover:text-bcblue-300 [&_path]:hover:fill-bcblue-300 text-bcblue-500 disabled:border-gs01-100 disabled:text-gs01-400 [&_path]:fill-bcblue-500 [&_path]:disabled:fill-gs01-100 rounded-[8px] border',
    variant === 'contained-rounded' &&
      'bg-bcblue-500 disabled:bg-gs01-100 rounded-[50px] border text-white disabled:text-white [&_path]:fill-white [&_path]:disabled:fill-white',
    variant === 'contained-square' &&
      'bg-bcblue-500 disabled:bg-gs01-100 rounded-[8px] border text-white disabled:text-white [&_path]:fill-white [&_path]:disabled:fill-white',
  )
  const sizeStyle = clsx(
    size === 'L' && 'text-button01 h-[40px] px-[20px]',
    size === 'M' && 'text-button01 h-[34px] px-[15px]',
    size === 'S' && 'text-button02 h-[24px] px-[12px]',
    size === 'S' && variant.endsWith('square') && 'rounded-[6px]',
  )

  return (
    <button
      className={twJoin('flex items-center justify-center gap-[5px]', variantStyle, sizeStyle, className)}
      {...rest}
    >
      {children}
    </button>
  )
}
