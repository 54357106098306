/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useDebounce } from 'react-use'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import useDidMount from '../../../../../../../hooks/useDidMount'
import { isPlayingState } from '../../../../../../../recoil/audiocontroller/selectors'
import useAudioControllerCallbacks from '../../../../../../../recoil/audiocontroller/useAudioControllerCallbacks'
import {
  focusedBoxValueState,
  selectedBoxIdsState,
  selectedBoxValueState,
} from '../../../../../../../recoil/texteditor/editor/selectors'
import {
  SentenceBoxValuesForFetch,
  SENTENCEBOX_CATEGORY,
} from '../../../../../../../recoil/texteditor/sentenceBox/atoms'
import useUpdateAudio from '../../../../../../../services/audio/useUpdateAudio'
import OptionButton from './OptionButton'

export default function VolumeComponent() {
  const { projectId } = useParams()
  const [selectedValue, setSelectedValue] = useRecoilState(
    selectedBoxValueState({
      category: SENTENCEBOX_CATEGORY.OPTION,
      key: 'volume',
    }),
  )
  const setPlayed = useSetRecoilState(
    selectedBoxValueState({
      category: SENTENCEBOX_CATEGORY.AUDIO,
      key: 'played',
    }),
  )
  const setIntervalPlayed = useSetRecoilState(
    selectedBoxValueState({
      category: SENTENCEBOX_CATEGORY.AUDIO,
      key: 'intervalPlayed',
    }),
  )
  const selectedBoxIds = useRecoilValue(selectedBoxIdsState)
  const [sentenceBoxValues, setSentenceBoxValues] = useRecoilState(SentenceBoxValuesForFetch)

  const { getAudioUpdateBody } = useAudioControllerCallbacks()
  const { fetchAudioUpdate } = useUpdateAudio()
  const didMount = useDidMount()
  const [value, setValue] = useState(-1)
  const { t } = useTranslation('project-option')

  useDebounce(
    () => {
      const body = getAudioUpdateBody({ sentenceBoxIds: selectedBoxIds, projectId })
      if (!didMount) return
      // if (!audioId) return
      if (value === -1) return
      fetchAudioUpdate({ sentenceBoxIds: selectedBoxIds })
      setSentenceBoxValues({ ...sentenceBoxValues, ...body.attributes[0] })
      setPlayed(0) // 재생바를 초기화해야 새로운음성이 생성되고 재생됨
      setIntervalPlayed(0)
    },
    500,
    [value],
  )

  const isPlaying = useRecoilValue(isPlayingState)

  const focusedOptionValue = useRecoilValue(
    focusedBoxValueState({
      category: SENTENCEBOX_CATEGORY.OPTION,
      key: 'volume',
    }),
  )

  const changeOptionValue = (value: number) => {
    setValue(value)
    setSelectedValue(value)
  }

  const displayValue = isPlaying ? focusedOptionValue : selectedValue

  return (
    <div className="flex w-full items-stretch justify-between">
      <OptionButton
        variant={displayValue === 0.5 ? 'contained-square' : 'outlined-square'}
        onClick={() => changeOptionValue(0.5)}
      >
        {t('작게')}
      </OptionButton>
      <OptionButton
        variant={displayValue === 0.75 ? 'contained-square' : 'outlined-square'}
        onClick={() => changeOptionValue(0.75)}
      >
        {t('조금 작게')}
      </OptionButton>
      <OptionButton
        variant={displayValue === 1.0 ? 'contained-square' : 'outlined-square'}
        onClick={() => changeOptionValue(1)}
      >
        {t('기본')}
      </OptionButton>
      <OptionButton
        variant={displayValue === 1.25 ? 'contained-square' : 'outlined-square'}
        onClick={() => changeOptionValue(1.25)}
      >
        {t('조금 크게')}
      </OptionButton>
      <OptionButton
        variant={displayValue === 1.5 ? 'contained-square' : 'outlined-square'}
        onClick={() => changeOptionValue(1.5)}
      >
        {t('크게')}
      </OptionButton>
    </div>
  )
}
