import React from 'react'
import { twMerge } from 'tailwind-merge'
import IconMinus from './IconMinus'
import IconPlus from './IconPlus'

type Props = {
  sign: 'plus' | 'minus'
  onClick: () => void
  notValid: boolean
}

export default function AdjustButton({ sign, onClick, notValid }: Props) {
  return (
    <button
      type="button"
      className={twMerge(
        'bg-bcblue-500 grid h-[24px] w-[24px] place-items-center rounded-full',
        notValid && 'bg-gs01-100',
      )}
      onClick={onClick}
    >
      {{ plus: <IconPlus />, minus: <IconMinus /> }[sign]}
    </button>
  )
}
