import { useTranslation } from 'react-i18next'
import { PlanName } from '../../@types/common'

type Props = {
  planName: PlanName
}

export default function PlanFeatures({ planName }: Props) {
  const { t } = useTranslation('plan-info-checkout')

  const allowedPlanList = ['BASIC-1', 'BASIC-2', 'PRO-1', 'PRO-2', 'PRO-3']

  const features: Record<PlanName, string[]> = {
    'BASIC-1': [
      t('매달 {{credits, number}} 크레딧', { credits: 1800 }),
      t('AI 아바타 {{avatar_count}}종', { avatar_count: 5 }),
      t('아바타별 제스처 {{gesture_count}}종', { gesture_count: 1 }),
      t('{{language_count}}개 언어 및 목소리', { language_count: 140 }),
      t('워터마크 제거'),
      t('무제한 다운로드'),
    ],
    'BASIC-2': [
      t('매달 {{credits, number}} 크레딧', { credits: 6000 }),
      t('AI 아바타 {{avatar_count}}종', { avatar_count: 5 }),
      t('아바타별 제스처 {{gesture_count}}종', { gesture_count: 1 }),
      t('{{language_count}}개 언어 및 목소리', { language_count: 140 }),
      t('워터마크 제거'),
      t('무제한 다운로드'),
    ],
    'PRO-1': [
      t('매달 {{credits, number}} 크레딧', { credits: 6000 }),
      t('AI 아바타 {{avatar_count}}종 이상', { avatar_count: 140 }),
      t('아바타별 모든 제스처'),
      t('{{language_count}}개 언어 및 목소리', { language_count: 140 }),
      t('워터마크 제거'),
      t('무제한 다운로드'),
    ],
    'PRO-2': [
      t('매달 {{credits, number}} 크레딧', { credits: 18000 }),
      t('AI 아바타 {{avatar_count}}종 이상', { avatar_count: 140 }),
      t('아바타별 모든 제스처'),
      t('{{language_count}}개 언어 및 목소리', { language_count: 140 }),
      t('워터마크 제거'),
      t('무제한 다운로드'),
    ],
    'PRO-3': [
      t('매달 {{credits, number}} 크레딧', { credits: 36000 }),
      t('AI 아바타 {{avatar_count}}종 이상', { avatar_count: 140 }),
      t('아바타별 모든 제스처'),
      t('{{language_count}}개 언어 및 목소리', { language_count: 140 }),
      t('워터마크 제거'),
      t('무제한 다운로드'),
    ],
    FREE: [],
    EXCLUSIVE: [],
  }

  // TODO 거부된 플랜도 자동화 할 수는 없을까?? 이정도로도 어느정도 정적분석은 가능할듯 하다. 여기서 실수 있다면 렌더링 부분에서 에러 날테니까
  if (!allowedPlanList.includes(planName)) return null

  return (
    <ul className="list-disc pl-[20px]">
      {features[planName].map((feature, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={idx} className="text-[14px] text-[#5e5e5e]">
          {feature}
        </li>
      ))}
    </ul>
  )
}
