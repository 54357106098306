import {ReactComponent as IconClose} from './icon-close.svg';

function ModalLayout({children, closeModal}) {
  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50 min-h-screen">
      <div className="relative w-[866px] min-h-[500px] max-h-[90vh] bg-white rounded-lg shadow-lg flex flex-col">
        <div className="absolute top-[10px] right-[10px]">
          <button
            type="button"
            className="p-2"
            onClick={closeModal}
            aria-label="닫기"
          >
            <IconClose className="w-6 h-6"/>
          </button>
        </div>

        {children}
      </div>
    </div>
  );
}

export default ModalLayout;
