import React from 'react'

export default function IconReset() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.2866 4.58946C13.9599 4.41627 13.5599 4.54283 13.3866 4.86922L12.9799 5.64191C11.9866 3.39045 9.57327 1.98496 7.0266 2.37796C5.01327 2.69104 3.33327 4.07655 2.63993 5.99495C2.51327 6.34133 2.69327 6.72101 3.03993 6.84757C3.3866 6.97413 3.7666 6.79428 3.89327 6.44791C4.41993 4.98912 5.69993 3.93667 7.23327 3.69687C9.07993 3.41044 10.8333 4.36298 11.6466 5.94166L11.0066 5.60194C10.6799 5.42876 10.2799 5.55532 10.1066 5.88171C9.93327 6.20811 10.0599 6.60777 10.3866 6.78096L12.5333 7.91335C12.6333 7.96664 12.7399 7.99329 12.8466 7.99329C13.0866 7.99329 13.3199 7.86672 13.4333 7.64025L14.5666 5.49537C14.7399 5.16897 14.6133 4.76931 14.2866 4.59612V4.58946Z"
        fill="#9CAEBE"
      />
      <path
        d="M12.9334 9.27229C12.5934 9.13907 12.2068 9.3056 12.0734 9.65198C11.5268 11.0575 10.2601 12.0766 8.76676 12.3098C7.64676 12.483 6.52676 12.2098 5.61343 11.5437C5.07343 11.1507 4.64676 10.6445 4.34676 10.065L4.99343 10.4047C5.32009 10.5779 5.7201 10.4513 5.89343 10.1249C6.06676 9.79852 5.94009 9.39885 5.61343 9.22567L3.46676 8.09328C3.14009 7.92009 2.74009 8.04665 2.56676 8.37304L1.43343 10.5179C1.26009 10.8443 1.38676 11.244 1.71343 11.4172C1.81343 11.4705 1.92009 11.4971 2.02676 11.4971C2.26676 11.4971 2.50009 11.3705 2.62009 11.1441L3.02676 10.3714C3.41343 11.2573 4.02676 12.0367 4.83343 12.6228C5.8001 13.3289 6.94009 13.6953 8.11343 13.6953C8.40009 13.6953 8.69343 13.6753 8.98009 13.6287C10.9401 13.3223 12.6068 11.99 13.3201 10.1382C13.4534 9.79186 13.2801 9.41218 12.9401 9.27895L12.9334 9.27229Z"
        fill="#9CAEBE"
      />
    </svg>
  )
}
