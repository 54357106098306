/* eslint-disable @typescript-eslint/no-unused-vars */
import { useTimeout } from 'react-use'

import { AnimatePresence, motion } from 'framer-motion'
import { useRecoilValue } from 'recoil'
import { useTranslation } from 'react-i18next'
import useAivatarDidMount from '../../../../hooks/useAivatarDidMount'
import {
  editorAudioValueState,
  focusedBoxState,
  isValidSetenceBoxAudioInEditorState,
  sentenceBoxesByTypeState,
  viewerBoxesByCategoryState,
  editorValueState,
} from '../../../../recoil/texteditor/editor/selectors'
import FailIcon from '../../../../assets/images/global_toast-fail.png'
import {
  sentenceBoxAudioState,
  sentenceBoxState,
  SENTENCEBOX_CATEGORY,
} from '../../../../recoil/texteditor/sentenceBox/atoms'
import {
  isValidSentenceBoxLengthState,
  sentenceBoxAudioValueState,
} from '../../../../recoil/texteditor/sentenceBox/selectors'
import { ReactComponent as CloseIcon } from '../../../../assets/images/icon-close.svg'

const durationTime = 4000

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function SentenceBoxErrorModalV2({ type, onClickClose }) {
  const { t } = useTranslation('project')
  /** Recoilds */
  const isValidSetenceBoxAudioInEditor = useRecoilValue(isValidSetenceBoxAudioInEditorState)

  const focusedBoxId = useRecoilValue(editorValueState({ key: 'focusedBoxId' }))
  const focusedBoxAudioErrorCode = useRecoilValue(sentenceBoxAudioValueState({ id: focusedBoxId, key: 'errorCode' }))
  const isValidSentenceBoxLength = useRecoilValue(isValidSentenceBoxLengthState({ id: focusedBoxId }))

  let headerMessage = ''

  if (!isValidSentenceBoxLength) {
    headerMessage = t('글자수가 400자를 초과했습니다. 400자 이내로 줄여 주세요.')
  } else {
    switch (focusedBoxAudioErrorCode) {
      case 'ERROR_USER':
        headerMessage = t('문장에 입력된 언어와 지정된 언어 옵션이 일치하지 않습니다. 확인 후 수정해 주세요.')
        break
      case 'ERROR_SYSTEM':
        headerMessage = t('음성 생성 중 시스템 오류가 발생하였습니다.')
        break
      default:
        headerMessage = t('일부 문장에 오류가 있습니다. 문장을 수정해주세요.')
    }
  }

  // const headerMessage = !isValidSetenceBoxAudioInEditor
  //   ? t('음성 생성이 불가한 문자나 기호가 포함되었습니다. 문자만 입력해 주세요.')
  //   : // : t('음성 생성에 실패하였습니다. 텍스트 또는 음성 옵션을 다시 한 번 확인해 주세요.')
  //     t('마침표, 물음표, 느낌표를 포함하여 400글자 이내로 작성해 주세요.')

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isReady, cancel] = useTimeout(durationTime)

  const getPopupOverlay = () => {
    const popupOverlayArray = document.getElementsByClassName('popup-overlay')
    if (!popupOverlayArray || popupOverlayArray.length <= 0) return null
    return popupOverlayArray[0]
  }

  useAivatarDidMount(() => {
    const popupOverlay = getPopupOverlay()
    popupOverlay.classList.add('pointerNone')
  }, [])

  useAivatarDidMount(() => {
    if (!isReady()) return
    onClickClose()
  }, [isReady()])

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: 50, scale: 0.3 }}
        animate={{ opacity: 1, y: 0, scale: 1 }}
        exit={{ opacity: 0, y: 20, scale: 0.5 }}
        className="relative flex items-center justify-center rounded-[8px] bg-red-100 px-[20px] py-[12px] text-white"
      >
        <div className="mr-[8px] h-[24px] w-[24px]">
          <img className="h-full w-full" src={FailIcon} alt="error" />
        </div>
        <div className="text-subtitle02 flex w-fit items-center overflow-hidden whitespace-pre-wrap text-[14px] font-medium text-red-500">
          {headerMessage}
        </div>
        <button className="ml-[24px] grid h-[24px] w-[24px] place-items-center" onClick={() => onClickClose()}>
          <CloseIcon className="h-[15px] w-[15px] [&_path]:stroke-red-500" />
        </button>
        <div className="absolute bottom-0 h-[2px] w-full sm:h-[3px]">
          <motion.div
            animate={{ width: 0 }}
            transition={{ ease: 'linear', duration: durationTime / 1000 }}
            className="absolute h-full w-full bg-[#ffffff50]"
          />
        </div>
      </motion.div>
    </AnimatePresence>
  )
}

export default SentenceBoxErrorModalV2
