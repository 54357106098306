import React from 'react'

export default function IconMale() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="16" rx="2" fill="#FCC175" />
      <path
        d="M8.50002 7.21006V4.57506L9.61002 5.68506C9.71002 5.78506 9.83502 5.83006 9.96502 5.83006C10.095 5.83006 10.22 5.78006 10.32 5.68506C10.515 5.49006 10.515 5.17506 10.32 4.98006L8.35502 3.01506C8.31002 2.97006 8.25502 2.93506 8.19502 2.91006C8.07502 2.86006 7.93502 2.86006 7.81502 2.91006C7.75502 2.93506 7.70002 2.97006 7.65002 3.02006L5.68502 4.98506C5.49002 5.18006 5.49002 5.49506 5.68502 5.69006C5.88002 5.88506 6.19502 5.88506 6.39002 5.69006L7.50002 4.58006V7.21506C6.09502 7.45506 5.02002 8.68006 5.02002 10.1501C5.02002 11.7951 6.36002 13.1301 8.00002 13.1301C9.64002 13.1301 10.98 11.7951 10.98 10.1501C10.98 8.67506 9.90502 7.45006 8.50002 7.21506V7.21006ZM8.00002 12.1301C6.90502 12.1301 6.02002 11.2401 6.02002 10.1501C6.02002 9.06006 6.91002 8.17006 8.00002 8.17006C9.09002 8.17006 9.98002 9.06006 9.98002 10.1501C9.98002 11.2401 9.09002 12.1301 8.00002 12.1301Z"
        fill="white"
      />
    </svg>
  )
}
