import {useState, useEffect} from 'react';
import ModalLayout from './ModalLayout';
import ProModalContent from './ProModalContent';
import UpgradePlanModalContent from "./UpgradePlanModalContent";

function SelfCustomAvatarModal({isProPlan, closeModal}) {
  const [content, setContent] = useState(null);

  useEffect(() => {
    if (isProPlan) {
      setContent(<ProModalContent/>);
    } else {
      setContent(<UpgradePlanModalContent/>);
    }
  }, [isProPlan]);

  return (
    <ModalLayout closeModal={closeModal}>
      {content}
    </ModalLayout>
  );
}

export default SelfCustomAvatarModal;
