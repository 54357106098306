import { Dispatch } from '@tiptap/core'
import { EditorState, TextSelection } from '@tiptap/pm/state'

export function getNextCursor(state: EditorState) {
  const { selection } = state
  const { $to } = selection

  let nextCursor = state.doc.resolve($to.pos + 1)
  while (nextCursor.nodeAfter?.type.name === 'space' || nextCursor.nodeAfter?.type.name === 'sentence') {
    nextCursor = state.doc.resolve(nextCursor.pos + 1)
  }

  return nextCursor
}

export function getPrevCursor(state: EditorState) {
  const { selection } = state
  const { $from } = selection

  let prevCursor = state.doc.resolve($from.pos - 1)
  while (
    prevCursor.pos > 0 &&
    (prevCursor.nodeAfter?.type.name === 'space' ||
      prevCursor.nodeAfter?.type.name === 'sentence' ||
      prevCursor.nodeAfter?.type.name === 'paragraph')
  ) {
    prevCursor = state.doc.resolve(prevCursor.pos - 1)
  }

  return prevCursor
}

export function goToNextCursor(state: EditorState, dispatch: Dispatch) {
  const { tr } = state
  const nextCursor = getNextCursor(state)

  tr.setSelection(TextSelection.create(tr.doc, nextCursor.pos))
  dispatch?.(tr.scrollIntoView())
}

export function goToPrevCursor(state: EditorState, dispatch: Dispatch) {
  const { tr } = state
  const prevCursor = getPrevCursor(state)

  tr.setSelection(TextSelection.create(tr.doc, prevCursor.pos))
  dispatch?.(tr.scrollIntoView())
}
