import React from 'react'

export default function IconPlay() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.63995 1.52L13.36 7.35333C13.8733 7.69333 13.8733 8.45333 13.36 8.79333L4.63329 14.6333C4.05995 15.02 3.28662 14.6067 3.28662 13.9133V2.24C3.28662 1.54666 4.06662 1.13333 4.63995 1.52Z"
        fill="white"
      />
    </svg>
  )
}
