import React from 'react'

export default function IconArrowDown() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.02686 12.86C7.85352 12.86 7.68686 12.7934 7.55352 12.6667L1.34686 6.45336C1.08686 6.19336 1.08686 5.77336 1.34686 5.51336C1.60686 5.25336 2.02686 5.25336 2.28686 5.51336L8.02686 11.2534L13.6669 5.61336C13.9269 5.35336 14.3469 5.35336 14.6069 5.61336C14.8669 5.87336 14.8669 6.29336 14.6069 6.55336L8.49352 12.6667C8.36019 12.8 8.19352 12.86 8.02019 12.86H8.02686Z"
        fill="#576076"
      />
    </svg>
  )
}
