/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable arrow-body-style */
import { debounce } from 'lodash'
import { useEffect, useRef } from 'react'

const useDebouncedCallback = (callback: any, delay: any) => {
  const callbackRef = useRef(callback)

  // 최신 callback을 항상 유지
  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  // debounce 인스턴스 생성
  const debounced = useRef<any>(
    debounce((...args) => {
      callbackRef.current(...args) // 최신 callback 호출
    }, delay),
  )

  // 컴포넌트 언마운트 시 debounce 정리
  useEffect(() => {
    return () => {
      debounced.current.cancel()
    }
  }, [])

  return debounced.current
}

export default useDebouncedCallback
