import React from 'react'

export default function NotSelectedIcon() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.0001 2.91669C10.5667 2.91669 2.91675 10.5667 2.91675 20C2.91675 29.4334 10.5667 37.0834 20.0001 37.0834C29.4334 37.0834 37.0834 29.4334 37.0834 20C37.0834 10.5667 29.4334 2.91669 20.0001 2.91669ZM20.7667 10.1667C21.0667 10.1667 21.3167 10.4167 21.3167 10.7334L21.1334 24.1667H18.8667L18.6834 10.7334C18.6834 10.4334 18.9334 10.1667 19.2334 10.1667H20.7501H20.7667ZM20.0001 29.8334C19.0501 29.8334 18.2501 29.05 18.2834 28.0834C18.2501 27.1334 19.0667 26.3667 20.0001 26.3667C20.9334 26.3667 21.7167 27.15 21.7167 28.0834C21.7167 29.0167 20.9334 29.8334 20.0001 29.8334Z"
        fill="#9CAEBE"
      />
    </svg>
  )
}
