import React from 'react'

export default function IconWarning() {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.0003 3.79175C13.737 3.79175 3.79199 13.7367 3.79199 26.0001C3.79199 38.2634 13.737 48.2084 26.0003 48.2084C38.2637 48.2084 48.2087 38.2634 48.2087 26.0001C48.2087 13.7367 38.2637 3.79175 26.0003 3.79175ZM26.997 13.2167C27.387 13.2167 27.712 13.5417 27.712 13.9534L27.4737 31.4167H24.527L24.2887 13.9534C24.2887 13.5634 24.6137 13.2167 25.0037 13.2167H26.9753H26.997ZM26.0003 38.7834C24.7653 38.7834 23.7253 37.7651 23.7687 36.5084C23.7253 35.2734 24.787 34.2767 26.0003 34.2767C27.2137 34.2767 28.232 35.2951 28.232 36.5084C28.232 37.7217 27.2137 38.7834 26.0003 38.7834Z"
        fill="#001F54"
      />
    </svg>
  )
}
