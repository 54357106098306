import {ReactComponent as IconCheck} from './icon-check.svg';
import {ReactComponent as IconInfoSuccess} from '../../../../../assets/images/icon-info-success.svg';
import AivatarButton from "../../../../../components/buttons/AivatarButton";
import useURI from "../../../../../hooks/useURI";
import useAuth from "../../../../../hooks/useAuth";
import {useCurrentWorkspaceDetailQuery} from "../../../../../hooks/queries";
import {useEffect, useRef} from "react";

function ProModalContent() {
  const {uri} = useURI();
  const {data: workspace} = useCurrentWorkspaceDetailQuery();
  const {user} = useAuth();
  const videoRef = useRef(null);

  const onCLickFileUpload = () => {
    window.open(
      `${uri['self-avatar-upload']}?email_address=${user.email}&workspace_number=${workspace?.id}`,
    );
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
      videoRef.current.muted = true;
    }
  }, []);

  return (
    <div className="flex flex-row w-full h-full">
      <div className="w-[350px] h-[622px] p-4 flex flex-col justify-center items-center gap-4">
        <div className="w-full h-full bg-[#EDEDED] rounded-lg flex justify-center items-center">
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video
            ref={videoRef}
            className="w-full h-full object-cover rounded-lg"
            controls loop
            controlsList="nofullscreen"
          >
            <source src="/videos/self-custom-modal.mp4" type="video/mp4"/>
            브라우저가 동영상을 지원하지 않습니다.
          </video>
        </div>
      </div>

      <div className="w-[487px] h-[622px] px-10 py-11 flex flex-col justify-center gap-3.5">
        <div className="text-3xl font-bold text-black">
          셀프 커스텀 아바타 베타 출시!<br/>
          지금 바로 만나보세요.
        </div>
        <div className="text-base font-normal text-black">
          내 스타일, 내 제스처, 내 목소리 그대로!<br/>
          <span className="font-bold">직접 촬영하고 녹음</span>하여 나만의 아바타를 만들어 보세요.
        </div>

        <div className="flex flex-col gap-1.5 mt-[18px]">
          <div className="flex items-center gap-3.5">
            <IconInfoSuccess size={20}/>
            <div className="text-base">웹캠이나 스마트폰만 있으면 OK!</div>
          </div>
          <div className="flex items-center gap-3.5">
            <IconInfoSuccess size={20}/>
            <div className="text-base">내 목소리 그대로 글로벌 언어로 소통하세요.</div>
          </div>
          <div className="flex items-center gap-3.5">
            <IconInfoSuccess size={20}/>
            <div className="text-base">의상도 배경도 내 스타일대로! 자유롭게 설정하세요.</div>
          </div>

          <div className="flex flex-col gap-2 mt-2 w-full">
            <div className="flex items-center text-sm text-[#001F54] font-medium">
              <a href={uri['self-avatar-guide']} target="_blank" rel="noreferrer" className="flex items-center gap-1">
                <span>상세 가이드 보기↗</span>
              </a>
              <a href={uri['aivatar-contact-us']} target="_blank" rel="noreferrer"
                 className="flex items-center gap-1 ml-4">
                <span>문의하기↗</span>
              </a>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center gap-3.5 bg-[#F5F7F9] px-3.5 py-5 rounded-[9.73px] mt-[18px]">
          <div className="text-center text-sm font-medium text-[#001F54]">
            🎉 베타 기간 동안 셀프 커스텀 아바타를 무료로 제작하세요!<br/>
            궁금한 점이 있으면 언제든 문의해 주세요.
          </div>
          <div className="w-96 flex justify-center items-center gap-3.5">
            <AivatarButton type="button" variant="m1" className="flex items-center gap-1 rounded-[8px]"
                           onClick={onCLickFileUpload}>
              파일 업로드하기
              <IconCheck className="w-4 h-4"/>
            </AivatarButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProModalContent;
