import { Tooltip } from '@mui/material'
import React, { ReactElement } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import { paragraphBoxIdsState, selectedParagraphBoxIdsState } from '../../../../../../recoil/texteditor/editor/atoms'

type Props = {
  children: ReactElement
}

export default function ParagraphListTooltip({ children }: Props) {
  const paragraphBoxIds = useRecoilValue(paragraphBoxIdsState)
  const selectedParagraphBoxIds = useRecoilValue(selectedParagraphBoxIdsState)
  const { t } = useTranslation('project')

  const selectedParagraphsOrdinalString = paragraphBoxIds
    .map((id, idx) => {
      if (selectedParagraphBoxIds.includes(id)) {
        return t('{{order}}', { order: idx + 1, count: idx + 1, ordinal: true })
      }

      return null
    })
    .filter(Boolean)
    .join(',')

  return (
    <Tooltip
      title={
        <div className="text-caption text-gs01-900 font-[700]">
          <Trans t={t}>{{ selectedParagraphsOrdinalString }}번째 문단</Trans>
        </div>
      }
      placement="top"
      className="z-10"
      classes={{
        tooltip: '!bg-white !rounded-[8px] !px-[24px] !py-[8px] !rounded-[53px] shadow-1',
        arrow: 'before:text-white',
      }}
      arrow
    >
      {children}
    </Tooltip>
  )
}
