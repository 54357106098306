import React from 'react'

export default function IconFilter() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.6202 20.24C13.4602 20.24 13.3102 20.2 13.1702 20.11L9.92018 18.01C9.69018 17.86 9.54018 17.6 9.54018 17.32V10.03L4.00018 5.15002C3.74018 4.92002 3.65018 4.56002 3.77018 4.24002C3.89018 3.92002 4.20018 3.71002 4.54018 3.71002H19.4402C19.7802 3.71002 20.0902 3.92002 20.2102 4.24002C20.3302 4.56002 20.2402 4.92002 19.9802 5.15002L14.4502 9.98002V19.41C14.4502 19.71 14.2902 19.99 14.0202 20.13C13.9002 20.2 13.7602 20.23 13.6302 20.23L13.6202 20.24ZM11.1902 16.87L12.8002 17.91V9.62002C12.8002 9.38002 12.9002 9.16002 13.0802 9.00002L17.2502 5.36002H6.72018L10.9102 9.04002C11.0902 9.20002 11.1902 9.42002 11.1902 9.66002V16.88V16.87Z"
        fill="#576076"
      />
    </svg>
  )
}
