import { useTranslation } from 'react-i18next'
import Section from '../Section'
import RecentProjectListItem from './RecentProjectListItem'
import { useCurrentWorkspaceProjectListQuery } from '../../../hooks/queries'

export default function RecentProjectListSection({ className = '' }) {
  const { t } = useTranslation('dashboard-recent-project')
  // const { projectList, fetchProjectList } = useProjectList()
  const { data } = useCurrentWorkspaceProjectListQuery({
    size: 3,
    sort: 'updatedAt,desc',
  })

  // useAivatarDidMount(() => {
  //   fetchProjectList({
  //     size: 3,
  //     sort: 'createdAt,desc',
  //   })
  // }, [])
  return (
    <Section title={t('최근 프로젝트')} linkTo="./project" className={className}>
      <ul className="mb-[44px] grid h-[240px] grid-cols-3 justify-between gap-[50px]">
        {data?.content.map((project, index) => (
          <RecentProjectListItem project={project} key={project.id} highlight={index === 0} />
        ))}
      </ul>
    </Section>
  )
}
