import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ReactComponent as BackIcon } from '../../../../assets/images/icon-back.svg'
import ExportButton from './ExportButton'
import SaveButton from './SaveButton'
import ProjectTitle from './ProjectTitle'
import useUpdateProject from '../../../../services/project/useUpdateProject'
import UpgradeButton from './UpgradeButton'
import useDialog from '../../../../hooks/useDialog'

function Header() {
  /** Services */
  const { fetchProject } = useUpdateProject()
  const { t } = useTranslation('project')
  const dialog = useDialog()
  const navigate = useNavigate()

  const onClickBack = () => {
    dialog.confirm({
      title: t('정말 뒤로가기 하시겠습니까?'),
      message: t('저장되지 않은 정보는 삭제됩니다.'),
      onClickConfirm: () => {
        dialog.close()
        fetchProject()
        navigate('/', { replace: false })
      },
    })
  }

  return (
    <section className="flex min-h-[80px] items-center border-b-[1px] border-[#CACACA]">
      <div className="navigator_width h-full">
        {/* <Link to="/"> */}
        <button
          type="button"
          aria-label={t('뒤로가기')}
          className="flex h-full w-full items-center justify-center [&_path]:hover:fill-[#004fff]"
          onClick={onClickBack}
        >
          <div className="h-[16px] w-[21.23px]">
            <BackIcon />
          </div>
        </button>
        {/* </Link> */}
      </div>

      <ProjectTitle />
      <div className="flex h-full items-center justify-center gap-[20px] pr-[50px]">
        <UpgradeButton />
        <SaveButton />
        <ExportButton />
      </div>
    </section>
  )
}

export default Header
