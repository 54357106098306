/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-else-return */
/* eslint-disable @typescript-eslint/no-shadow */
import {useQuery} from '@tanstack/react-query'
import {useParams} from 'react-router-dom'
import {useContext, useEffect, useReducer, useState} from 'react'
import _ from 'lodash'
import {workspaceQueries} from '../../../../../../../../../services/workspace'
import VoiceItem from '../../../components/VoiceItem'
import IconWarning from '../components/IconWarning'
import useURI from '../../../../../../../../../hooks/useURI'
import useLocaleList from '../../../../../../../../../hooks/useLocaleList'
import IconClose from '../../../components/icons/IconClose'
import LanguageToggle from '../../../components/LanguageToggle'
import AivatarButtonV2 from '../../../../../../../../../components/buttons/AivatarButtonV2'
import IconPlus from '../../../components/icons/IconPlus'
import {CircularProgress, MenuItem} from '@mui/material'
import {CustomsContext} from '..'
import {LocalStorage} from '../../../../../../../../../utils/storage'
import {SelectedFilter, VoiceSelectContext} from '../../..'
import {twMerge} from 'tailwind-merge'
import Filter from '../../../Header/Filter'
import IconFilter from '../../../Header/IconFilter'
import FilterSelect from '../../../Header/FilterSelect'
import useVoiceList, {useUpdateVoiceList} from '../../../../../../../../../services/workspace/useVoiceList'
import {useTranslation} from 'react-i18next'

const maxVcCount = 1

type Props = {
    onStartCreateVoice: () => void
}

export default function CustomsMain({onStartCreateVoice}: Props) {
    const {workspaceId} = useParams()
    const {uri} = useURI()

    const [filters, setFilters] = useState<SelectedFilter>([[]])
    const [searchKeyword, setSearchKeyword] = useState('')
    const [statusVisible, setStatusVisible] = useState(false)
    const [filterShow, toggleFilterShow] = useReducer((show) => !show, false)
    const [vcCreationDisabled, setVcCreationDisabled] = useState(true)
    const [voiceName, setVoiceName] = useState<string | null>(null)
    const {updateVoiceList} = useUpdateVoiceList({workspaceId})

    const {t} = useTranslation('voice-conversion')

    const filterExists = filters.some((filters) => filters.length !== 0)

    const {data: isVcEnabled, isPending: isPendingVcEnabled} = useQuery({
        ...workspaceQueries.detail(Number(workspaceId)),
        select: (data: any) => data.subscription?.isVcEnabled,
    })

    const {data, refetch} = useQuery({
        ...workspaceQueries.detail(Number(workspaceId))._ctx.voices,
        select: (data: any) =>
            data.voices
                .filter(
                    (item: any) =>
                        item.voice.type === 'CUSTOM' &&
                        filters.every(
                            (genderTags: string[]) =>
                                genderTags.length === 0 ||
                                genderTags.some((genderTag) => item.voice.gender === (genderTag === '남성' ? 'male' : 'female')),
                        ) &&
                        (voiceName ? item.voice.name.includes(voiceName) : true),
                )
                .map((item: any) => item.voice),
    })

    const {data: currentVcInfo, isPending: isPendingVcInfo} = useQuery({
        ...workspaceQueries.detail(Number(workspaceId))._ctx.voiceConversions,
        select: (data) => {
            //! status: READY, PROCESSING, FAIL, COMPLETED
            const firstItem = data
                .sort((a: any, b: any) => b.updatedAt.localeCompare(a.updatedAt))
                .filter((item: any) => ['PROCESSING', 'COMPLETED', 'FAIL'].includes(item.status))[0]

            return firstItem
        },
        refetchInterval: statusVisible ? 3000 : false,
        staleTime: 0,
        gcTime: 0,
    })

    const {data: completedVcList} = useQuery({
        ...workspaceQueries.detail(Number(workspaceId))._ctx.voiceConversions,
        select: (data) => {
            //! status: READY, PROCESSING, FAIL, COMPLETED
            const dataList = data.filter((item: any) => ['COMPLETED'].includes(item.status))

            return dataList
        },
        staleTime: 0,
        gcTime: 0,
    })

    const {localeList} = useLocaleList()
    const languages = [...new Set(data.map((item: any) => localeList[item.locale as keyof typeof localeList]))]
        .filter((item: any) => item)
        .sort((a, b) => (a as string).localeCompare(b as string)) as string[]
    const filteredLanguages = languages.filter((language) =>
        searchKeyword ? language.toUpperCase().includes(searchKeyword.toUpperCase()) : true,
    )

    const {changePage} = useContext(CustomsContext)
    const {selectedVoiceId} = useContext(VoiceSelectContext)

    const goToVcContactUs = () => {
        window.open(uri['contact-us'])
    }

    useEffect(() => {
        if (currentVcInfo?.status === 'COMPLETED') {
            refetch()
            updateVoiceList()

            window.setTimeout(() => {
                setStatusVisible(false)
                LocalStorage.lastCompletedVoiceConversionId = currentVcInfo.id
            }, 5000)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentVcInfo, refetch])

    useEffect(() => {
        if (currentVcInfo) {
            if (currentVcInfo.id === LocalStorage.lastCompletedVoiceConversionId) {
                setStatusVisible(false)
            } else {
                setStatusVisible(true)
            }
        }
    }, [currentVcInfo])

    useEffect(() => {
        if (!isPendingVcEnabled && !isPendingVcInfo) {
            if (isVcEnabled) {
                if (currentVcInfo?.status === 'PROCESSING' || completedVcList?.length >= maxVcCount) {
                    setVcCreationDisabled(true)
                } else {
                    setVcCreationDisabled(false)
                }
            } else {
                setVcCreationDisabled(false)
            }
        }
    }, [currentVcInfo, isPendingVcInfo, completedVcList, isPendingVcEnabled, isVcEnabled])

    useEffect(() => {
        const voiceArea = document.getElementById('voice-area')
        const scrollView = document.querySelector(`#voice-area [data-voice-id="${selectedVoiceId}"]`) as HTMLElement
        console.log('seop testtestzz', voiceArea, scrollView, selectedVoiceId)

        if (voiceArea && scrollView) voiceArea?.scrollTo({top: scrollView.offsetTop - 50})

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let vcStatusComponent = null
    switch (currentVcInfo?.status) {
        case 'PROCESSING':
            vcStatusComponent = (
                <div className="border-gs01-200 mt-[16px] flex h-[72px] items-center rounded-[8px] border px-[24px]">
                    {/* <pre>{JSON.stringify(currentVcInfo, undefined, 2)}</pre> */}
                    <div className="flex w-full justify-between">
                        <span className="text-headline05">{currentVcInfo?.name}</span>
                        <div className="flex items-center gap-[8px]">
              <span className="text-bcblue-500">
                <CircularProgress size={20} color="inherit"/>
              </span>
                            <div className="text-gs01-900 text-subtitle02">{t('목소리를 생성 중입니다.')}</div>
                        </div>
                    </div>
                </div>
            )
            break
        case 'COMPLETED':
            vcStatusComponent = (
                <div className="border-gs01-200 mt-[16px] flex h-[72px] items-center rounded-[8px] border px-[24px]">
                    {/* <pre>{JSON.stringify(currentVcInfo, undefined, 2)}</pre> */}
                    <div className="flex w-full justify-between">
                        <span className="text-headline05">{currentVcInfo?.name}</span>
                        <div className="flex items-center gap-[8px]">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M12 1.75C6.34 1.75 1.75 6.34 1.75 12C1.75 17.66 6.34 22.25 12 22.25C17.66 22.25 22.25 17.66 22.25 12C22.25 6.34 17.66 1.75 12 1.75ZM18.44 9.04L11.24 16.81C11.12 16.94 10.95 17.01 10.78 17.02H10.77C10.6 17.02 10.44 16.95 10.31 16.83L5.58 12.1C5.33 11.85 5.33 11.44 5.58 11.19C5.83 10.94 6.24 10.94 6.49 11.19L10.74 15.44L17.49 8.16C17.73 7.9 18.14 7.88 18.4 8.13C18.66 8.37 18.68 8.78 18.43 9.04H18.44Z"
                                    fill="#26C887"
                                />
                            </svg>
                            <div className="text-subtitle02 text-green-400">{t('목소리 생성이 완료되었습니다.')}</div>
                        </div>
                    </div>
                </div>
            )
            break
        case 'FAIL':
            vcStatusComponent = (
                <div className="border-gs01-200 mt-[16px] flex h-[72px] items-center rounded-[8px] border px-[24px]">
                    {/* <pre>{JSON.stringify(currentVcInfo, undefined, 2)}</pre> */}
                    <div className="flex w-full justify-between">
                        <span className="text-headline05">{currentVcInfo?.name}</span>
                        <div className="flex items-center">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M12.0003 1.78003C6.35027 1.78003 1.78027 6.35003 1.78027 12C1.78027 17.65 6.36027 22.22 12.0003 22.22C17.6403 22.22 22.2203 17.64 22.2203 12C22.2203 6.36003 17.6503 1.78003 12.0003 1.78003ZM17.1703 16.16C17.4503 16.44 17.4503 16.89 17.1703 17.17C16.8903 17.45 16.4403 17.45 16.1603 17.17L12.0003 13.01L7.84027 17.17C7.56027 17.45 7.11027 17.45 6.83027 17.17C6.55027 16.89 6.55027 16.44 6.83027 16.16L10.9903 12L6.83027 7.84003C6.55027 7.56003 6.55027 7.11003 6.83027 6.83003C7.11027 6.55003 7.56027 6.55003 7.84027 6.83003L12.0003 10.99L16.1603 6.83003C16.4403 6.55003 16.8903 6.55003 17.1703 6.83003C17.4503 7.11003 17.4503 7.56003 17.1703 7.84003L13.0103 12L17.1703 16.16Z"
                                    fill="#E54345"
                                />
                            </svg>
                            <div className="text-subtitle02 ml-[8px] text-red-500">
                                {t('목소리 생성을 실패했습니다. 다시 시도해보세요.')}
                            </div>
                            <div className="ml-[16px] flex gap-[11px]">
                                <AivatarButtonV2
                                    variant="contained-rounded"
                                    size="M"
                                    className="w-[108px]"
                                    onClick={() => changePage('writeInfo')}
                                >
                                    {t('다시 생성하기')}
                                </AivatarButtonV2>
                                <AivatarButtonV2
                                    variant="outlined-rounded"
                                    size="M"
                                    className="w-[108px]"
                                    onClick={() => {
                                        LocalStorage.lastAccessedWorkspaceId = currentVcInfo.id
                                        setStatusVisible(false)
                                    }}
                                >
                                    {t('취소')}
                                </AivatarButtonV2>
                            </div>
                        </div>
                    </div>
                </div>
            )
            break
        default:
            vcStatusComponent = null
    }

    return (
        <div className="h-full px-[32px] pt-[35px]">
            <div className="grid h-full grid-cols-[auto_1fr] grid-rows-[auto_1fr]">
                <div
                    className="border-gs01-200 relative row-span-2 mt-[40px] h-[calc(100%-40px)] w-[264px] overflow-y-auto rounded-t-[8px] border border-b-transparent px-[12px]">
                    <div className="sticky top-0 z-10 bg-white pb-[16px] pt-[12px]">
                        <div className="relative">
                            <input
                                type="text"
                                placeholder={t('언어 또는 국가 검색')}
                                className="border-gs01-200 placeholder:text-placeholder placeholder:text-gs01-300 h-[40px] w-full rounded-[4px] border px-[12px]"
                                value={searchKeyword}
                                onChange={(e) => setSearchKeyword(e.target.value)}
                            />
                            <button
                                type="button"
                                className="absolute right-[12px] top-1/2 -translate-y-1/2"
                                onClick={() => setSearchKeyword('')}
                            >
                                <IconClose/>
                            </button>
                        </div>
                    </div>
                    {filteredLanguages.length === 0 ? (
                        <div
                            className="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 flex-col items-center text-center">
                            <IconWarning/>
                            <div className="text-body01 text-gs01-600 mt-[8px]">
                                검색 결과를
                                <br/>
                                찾을 수 없습니다.
                            </div>
                        </div>
                    ) : (
                        Object.entries(_.groupBy(filteredLanguages, (language) => language?.split(' (')[0])).map(
                            ([languageGroup, languages]) => (
                                <LanguageToggle
                                    key={languageGroup}
                                    languageGroup={languageGroup}
                                    languages={languages}
                                    searchKeyword={searchKeyword}
                                    scrollToOffset={50}
                                />
                            ),
                        )
                    )}
                </div>
                <div className="pl-[24px]">
                    <div className="flex items-center justify-between">
                        <div className="flex items-end gap-[12px]">
                            <AivatarButtonV2
                                variant="contained-square"
                                size="L"
                                onClick={isVcEnabled ? onStartCreateVoice : goToVcContactUs}
                                disabled={vcCreationDisabled}
                            >
                                <span>{t('목소리 생성하기')}</span>
                                <IconPlus/>
                            </AivatarButtonV2>
                            <div className="text-headline06 text-gs01-900 font-[700]">
                                {t('업로드 음성 | {{vcCount}} / {{maxVcCount}}', {
                                    vcCount: completedVcList?.length,
                                    maxVcCount
                                })}
                            </div>
                        </div>

                        <div className="relative flex gap-[16px]">
                            <FilterSelect
                                value={voiceName ?? ''}
                                onChange={(e) => {
                                    setVoiceName((e.target.value as string) || null)
                                }}
                                displayEmpty
                            >
                                <MenuItem value="">{t('전체보기')}</MenuItem>
                                {completedVcList?.map((item: any) => (<MenuItem
                                    value={item.name}>{item.name}</MenuItem>))}
                            </FilterSelect>
                            <button
                                type="button"
                                className={twMerge(
                                    'border-gs01-200 hover:border-bcblue-500 [&_path]:hover:fill-bcblue-500 relative grid h-[40px] w-[40px] place-items-center rounded-[4px] border',
                                    filterShow &&
                                    'border-bcblue-500 bg-bcblue-500 hover:border-bcblue-500 [&_path]:fill-white [&_path]:hover:fill-white',
                                    filterExists &&
                                    'border-bcblue-500 bg-bcblue-500 hover:border-bcblue-500 [&_path]:hover:fill-bcblue-500 hover:bg-white [&_path]:fill-white',
                                )}
                                onClick={toggleFilterShow}
                            >
                                <IconFilter/>
                                {filterExists && (
                                    <div
                                        className="absolute bottom-full left-full h-[15px] w-[15px] -translate-x-1/2 translate-y-1/2 rounded-full bg-orange-500"/>
                                )}
                            </button>
                            {filterShow && (
                                <Filter
                                    className="absolute right-0 top-full"
                                    selectedFilters={filters}
                                    onSet={(filters) => setFilters(filters)}
                                    selectedTabIdx={2}
                                    onReset={() => setFilters([[]])}
                                    onClickAway={toggleFilterShow}
                                />
                            )}
                        </div>
                    </div>
                    {statusVisible && vcStatusComponent}
                </div>

                <div className="relative -mr-[20px] h-full overflow-y-auto overflow-x-hidden p-[24px]" id="voice-area">
                    <div className="h-full">
                        {data?.length === 0 ? (
                            <div className="grid h-full place-items-center">
                                <div className="flex flex-col items-center">
                                    <IconWarning/>
                                    <div
                                        className="text-headline05 text-gs01-950 mt-[8px] whitespace-pre text-center font-[500]">
                                        {'‘목소리 생성하기’ 버튼을 클릭하여\n나만의 커스텀 목소리를 만들어 보세요.'}
                                    </div>
                                    {/* <a
              href={uri['contact-us']}
              target="_blank"
              rel="noreferrer"
              className="text-button01 mt-[12px] inline-flex h-[40px] items-center rounded-[50px] bg-gradient-to-r from-[#006cff] to-[#18c95d] px-[20px] text-white"
              >
              커스텀 목소리 문의하기
              </a> */}
                                </div>
                            </div>
                        ) : (
                            Object.entries(_.groupBy(data, (item) => item.locale))
                                .map(([locale, voices]) => [localeList[locale as keyof typeof localeList], voices])
                                .filter((item) => item[0])
                                .sort((a, b) => (a[0] as string).localeCompare(b[0] as string))
                                .map(([language, voices]) => {
                                    const sortedVoices = (voices as any[]).sort((a, b) => {
                                        if (a.gender === b.gender) {
                                            return a.displayName.localeCompare(b.displayName)
                                        } else {
                                            if (a.gender === 'male' && b.gender === 'female') {
                                                return 1
                                            }

                                            return -1
                                        }
                                    })
                                    return (
                                        <>
                                            <div className="text-headline04 text-gs01-950 mb-[17px]"
                                                 id={`scroll-${language}`}>
                                                {language}
                                            </div>
                                            <ul className="grid grid-cols-[309px_309px_309px] gap-[16px] pb-[25px]">
                                                {(sortedVoices as any).map((item: any) => (
                                                    <VoiceItem key={item.id} data={item}/>
                                                ))}
                                            </ul>
                                        </>
                                    )
                                })
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
