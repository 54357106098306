import Aria_in_Blue_Shirt_en_video from '../videos/Aria_in_Blue_Shirt_en.webm'
import Aria_in_Blue_Shirt_en_thumb from '../thumbnails/Aria_in_Blue_Shirt_en_thumb.webp'
import Aria_in_Blue_Shirt_ko_video from '../videos/Aria_in_Blue_Shirt_ko.webm'
import Aria_in_Blue_Shirt_ko_thumb from '../thumbnails/Aria_in_Blue_Shirt_ko_thumb.webp'
import Aria_in_Blue_Suit_en_video from '../videos/Aria_in_Blue_Suit_en.webm'
import Aria_in_Blue_Suit_en_thumb from '../thumbnails/Aria_in_Blue_Suit_en_thumb.webp'
import Aria_in_Blue_Suit_ko_video from '../videos/Aria_in_Blue_Suit_ko.webm'
import Aria_in_Blue_Suit_ko_thumb from '../thumbnails/Aria_in_Blue_Suit_ko_thumb.webp'
import Aria_in_Green_Blazer_en_video from '../videos/Aria_in_Green_Blazer_en.webm'
import Aria_in_Green_Blazer_en_thumb from '../thumbnails/Aria_in_Green_Blazer_en_thumb.webp'
import Aria_in_Green_Blazer_ko_video from '../videos/Aria_in_Green_Blazer_ko.webm'
import Aria_in_Green_Blazer_ko_thumb from '../thumbnails/Aria_in_Green_Blazer_ko_thumb.webp'
import Aria_in_Pink_Dress_en_video from '../videos/Aria_in_Pink_Dress_en.webm'
import Aria_in_Pink_Dress_en_thumb from '../thumbnails/Aria_in_Pink_Dress_en_thumb.webp'
import Aria_in_Pink_Dress_ko_video from '../videos/Aria_in_Pink_Dress_ko.webm'
import Aria_in_Pink_Dress_ko_thumb from '../thumbnails/Aria_in_Pink_Dress_ko_thumb.webp'
import Blake_in_Beige_Suit_en_video from '../videos/Blake_in_Beige_Suit_en.webm'
import Blake_in_Beige_Suit_en_thumb from '../thumbnails/Blake_in_Beige_Suit_en_thumb.webp'
import Blake_in_Beige_Suit_ko_video from '../videos/Blake_in_Beige_Suit_ko.webm'
import Blake_in_Beige_Suit_ko_thumb from '../thumbnails/Blake_in_Beige_Suit_ko_thumb.webp'
import Blake_in_Black_Blazer_en_video from '../videos/Blake_in_Black_Blazer_en.webm'
import Blake_in_Black_Blazer_en_thumb from '../thumbnails/Blake_in_Black_Blazer_en_thumb.webp'
import Blake_in_Black_Blazer_ko_video from '../videos/Blake_in_Black_Blazer_ko.webm'
import Blake_in_Black_Blazer_ko_thumb from '../thumbnails/Blake_in_Black_Blazer_ko_thumb.webp'
import Blake_in_Navy_Suit_en_video from '../videos/Blake_in_Navy_Suit_en.webm'
import Blake_in_Navy_Suit_en_thumb from '../thumbnails/Blake_in_Navy_Suit_en_thumb.webp'
import Blake_in_Navy_Suit_ko_video from '../videos/Blake_in_Navy_Suit_ko.webm'
import Blake_in_Navy_Suit_ko_thumb from '../thumbnails/Blake_in_Navy_Suit_ko_thumb.webp'
import Dylan_in_Beige_Suit_en_video from '../videos/Dylan_in_Beige_Suit_en.webm'
import Dylan_in_Beige_Suit_en_thumb from '../thumbnails/Dylan_in_Beige_Suit_en_thumb.webp'
import Dylan_in_Beige_Suit_ko_video from '../videos/Dylan_in_Beige_Suit_ko.webm'
import Dylan_in_Beige_Suit_ko_thumb from '../thumbnails/Dylan_in_Beige_Suit_ko_thumb.webp'
import Dylan_in_Black_Blazer_en_video from '../videos/Dylan_in_Black_Blazer_en.webm'
import Dylan_in_Black_Blazer_en_thumb from '../thumbnails/Dylan_in_Black_Blazer_en_thumb.webp'
import Dylan_in_Black_Blazer_ko_video from '../videos/Dylan_in_Black_Blazer_ko.webm'
import Dylan_in_Black_Blazer_ko_thumb from '../thumbnails/Dylan_in_Black_Blazer_ko_thumb.webp'
import Dylan_in_Navy_Suit_en_video from '../videos/Dylan_in_Navy_Suit_en.webm'
import Dylan_in_Navy_Suit_en_thumb from '../thumbnails/Dylan_in_Navy_Suit_en_thumb.webp'
import Dylan_in_Navy_Suit_ko_video from '../videos/Dylan_in_Navy_Suit_ko.webm'
import Dylan_in_Navy_Suit_ko_thumb from '../thumbnails/Dylan_in_Navy_Suit_ko_thumb.webp'
import Hana_in_Blue_Dress_en_video from '../videos/Hana_in_Blue_Dress_en.webm'
import Hana_in_Blue_Dress_en_thumb from '../thumbnails/Hana_in_Blue_Dress_en_thumb.webp'
import Hana_in_Blue_Dress_ko_video from '../videos/Hana_in_Blue_Dress_ko.webm'
import Hana_in_Blue_Dress_ko_thumb from '../thumbnails/Hana_in_Blue_Dress_ko_thumb.webp'
import Hana_in_Orange_Blazer_en_video from '../videos/Hana_in_Orange_Blazer_en.webm'
import Hana_in_Orange_Blazer_en_thumb from '../thumbnails/Hana_in_Orange_Blazer_en_thumb.webp'
import Hana_in_Orange_Blazer_ko_video from '../videos/Hana_in_Orange_Blazer_ko.webm'
import Hana_in_Orange_Blazer_ko_thumb from '../thumbnails/Hana_in_Orange_Blazer_ko_thumb.webp'
import Hana_in_Pink_Dress_en_video from '../videos/Hana_in_Pink_Dress_en.webm'
import Hana_in_Pink_Dress_en_thumb from '../thumbnails/Hana_in_Pink_Dress_en_thumb.webp'
import Hana_in_Pink_Dress_ko_video from '../videos/Hana_in_Pink_Dress_ko.webm'
import Hana_in_Pink_Dress_ko_thumb from '../thumbnails/Hana_in_Pink_Dress_ko_thumb.webp'
import Jena_in_Beige_Shirt_en_video from '../videos/Jena_in_Beige_Shirt_en.webm'
import Jena_in_Beige_Shirt_en_thumb from '../thumbnails/Jena_in_Beige_Shirt_en_thumb.webp'
import Jena_in_Beige_Shirt_ko_video from '../videos/Jena_in_Beige_Shirt_ko.webm'
import Jena_in_Beige_Shirt_ko_thumb from '../thumbnails/Jena_in_Beige_Shirt_ko_thumb.webp'
import Jena_in_Blue_Dress_en_video from '../videos/Jena_in_Blue_Dress_en.webm'
import Jena_in_Blue_Dress_en_thumb from '../thumbnails/Jena_in_Blue_Dress_en_thumb.webp'
import Jena_in_Blue_Dress_ko_video from '../videos/Jena_in_Blue_Dress_ko.webm'
import Jena_in_Blue_Dress_ko_thumb from '../thumbnails/Jena_in_Blue_Dress_ko_thumb.webp'
import Jihun_in_Black_Suit_en_video from '../videos/Jihun_in_Black_Suit_en.webm'
import Jihun_in_Black_Suit_en_thumb from '../thumbnails/Jihun_in_Black_Suit_en_thumb.webp'
import Jihun_in_Black_Suit_ko_video from '../videos/Jihun_in_Black_Suit_ko.webm'
import Jihun_in_Black_Suit_ko_thumb from '../thumbnails/Jihun_in_Black_Suit_ko_thumb.webp'
import Jihun_in_Gray_Shirt_en_video from '../videos/Jihun_in_Gray_Shirt_en.webm'
import Jihun_in_Gray_Shirt_en_thumb from '../thumbnails/Jihun_in_Gray_Shirt_en_thumb.webp'
import Jihun_in_Gray_Shirt_ko_video from '../videos/Jihun_in_Gray_Shirt_ko.webm'
import Jihun_in_Gray_Shirt_ko_thumb from '../thumbnails/Jihun_in_Gray_Shirt_ko_thumb.webp'
import Jihun_in_Gray_Suit_en_video from '../videos/Jihun_in_Gray_Suit_en.webm'
import Jihun_in_Gray_Suit_en_thumb from '../thumbnails/Jihun_in_Gray_Suit_en_thumb.webp'
import Jihun_in_Gray_Suit_ko_video from '../videos/Jihun_in_Gray_Suit_ko.webm'
import Jihun_in_Gray_Suit_ko_thumb from '../thumbnails/Jihun_in_Gray_Suit_ko_thumb.webp'
import Jihun_in_Pink_Shirt_en_video from '../videos/Jihun_in_Pink_Shirt_en.webm'
import Jihun_in_Pink_Shirt_en_thumb from '../thumbnails/Jihun_in_Pink_Shirt_en_thumb.webp'
import Jihun_in_Pink_Shirt_ko_video from '../videos/Jihun_in_Pink_Shirt_ko.webm'
import Jihun_in_Pink_Shirt_ko_thumb from '../thumbnails/Jihun_in_Pink_Shirt_ko_thumb.webp'
import Jina_in_Beige_Shirt_en_video from '../videos/Jina_in_Beige_Shirt_en.webm'
import Jina_in_Beige_Shirt_en_thumb from '../thumbnails/Jina_in_Beige_Shirt_en_thumb.webp'
import Jina_in_Beige_Shirt_ko_video from '../videos/Jina_in_Beige_Shirt_ko.webm'
import Jina_in_Beige_Shirt_ko_thumb from '../thumbnails/Jina_in_Beige_Shirt_ko_thumb.webp'
import Jina_in_Blue_Dress_en_video from '../videos/Jina_in_Blue_Dress_en.webm'
import Jina_in_Blue_Dress_en_thumb from '../thumbnails/Jina_in_Blue_Dress_en_thumb.webp'
import Jina_in_Blue_Dress_ko_video from '../videos/Jina_in_Blue_Dress_ko.webm'
import Jina_in_Blue_Dress_ko_thumb from '../thumbnails/Jina_in_Blue_Dress_ko_thumb.webp'
import Jina_in_Purple_Hanbok_en_video from '../videos/Jina_in_Purple_Hanbok_en.webm'
import Jina_in_Purple_Hanbok_en_thumb from '../thumbnails/Jina_in_Purple_Hanbok_en_thumb.webp'
import Jina_in_Purple_Hanbok_ko_video from '../videos/Jina_in_Purple_Hanbok_ko.webm'
import Jina_in_Purple_Hanbok_ko_thumb from '../thumbnails/Jina_in_Purple_Hanbok_ko_thumb.webp'
import Jisoo_06_preview_en_video from '../videos/Jisoo_06_preview_en.webm'
import Jisoo_06_preview_en_thumb from '../thumbnails/Jisoo_06_preview_en_thumb.webp'
import Jisoo_06_preview_ko_video from '../videos/Jisoo_06_preview_ko.webm'
import Jisoo_06_preview_ko_thumb from '../thumbnails/Jisoo_06_preview_ko_thumb.webp'
import Jisoo_in_Blue_Hanbok_en_video from '../videos/Jisoo_in_Blue_Hanbok_en.webm'
import Jisoo_in_Blue_Hanbok_en_thumb from '../thumbnails/Jisoo_in_Blue_Hanbok_en_thumb.webp'
import Jisoo_in_Blue_Hanbok_ko_video from '../videos/Jisoo_in_Blue_Hanbok_ko.webm'
import Jisoo_in_Blue_Hanbok_ko_thumb from '../thumbnails/Jisoo_in_Blue_Hanbok_ko_thumb.webp'
import Jisoo_in_Gray_Suit_en_video from '../videos/Jisoo_in_Gray_Suit_en.webm'
import Jisoo_in_Gray_Suit_en_thumb from '../thumbnails/Jisoo_in_Gray_Suit_en_thumb.webp'
import Jisoo_in_Gray_Suit_ko_video from '../videos/Jisoo_in_Gray_Suit_ko.webm'
import Jisoo_in_Gray_Suit_ko_thumb from '../thumbnails/Jisoo_in_Gray_Suit_ko_thumb.webp'
import Jisoo_in_Navy_Dress_en_video from '../videos/Jisoo_in_Navy_Dress_en.webm'
import Jisoo_in_Navy_Dress_en_thumb from '../thumbnails/Jisoo_in_Navy_Dress_en_thumb.webp'
import Jisoo_in_Navy_Dress_ko_video from '../videos/Jisoo_in_Navy_Dress_ko.webm'
import Jisoo_in_Navy_Dress_ko_thumb from '../thumbnails/Jisoo_in_Navy_Dress_ko_thumb.webp'
import Jisoo_in_Red_Blazer_en_video from '../videos/Jisoo_in_Red_Blazer_en.webm'
import Jisoo_in_Red_Blazer_en_thumb from '../thumbnails/Jisoo_in_Red_Blazer_en_thumb.webp'
import Jisoo_in_Red_Blazer_ko_video from '../videos/Jisoo_in_Red_Blazer_ko.webm'
import Jisoo_in_Red_Blazer_ko_thumb from '../thumbnails/Jisoo_in_Red_Blazer_ko_thumb.webp'
import Jisoo_in_Red_Hanbok_en_video from '../videos/Jisoo_in_Red_Hanbok_en.webm'
import Jisoo_in_Red_Hanbok_en_thumb from '../thumbnails/Jisoo_in_Red_Hanbok_en_thumb.webp'
import Jisoo_in_Red_Hanbok_ko_video from '../videos/Jisoo_in_Red_Hanbok_ko.webm'
import Jisoo_in_Red_Hanbok_ko_thumb from '../thumbnails/Jisoo_in_Red_Hanbok_ko_thumb.webp'
import Junho_01_en_video from '../videos/Junho_01_en.webm'
import Junho_01_en_thumb from '../thumbnails/Junho_01_en_thumb.webp'
import Junho_01_ko_video from '../videos/Junho_01_ko.webm'
import Junho_01_ko_thumb from '../thumbnails/Junho_01_ko_thumb.webp'
import Junho_02_en_video from '../videos/Junho_02_en.webm'
import Junho_02_en_thumb from '../thumbnails/Junho_02_en_thumb.webp'
import Junho_02_ko_video from '../videos/Junho_02_ko.webm'
import Junho_02_ko_thumb from '../thumbnails/Junho_02_ko_thumb.webp'
import Junho_03_en_video from '../videos/Junho_03_en.webm'
import Junho_03_en_thumb from '../thumbnails/Junho_03_en_thumb.webp'
import Junho_03_ko_video from '../videos/Junho_03_ko.webm'
import Junho_03_ko_thumb from '../thumbnails/Junho_03_ko_thumb.webp'
import Junho_04_en_video from '../videos/Junho_04_en.webm'
import Junho_04_en_thumb from '../thumbnails/Junho_04_en_thumb.webp'
import Junho_04_ko_video from '../videos/Junho_04_ko.webm'
import Junho_04_ko_thumb from '../thumbnails/Junho_04_ko_thumb.webp'
import Junho_05_en_video from '../videos/Junho_05_en.webm'
import Junho_05_en_thumb from '../thumbnails/Junho_05_en_thumb.webp'
import Junho_05_ko_video from '../videos/Junho_05_ko.webm'
import Junho_05_ko_thumb from '../thumbnails/Junho_05_ko_thumb.webp'
import Junho_06_en_video from '../videos/Junho_06_en.webm'
import Junho_06_en_thumb from '../thumbnails/Junho_06_en_thumb.webp'
import Junho_06_ko_video from '../videos/Junho_06_ko.webm'
import Junho_06_ko_thumb from '../thumbnails/Junho_06_ko_thumb.webp'
import Junho_07_en_video from '../videos/Junho_07_en.webm'
import Junho_07_en_thumb from '../thumbnails/Junho_07_en_thumb.webp'
import Junho_07_ko_video from '../videos/Junho_07_ko.webm'
import Junho_07_ko_thumb from '../thumbnails/Junho_07_ko_thumb.webp'
import Layla_in_Beige_Shirt_en_video from '../videos/Layla_in_Beige_Shirt_en.webm'
import Layla_in_Beige_Shirt_en_thumb from '../thumbnails/Layla_in_Beige_Shirt_en_thumb.webp'
import Layla_in_Beige_Shirt_ko_video from '../videos/Layla_in_Beige_Shirt_ko.webm'
import Layla_in_Beige_Shirt_ko_thumb from '../thumbnails/Layla_in_Beige_Shirt_ko_thumb.webp'
import Layla_in_Beige_Shirt_with_Hijab_en_video from '../videos/Layla_in_Beige_Shirt_with_Hijab_en.webm'
import Layla_in_Beige_Shirt_with_Hijab_en_thumb from '../thumbnails/Layla_in_Beige_Shirt_with_Hijab_en_thumb.webp'
import Layla_in_Beige_Shirt_with_Hijab_ko_video from '../videos/Layla_in_Beige_Shirt_with_Hijab_ko.webm'
import Layla_in_Beige_Shirt_with_Hijab_ko_thumb from '../thumbnails/Layla_in_Beige_Shirt_with_Hijab_ko_thumb.webp'
import Layla_in_Beige_Suit_with_Hijab_en_video from '../videos/Layla_in_Beige_Suit_with_Hijab_en.webm'
import Layla_in_Beige_Suit_with_Hijab_en_thumb from '../thumbnails/Layla_in_Beige_Suit_with_Hijab_en_thumb.webp'
import Layla_in_Beige_Suit_with_Hijab_ko_video from '../videos/Layla_in_Beige_Suit_with_Hijab_ko.webm'
import Layla_in_Beige_Suit_with_Hijab_ko_thumb from '../thumbnails/Layla_in_Beige_Suit_with_Hijab_ko_thumb.webp'
import Layla_in_Black_Dress_with_Hijab_en_video from '../videos/Layla_in_Black_Dress_with_Hijab_en.webm'
import Layla_in_Black_Dress_with_Hijab_en_thumb from '../thumbnails/Layla_in_Black_Dress_with_Hijab_en_thumb.webp'
import Layla_in_Black_Dress_with_Hijab_ko_video from '../videos/Layla_in_Black_Dress_with_Hijab_ko.webm'
import Layla_in_Black_Dress_with_Hijab_ko_thumb from '../thumbnails/Layla_in_Black_Dress_with_Hijab_ko_thumb.webp'
import Layla_in_Gray_Dress_en_video from '../videos/Layla_in_Gray_Dress_en.webm'
import Layla_in_Gray_Dress_en_thumb from '../thumbnails/Layla_in_Gray_Dress_en_thumb.webp'
import Layla_in_Gray_Dress_ko_video from '../videos/Layla_in_Gray_Dress_ko.webm'
import Layla_in_Gray_Dress_ko_thumb from '../thumbnails/Layla_in_Gray_Dress_ko_thumb.webp'
import Layla_in_Orange_Blazer_en_video from '../videos/Layla_in_Orange_Blazer_en.webm'
import Layla_in_Orange_Blazer_en_thumb from '../thumbnails/Layla_in_Orange_Blazer_en_thumb.webp'
import Layla_in_Orange_Blazer_ko_video from '../videos/Layla_in_Orange_Blazer_ko.webm'
import Layla_in_Orange_Blazer_ko_thumb from '../thumbnails/Layla_in_Orange_Blazer_ko_thumb.webp'
import Layla_in_Orange_Blazer_with_Hijab_en_video from '../videos/Layla_in_Orange_Blazer_with_Hijab_en.webm'
import Layla_in_Orange_Blazer_with_Hijab_en_thumb from '../thumbnails/Layla_in_Orange_Blazer_with_Hijab_en_thumb.webp'
import Layla_in_Orange_Blazer_with_Hijab_ko_video from '../videos/Layla_in_Orange_Blazer_with_Hijab_ko.webm'
import Layla_in_Orange_Blazer_with_Hijab_ko_thumb from '../thumbnails/Layla_in_Orange_Blazer_with_Hijab_ko_thumb.webp'
import Layla_in_Orange_Shirt_en_video from '../videos/Layla_in_Orange_Shirt_en.webm'
import Layla_in_Orange_Shirt_en_thumb from '../thumbnails/Layla_in_Orange_Shirt_en_thumb.webp'
import Layla_in_Orange_Shirt_ko_video from '../videos/Layla_in_Orange_Shirt_ko.webm'
import Layla_in_Orange_Shirt_ko_thumb from '../thumbnails/Layla_in_Orange_Shirt_ko_thumb.webp'
import Lina_in_Blue_Suit_en_video from '../videos/Lina_in_Blue_Suit_en.webm'
import Lina_in_Blue_Suit_en_thumb from '../thumbnails/Lina_in_Blue_Suit_en_thumb.webp'
import Lina_in_Blue_Suit_ko_video from '../videos/Lina_in_Blue_Suit_ko.webm'
import Lina_in_Blue_Suit_ko_thumb from '../thumbnails/Lina_in_Blue_Suit_ko_thumb.webp'
import Lina_in_White_Shirt_en_video from '../videos/Lina_in_White_Shirt_en.webm'
import Lina_in_White_Shirt_en_thumb from '../thumbnails/Lina_in_White_Shirt_en_thumb.webp'
import Lina_in_White_Shirt_ko_video from '../videos/Lina_in_White_Shirt_ko.webm'
import Lina_in_White_Shirt_ko_thumb from '../thumbnails/Lina_in_White_Shirt_ko_thumb.webp'
import Mateo_in_Black_Blazer_en_video from '../videos/Mateo_in_Black_Blazer_en.webm'
import Mateo_in_Black_Blazer_en_thumb from '../thumbnails/Mateo_in_Black_Blazer_en_thumb.webp'
import Mateo_in_Black_Blazer_ko_video from '../videos/Mateo_in_Black_Blazer_ko.webm'
import Mateo_in_Black_Blazer_ko_thumb from '../thumbnails/Mateo_in_Black_Blazer_ko_thumb.webp'
import Mateo_in_Black_Suit_with_Blue_Tie_en_video from '../videos/Mateo_in_Black_Suit_with_Blue_Tie_en.webm'
import Mateo_in_Black_Suit_with_Blue_Tie_en_thumb from '../thumbnails/Mateo_in_Black_Suit_with_Blue_Tie_en_thumb.webp'
import Mateo_in_Black_Suit_with_Blue_Tie_ko_video from '../videos/Mateo_in_Black_Suit_with_Blue_Tie_ko.webm'
import Mateo_in_Black_Suit_with_Blue_Tie_ko_thumb from '../thumbnails/Mateo_in_Black_Suit_with_Blue_Tie_ko_thumb.webp'
import Mateo_in_Black_Suit_with_Pink_Tie_en_video from '../videos/Mateo_in_Black_Suit_with_Pink_Tie_en.webm'
import Mateo_in_Black_Suit_with_Pink_Tie_en_thumb from '../thumbnails/Mateo_in_Black_Suit_with_Pink_Tie_en_thumb.webp'
import Mateo_in_Black_Suit_with_Pink_Tie_ko_video from '../videos/Mateo_in_Black_Suit_with_Pink_Tie_ko.webm'
import Mateo_in_Black_Suit_with_Pink_Tie_ko_thumb from '../thumbnails/Mateo_in_Black_Suit_with_Pink_Tie_ko_thumb.webp'
import Mateo_in_Brown_Suit_en_video from '../videos/Mateo_in_Brown_Suit_en.webm'
import Mateo_in_Brown_Suit_en_thumb from '../thumbnails/Mateo_in_Brown_Suit_en_thumb.webp'
import Mateo_in_Brown_Suit_ko_video from '../videos/Mateo_in_Brown_Suit_ko.webm'
import Mateo_in_Brown_Suit_ko_thumb from '../thumbnails/Mateo_in_Brown_Suit_ko_thumb.webp'
import Mateo_in_Gray_Blazer_en_video from '../videos/Mateo_in_Gray_Blazer_en.webm'
import Mateo_in_Gray_Blazer_en_thumb from '../thumbnails/Mateo_in_Gray_Blazer_en_thumb.webp'
import Mateo_in_Gray_Blazer_ko_video from '../videos/Mateo_in_Gray_Blazer_ko.webm'
import Mateo_in_Gray_Blazer_ko_thumb from '../thumbnails/Mateo_in_Gray_Blazer_ko_thumb.webp'
import Mateo_in_Navy_Suit_en_video from '../videos/Mateo_in_Navy_Suit_en.webm'
import Mateo_in_Navy_Suit_en_thumb from '../thumbnails/Mateo_in_Navy_Suit_en_thumb.webp'
import Mateo_in_Navy_Suit_ko_video from '../videos/Mateo_in_Navy_Suit_ko.webm'
import Mateo_in_Navy_Suit_ko_thumb from '../thumbnails/Mateo_in_Navy_Suit_ko_thumb.webp'
import Mina_in_Blue_Shirt_en_video from '../videos/Mina_in_Blue_Shirt_en.webm'
import Mina_in_Blue_Shirt_en_thumb from '../thumbnails/Mina_in_Blue_Shirt_en_thumb.webp'
import Mina_in_Blue_Shirt_ko_video from '../videos/Mina_in_Blue_Shirt_ko.webm'
import Mina_in_Blue_Shirt_ko_thumb from '../thumbnails/Mina_in_Blue_Shirt_ko_thumb.webp'
import Mina_in_Pink_Dress_en_video from '../videos/Mina_in_Pink_Dress_en.webm'
import Mina_in_Pink_Dress_en_thumb from '../thumbnails/Mina_in_Pink_Dress_en_thumb.webp'
import Mina_in_Pink_Dress_ko_video from '../videos/Mina_in_Pink_Dress_ko.webm'
import Mina_in_Pink_Dress_ko_thumb from '../thumbnails/Mina_in_Pink_Dress_ko_thumb.webp'
import Mina_in_Red_Blazer_en_video from '../videos/Mina_in_Red_Blazer_en.webm'
import Mina_in_Red_Blazer_en_thumb from '../thumbnails/Mina_in_Red_Blazer_en_thumb.webp'
import Mina_in_Red_Blazer_ko_video from '../videos/Mina_in_Red_Blazer_ko.webm'
import Mina_in_Red_Blazer_ko_thumb from '../thumbnails/Mina_in_Red_Blazer_ko_thumb.webp'
import Minho_in_Black_Suit_en_cut_video from '../videos/Minho_in_Black_Suit_en_cut.webm'
import Minho_in_Black_Suit_en_cut_thumb from '../thumbnails/Minho_in_Black_Suit_en_cut_thumb.webp'
import Minho_in_Black_Suit_ko_cut_video from '../videos/Minho_in_Black_Suit_ko_cut.webm'
import Minho_in_Black_Suit_ko_cut_thumb from '../thumbnails/Minho_in_Black_Suit_ko_cut_thumb.webp'
import Minho_in_Gray_Suit_en_cut_video from '../videos/Minho_in_Gray_Suit_en_cut.webm'
import Minho_in_Gray_Suit_en_cut_thumb from '../thumbnails/Minho_in_Gray_Suit_en_cut_thumb.webp'
import Minho_in_Gray_Suit_ko_cut_video from '../videos/Minho_in_Gray_Suit_ko_cut.webm'
import Minho_in_Gray_Suit_ko_cut_thumb from '../thumbnails/Minho_in_Gray_Suit_ko_cut_thumb.webp'
import Minji_in_Blue_Suit_en_video from '../videos/Minji_in_Blue_Suit_en.webm'
import Minji_in_Blue_Suit_en_thumb from '../thumbnails/Minji_in_Blue_Suit_en_thumb.webp'
import Minji_in_Blue_Suit_ko_video from '../videos/Minji_in_Blue_Suit_ko.webm'
import Minji_in_Blue_Suit_ko_thumb from '../thumbnails/Minji_in_Blue_Suit_ko_thumb.webp'
import Minji_in_White_Shirt_en_video from '../videos/Minji_in_White_Shirt_en.webm'
import Minji_in_White_Shirt_en_thumb from '../thumbnails/Minji_in_White_Shirt_en_thumb.webp'
import Minji_in_White_Shirt_ko_video from '../videos/Minji_in_White_Shirt_ko.webm'
import Minji_in_White_Shirt_ko_thumb from '../thumbnails/Minji_in_White_Shirt_ko_thumb.webp'
import Minu_01_en_video from '../videos/Minu_01_en.webm'
import Minu_01_en_thumb from '../thumbnails/Minu_01_en_thumb.webp'
import Minu_01_ko_video from '../videos/Minu_01_ko.webm'
import Minu_01_ko_thumb from '../thumbnails/Minu_01_ko_thumb.webp'
import Minu_02_en_video from '../videos/Minu_02_en.webm'
import Minu_02_en_thumb from '../thumbnails/Minu_02_en_thumb.webp'
import Minu_02_ko_video from '../videos/Minu_02_ko.webm'
import Minu_02_ko_thumb from '../thumbnails/Minu_02_ko_thumb.webp'
import Minu_03_en_video from '../videos/Minu_03_en.webm'
import Minu_03_en_thumb from '../thumbnails/Minu_03_en_thumb.webp'
import Minu_03_ko_video from '../videos/Minu_03_ko.webm'
import Minu_03_ko_thumb from '../thumbnails/Minu_03_ko_thumb.webp'
import Minu_04_en_video from '../videos/Minu_04_en.webm'
import Minu_04_en_thumb from '../thumbnails/Minu_04_en_thumb.webp'
import Minu_04_ko_video from '../videos/Minu_04_ko.webm'
import Minu_04_ko_thumb from '../thumbnails/Minu_04_ko_thumb.webp'
import Minu_05_en_video from '../videos/Minu_05_en.webm'
import Minu_05_en_thumb from '../thumbnails/Minu_05_en_thumb.webp'
import Minu_05_ko_video from '../videos/Minu_05_ko.webm'
import Minu_05_ko_thumb from '../thumbnails/Minu_05_ko_thumb.webp'
import Minu_06_en_video from '../videos/Minu_06_en.webm'
import Minu_06_en_thumb from '../thumbnails/Minu_06_en_thumb.webp'
import Minu_06_ko_video from '../videos/Minu_06_ko.webm'
import Minu_06_ko_thumb from '../thumbnails/Minu_06_ko_thumb.webp'
import Minu_07_en_video from '../videos/Minu_07_en.webm'
import Minu_07_en_thumb from '../thumbnails/Minu_07_en_thumb.webp'
import Minu_07_ko_video from '../videos/Minu_07_ko.webm'
import Minu_07_ko_thumb from '../thumbnails/Minu_07_ko_thumb.webp'
import Minu_08_en_video from '../videos/Minu_08_en.webm'
import Minu_08_en_thumb from '../thumbnails/Minu_08_en_thumb.webp'
import Minu_08_ko_video from '../videos/Minu_08_ko.webm'
import Minu_08_ko_thumb from '../thumbnails/Minu_08_ko_thumb.webp'
import Sora_in_Beige_Coat_en_video from '../videos/Sora_in_Beige_Coat_en.webm'
import Sora_in_Beige_Coat_en_thumb from '../thumbnails/Sora_in_Beige_Coat_en_thumb.webp'
import Sora_in_Beige_Coat_ko_video from '../videos/Sora_in_Beige_Coat_ko.webm'
import Sora_in_Beige_Coat_ko_thumb from '../thumbnails/Sora_in_Beige_Coat_ko_thumb.webp'
import Sora_in_Beige_Dress_en_video from '../videos/Sora_in_Beige_Dress_en.webm'
import Sora_in_Beige_Dress_en_thumb from '../thumbnails/Sora_in_Beige_Dress_en_thumb.webp'
import Sora_in_Beige_Dress_ko_video from '../videos/Sora_in_Beige_Dress_ko.webm'
import Sora_in_Beige_Dress_ko_thumb from '../thumbnails/Sora_in_Beige_Dress_ko_thumb.webp'
import Sora_in_Blue_Shirt_en_video from '../videos/Sora_in_Blue_Shirt_en.webm'
import Sora_in_Blue_Shirt_en_thumb from '../thumbnails/Sora_in_Blue_Shirt_en_thumb.webp'
import Sora_in_Blue_Shirt_ko_video from '../videos/Sora_in_Blue_Shirt_ko.webm'
import Sora_in_Blue_Shirt_ko_thumb from '../thumbnails/Sora_in_Blue_Shirt_ko_thumb.webp'
import Sora_in_Blue_Suit_en_video from '../videos/Sora_in_Blue_Suit_en.webm'
import Sora_in_Blue_Suit_en_thumb from '../thumbnails/Sora_in_Blue_Suit_en_thumb.webp'
import Sora_in_Blue_Suit_ko_video from '../videos/Sora_in_Blue_Suit_ko.webm'
import Sora_in_Blue_Suit_ko_thumb from '../thumbnails/Sora_in_Blue_Suit_ko_thumb.webp'
import Sora_in_Gray_Blazer_en_video from '../videos/Sora_in_Gray_Blazer_en.webm'
import Sora_in_Gray_Blazer_en_thumb from '../thumbnails/Sora_in_Gray_Blazer_en_thumb.webp'
import Sora_in_Gray_Blazer_ko_video from '../videos/Sora_in_Gray_Blazer_ko.webm'
import Sora_in_Gray_Blazer_ko_thumb from '../thumbnails/Sora_in_Gray_Blazer_ko_thumb.webp'
import Sora_in_Pink_Dress_en_video from '../videos/Sora_in_Pink_Dress_en.webm'
import Sora_in_Pink_Dress_en_thumb from '../thumbnails/Sora_in_Pink_Dress_en_thumb.webp'
import Sora_in_Pink_Dress_ko_video from '../videos/Sora_in_Pink_Dress_ko.webm'
import Sora_in_Pink_Dress_ko_thumb from '../thumbnails/Sora_in_Pink_Dress_ko_thumb.webp'
import Sora_in_Pink_Dress_with_Hair_Up_en_video from '../videos/Sora_in_Pink_Dress_with_Hair_Up_en.webm'
import Sora_in_Pink_Dress_with_Hair_Up_en_thumb from '../thumbnails/Sora_in_Pink_Dress_with_Hair_Up_en_thumb.webp'
import Sora_in_Pink_Dress_with_Hair_Up_ko_video from '../videos/Sora_in_Pink_Dress_with_Hair_Up_ko.webm'
import Sora_in_Pink_Dress_with_Hair_Up_ko_thumb from '../thumbnails/Sora_in_Pink_Dress_with_Hair_Up_ko_thumb.webp'
import Sora_in_White_Shirt_en_video from '../videos/Sora_in_White_Shirt_en.webm'
import Sora_in_White_Shirt_en_thumb from '../thumbnails/Sora_in_White_Shirt_en_thumb.webp'
import Sora_in_White_Shirt_ko_video from '../videos/Sora_in_White_Shirt_ko.webm'
import Sora_in_White_Shirt_ko_thumb from '../thumbnails/Sora_in_White_Shirt_ko_thumb.webp'
import Soyeon_in_Blue_Shirt_en_video from '../videos/Soyeon_in_Blue_Shirt_en.webm'
import Soyeon_in_Blue_Shirt_en_thumb from '../thumbnails/Soyeon_in_Blue_Shirt_en_thumb.webp'
import Soyeon_in_Blue_Shirt_ko_video from '../videos/Soyeon_in_Blue_Shirt_ko.webm'
import Soyeon_in_Blue_Shirt_ko_thumb from '../thumbnails/Soyeon_in_Blue_Shirt_ko_thumb.webp'
import Soyeon_in_Pink_Dress_en_video from '../videos/Soyeon_in_Pink_Dress_en.webm'
import Soyeon_in_Pink_Dress_en_thumb from '../thumbnails/Soyeon_in_Pink_Dress_en_thumb.webp'
import Soyeon_in_Pink_Dress_ko_video from '../videos/Soyeon_in_Pink_Dress_ko.webm'
import Soyeon_in_Pink_Dress_ko_thumb from '../thumbnails/Soyeon_in_Pink_Dress_ko_thumb.webp'
import Soyeon_in_Red_Blazer_en_video from '../videos/Soyeon_in_Red_Blazer_en.webm'
import Soyeon_in_Red_Blazer_en_thumb from '../thumbnails/Soyeon_in_Red_Blazer_en_thumb.webp'
import Soyeon_in_Red_Blazer_ko_video from '../videos/Soyeon_in_Red_Blazer_ko.webm'
import Soyeon_in_Red_Blazer_ko_thumb from '../thumbnails/Soyeon_in_Red_Blazer_ko_thumb.webp'
import Yuna_in_Gray_Suit_en_video from '../videos/Yuna_in_Gray_Suit_en.webm'
import Yuna_in_Gray_Suit_en_thumb from '../thumbnails/Yuna_in_Gray_Suit_en_thumb.webp'
import Yuna_in_Gray_Suit_ko_video from '../videos/Yuna_in_Gray_Suit_ko.webm'
import Yuna_in_Gray_Suit_ko_thumb from '../thumbnails/Yuna_in_Gray_Suit_ko_thumb.webp'
import Yuna_in_Navy_Dress_en_video from '../videos/Yuna_in_Navy_Dress_en.webm'
import Yuna_in_Navy_Dress_en_thumb from '../thumbnails/Yuna_in_Navy_Dress_en_thumb.webp'
import Yuna_in_Navy_Dress_ko_video from '../videos/Yuna_in_Navy_Dress_ko.webm'
import Yuna_in_Navy_Dress_ko_thumb from '../thumbnails/Yuna_in_Navy_Dress_ko_thumb.webp'
import Yuna_in_Red_Blazer_en_video from '../videos/Yuna_in_Red_Blazer_en.webm'
import Yuna_in_Red_Blazer_en_thumb from '../thumbnails/Yuna_in_Red_Blazer_en_thumb.webp'
import Yuna_in_Red_Blazer_ko_video from '../videos/Yuna_in_Red_Blazer_ko.webm'
import Yuna_in_Red_Blazer_ko_thumb from '../thumbnails/Yuna_in_Red_Blazer_ko_thumb.webp'
import Yura_in_Blue_Dress_en_video from '../videos/Yura_in_Blue_Dress_en.webm'
import Yura_in_Blue_Dress_en_thumb from '../thumbnails/Yura_in_Blue_Dress_en_thumb.webp'
import Yura_in_Blue_Dress_ko_video from '../videos/Yura_in_Blue_Dress_ko.webm'
import Yura_in_Blue_Dress_ko_thumb from '../thumbnails/Yura_in_Blue_Dress_ko_thumb.webp'
import Yura_in_Orange_Blazer_en_video from '../videos/Yura_in_Orange_Blazer_en.webm'
import Yura_in_Orange_Blazer_en_thumb from '../thumbnails/Yura_in_Orange_Blazer_en_thumb.webp'
import Yura_in_Orange_Blazer_ko_video from '../videos/Yura_in_Orange_Blazer_ko.webm'
import Yura_in_Orange_Blazer_ko_thumb from '../thumbnails/Yura_in_Orange_Blazer_ko_thumb.webp'
import Yura_in_Pink_Dress_en_video from '../videos/Yura_in_Pink_Dress_en.webm'
import Yura_in_Pink_Dress_en_thumb from '../thumbnails/Yura_in_Pink_Dress_en_thumb.webp'
import Yura_in_Pink_Dress_ko_video from '../videos/Yura_in_Pink_Dress_ko.webm'
import Yura_in_Pink_Dress_ko_thumb from '../thumbnails/Yura_in_Pink_Dress_ko_thumb.webp'
import Zoe_in_Black_Blazer_en_video from '../videos/Zoe_in_Black_Blazer_en.webm'
import Zoe_in_Black_Blazer_en_thumb from '../thumbnails/Zoe_in_Black_Blazer_en_thumb.webp'
import Zoe_in_Black_Blazer_ko_video from '../videos/Zoe_in_Black_Blazer_ko.webm'
import Zoe_in_Black_Blazer_ko_thumb from '../thumbnails/Zoe_in_Black_Blazer_ko_thumb.webp'
import Zoe_in_Black_Dress_en_video from '../videos/Zoe_in_Black_Dress_en.webm'
import Zoe_in_Black_Dress_en_thumb from '../thumbnails/Zoe_in_Black_Dress_en_thumb.webp'
import Zoe_in_Black_Dress_ko_video from '../videos/Zoe_in_Black_Dress_ko.webm'
import Zoe_in_Black_Dress_ko_thumb from '../thumbnails/Zoe_in_Black_Dress_ko_thumb.webp'
import Zoe_in_Black_Suit_en_video from '../videos/Zoe_in_Black_Suit_en.webm'
import Zoe_in_Black_Suit_en_thumb from '../thumbnails/Zoe_in_Black_Suit_en_thumb.webp'
import Zoe_in_Black_Suit_ko_video from '../videos/Zoe_in_Black_Suit_ko.webm'
import Zoe_in_Black_Suit_ko_thumb from '../thumbnails/Zoe_in_Black_Suit_ko_thumb.webp'
import Zoe_in_White_Shirt_en_video from '../videos/Zoe_in_White_Shirt_en.webm'
import Zoe_in_White_Shirt_en_thumb from '../thumbnails/Zoe_in_White_Shirt_en_thumb.webp'
import Zoe_in_White_Shirt_ko_video from '../videos/Zoe_in_White_Shirt_ko.webm'
import Zoe_in_White_Shirt_ko_thumb from '../thumbnails/Zoe_in_White_Shirt_ko_thumb.webp'

import { Avatar } from './AvatarItem'

const avatarListData: Avatar[] = [
  {
    label: 'Jina in Purple Hanbok',
    plans: ['FREE', 'BASIC', 'PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Jina_in_Purple_Hanbok_ko_video, en: Jina_in_Purple_Hanbok_en_video },
    thumbnailUrl: {
      ko: Jina_in_Purple_Hanbok_ko_thumb,
      en: Jina_in_Purple_Hanbok_en_thumb,
    },
  },
  {
    label: 'Jina in Beige Shirt',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Jina_in_Beige_Shirt_ko_video, en: Jina_in_Beige_Shirt_en_video },
    thumbnailUrl: {
      ko: Jina_in_Beige_Shirt_ko_thumb,
      en: Jina_in_Beige_Shirt_en_thumb,
    },
  },
  {
    label: 'Jina in Blue Dress',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Jina_in_Blue_Dress_ko_video, en: Jina_in_Blue_Dress_en_video },
    thumbnailUrl: {
      ko: Jina_in_Blue_Dress_ko_thumb,
      en: Jina_in_Blue_Dress_en_thumb,
    },
  },
  {
    label: 'Sora in Beige Coat',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Sora_in_Beige_Coat_ko_video, en: Sora_in_Beige_Coat_en_video },
    thumbnailUrl: {
      ko: Sora_in_Beige_Coat_ko_thumb,
      en: Sora_in_Beige_Coat_en_thumb,
    },
  },
  {
    label: 'Sora in Blue Suit',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Sora_in_Blue_Suit_ko_video, en: Sora_in_Blue_Suit_en_video },
    thumbnailUrl: {
      ko: Sora_in_Blue_Suit_ko_thumb,
      en: Sora_in_Blue_Suit_en_thumb,
    },
  },
  {
    label: 'Sora in Pink Dress',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Sora_in_Pink_Dress_ko_video, en: Sora_in_Pink_Dress_en_video },
    thumbnailUrl: {
      ko: Sora_in_Pink_Dress_ko_thumb,
      en: Sora_in_Pink_Dress_en_thumb,
    },
  },
  {
    label: 'Sora in Pink Dress with Hair Up',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Sora_in_Pink_Dress_with_Hair_Up_ko_video, en: Sora_in_Pink_Dress_with_Hair_Up_en_video },
    thumbnailUrl: {
      ko: Sora_in_Pink_Dress_with_Hair_Up_ko_thumb,
      en: Sora_in_Pink_Dress_with_Hair_Up_en_thumb,
    },
  },
  {
    label: 'Sora in Gray Blazer',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Sora_in_Gray_Blazer_ko_video, en: Sora_in_Gray_Blazer_en_video },
    thumbnailUrl: {
      ko: Sora_in_Gray_Blazer_ko_thumb,
      en: Sora_in_Gray_Blazer_en_thumb,
    },
  },
  {
    label: 'Sora in Beige Dress',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Sora_in_Beige_Dress_ko_video, en: Sora_in_Beige_Dress_en_video },
    thumbnailUrl: {
      ko: Sora_in_Beige_Dress_ko_thumb,
      en: Sora_in_Beige_Dress_en_thumb,
    },
  },
  {
    label: 'Sora in White Shirt',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Sora_in_White_Shirt_ko_video, en: Sora_in_White_Shirt_en_video },
    thumbnailUrl: {
      ko: Sora_in_White_Shirt_ko_thumb,
      en: Sora_in_White_Shirt_en_thumb,
    },
  },
  {
    label: 'Sora in Blue Shirt',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Sora_in_Blue_Shirt_ko_video, en: Sora_in_Blue_Shirt_en_video },
    thumbnailUrl: {
      ko: Sora_in_Blue_Shirt_ko_thumb,
      en: Sora_in_Blue_Shirt_en_thumb,
    },
  },

  {
    label: 'Mina in Red Blazer',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Mina_in_Red_Blazer_ko_video, en: Mina_in_Red_Blazer_en_video },
    thumbnailUrl: {
      ko: Mina_in_Red_Blazer_ko_thumb,
      en: Mina_in_Red_Blazer_en_thumb,
    },
  },
  {
    label: 'Mina in Pink Dress',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Mina_in_Pink_Dress_ko_video, en: Mina_in_Pink_Dress_en_video },
    thumbnailUrl: {
      ko: Mina_in_Pink_Dress_ko_thumb,
      en: Mina_in_Pink_Dress_en_thumb,
    },
  },
  {
    label: 'Mina in Blue Shirt',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Mina_in_Blue_Shirt_ko_video, en: Mina_in_Blue_Shirt_en_video },
    thumbnailUrl: {
      ko: Mina_in_Blue_Shirt_ko_thumb,
      en: Mina_in_Blue_Shirt_en_thumb,
    },
  },
  {
    label: 'Yura in Pink Dress',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Yura_in_Pink_Dress_ko_video, en: Yura_in_Pink_Dress_en_video },
    thumbnailUrl: {
      ko: Yura_in_Pink_Dress_ko_thumb,
      en: Yura_in_Pink_Dress_en_thumb,
    },
  },
  {
    label: 'Yura in Orange Blazer',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Yura_in_Orange_Blazer_ko_video, en: Yura_in_Orange_Blazer_en_video },
    thumbnailUrl: {
      ko: Yura_in_Orange_Blazer_ko_thumb,
      en: Yura_in_Orange_Blazer_en_thumb,
    },
  },
  {
    label: 'Yura in Blue Dress',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Yura_in_Blue_Dress_ko_video, en: Yura_in_Blue_Dress_en_video },
    thumbnailUrl: {
      ko: Yura_in_Blue_Dress_ko_thumb,
      en: Yura_in_Blue_Dress_en_thumb,
    },
  },
  {
    label: 'Jisoo in Red Hanbok',
    plans: ['FREE', 'BASIC', 'PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Jisoo_in_Red_Hanbok_ko_video, en: Jisoo_in_Red_Hanbok_en_video },
    thumbnailUrl: {
      ko: Jisoo_in_Red_Hanbok_ko_thumb,
      en: Jisoo_in_Red_Hanbok_en_thumb,
    },
  },
  {
    label: 'Jisoo in Blue Hanbok',
    plans: ['FREE', 'BASIC', 'PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Jisoo_in_Blue_Hanbok_ko_video, en: Jisoo_in_Blue_Hanbok_en_video },
    thumbnailUrl: {
      ko: Jisoo_in_Blue_Hanbok_ko_thumb,
      en: Jisoo_in_Blue_Hanbok_en_thumb,
    },
  },
  {
    label: 'Jisoo in Navy Dress',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Jisoo_in_Navy_Dress_ko_video, en: Jisoo_in_Navy_Dress_en_video },
    thumbnailUrl: {
      ko: Jisoo_in_Navy_Dress_ko_thumb,
      en: Jisoo_in_Navy_Dress_en_thumb,
    },
  },
  {
    label: 'Jisoo in Red Blazer',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Jisoo_in_Red_Blazer_ko_video, en: Jisoo_in_Red_Blazer_en_video },
    thumbnailUrl: {
      ko: Jisoo_in_Red_Blazer_ko_thumb,
      en: Jisoo_in_Red_Blazer_en_thumb,
    },
  },
  {
    label: 'Jisoo in Gray Suit',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Jisoo_in_Gray_Suit_ko_video, en: Jisoo_in_Gray_Suit_en_video },
    thumbnailUrl: {
      ko: Jisoo_in_Gray_Suit_ko_thumb,
      en: Jisoo_in_Gray_Suit_en_thumb,
    },
  },
  {
    label: 'Jisoo_06',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Jisoo_06_preview_ko_video, en: Jisoo_06_preview_en_video },
    thumbnailUrl: {
      ko: Jisoo_06_preview_ko_thumb,
      en: Jisoo_06_preview_en_thumb,
    },
  },
  {
    label: 'Minji in Blue Suit',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Minji_in_Blue_Suit_ko_video, en: Minji_in_Blue_Suit_en_video },
    thumbnailUrl: {
      ko: Minji_in_Blue_Suit_ko_thumb,
      en: Minji_in_Blue_Suit_en_thumb,
    },
  },
  {
    label: 'Minji in White Shirt',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Minji_in_White_Shirt_ko_video, en: Minji_in_White_Shirt_en_video },
    thumbnailUrl: {
      ko: Minji_in_White_Shirt_ko_thumb,
      en: Minji_in_White_Shirt_en_thumb,
    },
  },
  {
    label: 'Layla in Beige Shirt',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Layla_in_Beige_Shirt_ko_video, en: Layla_in_Beige_Shirt_en_video },
    thumbnailUrl: {
      ko: Layla_in_Beige_Shirt_ko_thumb,
      en: Layla_in_Beige_Shirt_en_thumb,
    },
  },
  {
    label: 'Layla in Orange Shirt',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Layla_in_Orange_Shirt_ko_video, en: Layla_in_Orange_Shirt_en_video },
    thumbnailUrl: {
      ko: Layla_in_Orange_Shirt_ko_thumb,
      en: Layla_in_Orange_Shirt_en_thumb,
    },
  },
  {
    label: 'Layla in Gray Dress',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Layla_in_Gray_Dress_ko_video, en: Layla_in_Gray_Dress_en_video },
    thumbnailUrl: {
      ko: Layla_in_Gray_Dress_ko_thumb,
      en: Layla_in_Gray_Dress_en_thumb,
    },
  },
  {
    label: 'Layla in Orange Blazer',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Layla_in_Orange_Blazer_ko_video, en: Layla_in_Orange_Blazer_en_video },
    thumbnailUrl: {
      ko: Layla_in_Orange_Blazer_ko_thumb,
      en: Layla_in_Orange_Blazer_en_thumb,
    },
  },
  {
    label: 'Layla in Orange Blazer with Hijab',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Layla_in_Orange_Blazer_with_Hijab_ko_video, en: Layla_in_Orange_Blazer_with_Hijab_en_video },
    thumbnailUrl: {
      ko: Layla_in_Orange_Blazer_with_Hijab_ko_thumb,
      en: Layla_in_Orange_Blazer_with_Hijab_en_thumb,
    },
  },
  {
    label: 'Layla in Beige Suit with Hijab',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Layla_in_Beige_Suit_with_Hijab_ko_video, en: Layla_in_Beige_Suit_with_Hijab_en_video },
    thumbnailUrl: {
      ko: Layla_in_Beige_Suit_with_Hijab_ko_thumb,
      en: Layla_in_Beige_Suit_with_Hijab_en_thumb,
    },
  },
  {
    label: 'Layla in Beige Shirt with Hijab',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Layla_in_Beige_Shirt_with_Hijab_ko_video, en: Layla_in_Beige_Shirt_with_Hijab_en_video },
    thumbnailUrl: {
      ko: Layla_in_Beige_Shirt_with_Hijab_ko_thumb,
      en: Layla_in_Beige_Shirt_with_Hijab_en_thumb,
    },
  },
  {
    label: 'Layla in Black Dress with Hijab',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Layla_in_Black_Dress_with_Hijab_ko_video, en: Layla_in_Black_Dress_with_Hijab_en_video },
    thumbnailUrl: {
      ko: Layla_in_Black_Dress_with_Hijab_ko_thumb,
      en: Layla_in_Black_Dress_with_Hijab_en_thumb,
    },
  },
  {
    label: 'Jena in Beige Shirt',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Jena_in_Beige_Shirt_ko_video, en: Jena_in_Beige_Shirt_en_video },
    thumbnailUrl: {
      ko: Jena_in_Beige_Shirt_ko_thumb,
      en: Jena_in_Beige_Shirt_en_thumb,
    },
  },
  {
    label: 'Jena in Blue Dress',
    plans: ['BASIC', 'PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Jena_in_Blue_Dress_ko_video, en: Jena_in_Blue_Dress_en_video },
    thumbnailUrl: {
      ko: Jena_in_Blue_Dress_ko_thumb,
      en: Jena_in_Blue_Dress_en_thumb,
    },
  },
  {
    label: 'Soyeon in Red Blazer',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Soyeon_in_Red_Blazer_ko_video, en: Soyeon_in_Red_Blazer_en_video },
    thumbnailUrl: {
      ko: Soyeon_in_Red_Blazer_ko_thumb,
      en: Soyeon_in_Red_Blazer_en_thumb,
    },
  },
  {
    label: 'Soyeon in Pink Dress',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Soyeon_in_Pink_Dress_ko_video, en: Soyeon_in_Pink_Dress_en_video },
    thumbnailUrl: {
      ko: Soyeon_in_Pink_Dress_ko_thumb,
      en: Soyeon_in_Pink_Dress_en_thumb,
    },
  },
  {
    label: 'Soyeon in Blue Shirt',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Soyeon_in_Blue_Shirt_ko_video, en: Soyeon_in_Blue_Shirt_en_video },
    thumbnailUrl: {
      ko: Soyeon_in_Blue_Shirt_ko_thumb,
      en: Soyeon_in_Blue_Shirt_en_thumb,
    },
  },
  {
    label: 'Hana in Pink Dress',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Hana_in_Pink_Dress_ko_video, en: Hana_in_Pink_Dress_en_video },
    thumbnailUrl: {
      ko: Hana_in_Pink_Dress_ko_thumb,
      en: Hana_in_Pink_Dress_en_thumb,
    },
  },
  {
    label: 'Hana in Orange Blazer',
    plans: ['BASIC', 'PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Hana_in_Orange_Blazer_ko_video, en: Hana_in_Orange_Blazer_en_video },
    thumbnailUrl: {
      ko: Hana_in_Orange_Blazer_ko_thumb,
      en: Hana_in_Orange_Blazer_en_thumb,
    },
  },
  {
    label: 'Hana in Blue Dress',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Hana_in_Blue_Dress_ko_video, en: Hana_in_Blue_Dress_en_video },
    thumbnailUrl: {
      ko: Hana_in_Blue_Dress_ko_thumb,
      en: Hana_in_Blue_Dress_en_thumb,
    },
  },
  {
    label: 'Zoe in Black Dress',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Zoe_in_Black_Dress_ko_video, en: Zoe_in_Black_Dress_en_video },
    thumbnailUrl: {
      ko: Zoe_in_Black_Dress_ko_thumb,
      en: Zoe_in_Black_Dress_en_thumb,
    },
  },
  {
    label: 'Zoe in Black Blazer',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Zoe_in_Black_Blazer_ko_video, en: Zoe_in_Black_Blazer_en_video },
    thumbnailUrl: {
      ko: Zoe_in_Black_Blazer_ko_thumb,
      en: Zoe_in_Black_Blazer_en_thumb,
    },
  },
  {
    label: 'Zoe in White Shirt',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Zoe_in_White_Shirt_ko_video, en: Zoe_in_White_Shirt_en_video },
    thumbnailUrl: {
      ko: Zoe_in_White_Shirt_ko_thumb,
      en: Zoe_in_White_Shirt_en_thumb,
    },
  },
  {
    label: 'Zoe in Black Suit',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Zoe_in_Black_Suit_ko_video, en: Zoe_in_Black_Suit_en_video },
    thumbnailUrl: {
      ko: Zoe_in_Black_Suit_ko_thumb,
      en: Zoe_in_Black_Suit_en_thumb,
    },
  },
  {
    label: 'Lina in Blue Suit',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Lina_in_Blue_Suit_ko_video, en: Lina_in_Blue_Suit_en_video },
    thumbnailUrl: {
      ko: Lina_in_Blue_Suit_ko_thumb,
      en: Lina_in_Blue_Suit_en_thumb,
    },
  },
  {
    label: 'Lina in White Shirt',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Lina_in_White_Shirt_ko_video, en: Lina_in_White_Shirt_en_video },
    thumbnailUrl: {
      ko: Lina_in_White_Shirt_ko_thumb,
      en: Lina_in_White_Shirt_en_thumb,
    },
  },
  {
    label: 'Aria in Blue Suit',
    plans: ['BASIC', 'PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Aria_in_Blue_Suit_ko_video, en: Aria_in_Blue_Suit_en_video },
    thumbnailUrl: {
      ko: Aria_in_Blue_Suit_ko_thumb,
      en: Aria_in_Blue_Suit_en_thumb,
    },
  },
  {
    label: 'Aria in Blue Shirt',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Aria_in_Blue_Shirt_ko_video, en: Aria_in_Blue_Shirt_en_video },
    thumbnailUrl: {
      ko: Aria_in_Blue_Shirt_ko_thumb,
      en: Aria_in_Blue_Shirt_en_thumb,
    },
  },
  {
    label: 'Yuna in Navy Dress',
    plans: ['BASIC', 'PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Yuna_in_Navy_Dress_ko_video, en: Yuna_in_Navy_Dress_en_video },
    thumbnailUrl: {
      ko: Yuna_in_Navy_Dress_ko_thumb,
      en: Yuna_in_Navy_Dress_en_thumb,
    },
  },
  {
    label: 'Aria in Pink Dress',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Aria_in_Pink_Dress_ko_video, en: Aria_in_Pink_Dress_en_video },
    thumbnailUrl: {
      ko: Aria_in_Pink_Dress_ko_thumb,
      en: Aria_in_Pink_Dress_en_thumb,
    },
  },
  {
    label: 'Yuna in Red Blazer',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Yuna_in_Red_Blazer_ko_video, en: Yuna_in_Red_Blazer_en_video },
    thumbnailUrl: {
      ko: Yuna_in_Red_Blazer_ko_thumb,
      en: Yuna_in_Red_Blazer_en_thumb,
    },
  },
  {
    label: 'Aria in Green Blazer',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Aria_in_Green_Blazer_ko_video, en: Aria_in_Green_Blazer_en_video },
    thumbnailUrl: {
      ko: Aria_in_Green_Blazer_ko_thumb,
      en: Aria_in_Green_Blazer_en_thumb,
    },
  },
  {
    label: 'Junho_01',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: {
      ko: Junho_01_ko_video,
      en: Junho_01_en_video,
    },
    thumbnailUrl: {
      ko: Junho_01_ko_thumb,
      en: Junho_01_en_thumb,
    },
  },
  {
    label: 'Junho_02',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: {
      ko: Junho_02_ko_video,
      en: Junho_02_en_video,
    },
    thumbnailUrl: {
      ko: Junho_02_ko_thumb,
      en: Junho_02_en_thumb,
    },
  },
  {
    label: 'Junho_03',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: {
      ko: Junho_03_ko_video,
      en: Junho_03_en_video,
    },
    thumbnailUrl: {
      ko: Junho_03_ko_thumb,
      en: Junho_03_en_thumb,
    },
  },
  {
    label: 'Junho_04',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: {
      ko: Junho_04_ko_video,
      en: Junho_04_en_video,
    },
    thumbnailUrl: {
      ko: Junho_04_ko_thumb,
      en: Junho_04_en_thumb,
    },
  },
  {
    label: 'Junho_05',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: {
      ko: Junho_05_ko_video,
      en: Junho_05_en_video,
    },
    thumbnailUrl: {
      ko: Junho_05_ko_thumb,
      en: Junho_05_en_thumb,
    },
  },
  {
    label: 'Junho_06',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: {
      ko: Junho_06_ko_video,
      en: Junho_06_en_video,
    },
    thumbnailUrl: {
      ko: Junho_06_ko_thumb,
      en: Junho_06_en_thumb,
    },
  },
  {
    label: 'Junho_07',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: {
      ko: Junho_07_ko_video,
      en: Junho_07_en_video,
    },
    thumbnailUrl: {
      ko: Junho_07_ko_thumb,
      en: Junho_07_en_thumb,
    },
  },
  {
    label: 'Minu_01',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: {
      ko: Minu_01_ko_video,
      en: Minu_01_en_video,
    },
    thumbnailUrl: {
      ko: Minu_01_ko_thumb,
      en: Minu_01_en_thumb,
    },
  },
  {
    label: 'Minu_02',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: {
      ko: Minu_02_ko_video,
      en: Minu_02_en_video,
    },
    thumbnailUrl: {
      ko: Minu_02_ko_thumb,
      en: Minu_02_en_thumb,
    },
  },
  {
    label: 'Minu_03',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: {
      ko: Minu_03_ko_video,
      en: Minu_03_en_video,
    },
    thumbnailUrl: {
      ko: Minu_03_ko_thumb,
      en: Minu_03_en_thumb,
    },
  },
  {
    label: 'Minu_04',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: {
      ko: Minu_04_ko_video,
      en: Minu_04_en_video,
    },
    thumbnailUrl: {
      ko: Minu_04_ko_thumb,
      en: Minu_04_en_thumb,
    },
  },
  {
    label: 'Minu_05',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: {
      ko: Minu_05_ko_video,
      en: Minu_05_en_video,
    },
    thumbnailUrl: {
      ko: Minu_05_ko_thumb,
      en: Minu_05_en_thumb,
    },
  },
  {
    label: 'Minu_06',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: {
      ko: Minu_06_ko_video,
      en: Minu_06_en_video,
    },
    thumbnailUrl: {
      ko: Minu_06_ko_thumb,
      en: Minu_06_en_thumb,
    },
  },
  {
    label: 'Minu_07',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: {
      ko: Minu_07_ko_video,
      en: Minu_07_en_video,
    },
    thumbnailUrl: {
      ko: Minu_07_ko_thumb,
      en: Minu_07_en_thumb,
    },
  },
  {
    label: 'Minu_08',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: {
      ko: Minu_08_ko_video,
      en: Minu_08_en_video,
    },
    thumbnailUrl: {
      ko: Minu_08_ko_thumb,
      en: Minu_08_en_thumb,
    },
  },
  {
    label: 'Yuna in Gray Suit',
    plans: ['BASIC', 'PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Yuna_in_Gray_Suit_ko_video, en: Yuna_in_Gray_Suit_en_video },
    thumbnailUrl: {
      ko: Yuna_in_Gray_Suit_ko_thumb,
      en: Yuna_in_Gray_Suit_en_thumb,
    },
  },
  {
    label: 'Jihun in Black Suit',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Jihun_in_Black_Suit_ko_video, en: Jihun_in_Black_Suit_en_video },
    thumbnailUrl: {
      ko: Jihun_in_Black_Suit_ko_thumb,
      en: Jihun_in_Black_Suit_en_thumb,
    },
  },
  {
    label: 'Jihun in Gray Suit',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Jihun_in_Gray_Suit_ko_video, en: Jihun_in_Gray_Suit_en_video },
    thumbnailUrl: {
      ko: Jihun_in_Gray_Suit_ko_thumb,
      en: Jihun_in_Gray_Suit_en_thumb,
    },
  },
  {
    label: 'Jihun in Gray Shirt',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Jihun_in_Gray_Shirt_ko_video, en: Jihun_in_Gray_Shirt_en_video },
    thumbnailUrl: {
      ko: Jihun_in_Gray_Shirt_ko_thumb,
      en: Jihun_in_Gray_Shirt_en_thumb,
    },
  },
  {
    label: 'Jihun in Pink Shirt',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Jihun_in_Pink_Shirt_ko_video, en: Jihun_in_Pink_Shirt_en_video },
    thumbnailUrl: {
      ko: Jihun_in_Pink_Shirt_ko_thumb,
      en: Jihun_in_Pink_Shirt_en_thumb,
    },
  },
  {
    label: 'Minho in Black Suit',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Minho_in_Black_Suit_ko_cut_video, en: Minho_in_Black_Suit_en_cut_video },
    thumbnailUrl: {
      ko: Minho_in_Black_Suit_ko_cut_thumb,
      en: Minho_in_Black_Suit_en_cut_thumb,
    },
  },
  {
    label: 'Minho in Gray Suit',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Minho_in_Gray_Suit_ko_cut_video, en: Minho_in_Gray_Suit_en_cut_video },
    thumbnailUrl: {
      ko: Minho_in_Gray_Suit_ko_cut_thumb,
      en: Minho_in_Gray_Suit_en_cut_thumb,
    },
  },
  {
    label: 'Mateo in Brown Suit',
    plans: ['BASIC', 'PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Mateo_in_Brown_Suit_ko_video, en: Mateo_in_Brown_Suit_en_video },
    thumbnailUrl: {
      ko: Mateo_in_Brown_Suit_ko_thumb,
      en: Mateo_in_Brown_Suit_en_thumb,
    },
  },
  {
    label: 'Mateo in Black Suit with Pink Tie',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Mateo_in_Black_Suit_with_Pink_Tie_ko_video, en: Mateo_in_Black_Suit_with_Pink_Tie_en_video },
    thumbnailUrl: {
      ko: Mateo_in_Black_Suit_with_Pink_Tie_ko_thumb,
      en: Mateo_in_Black_Suit_with_Pink_Tie_en_thumb,
    },
  },
  {
    label: 'Mateo in Black Suit with Blue Tie',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Mateo_in_Black_Suit_with_Blue_Tie_ko_video, en: Mateo_in_Black_Suit_with_Blue_Tie_en_video },
    thumbnailUrl: {
      ko: Mateo_in_Black_Suit_with_Blue_Tie_ko_thumb,
      en: Mateo_in_Black_Suit_with_Blue_Tie_en_thumb,
    },
  },
  {
    label: 'Mateo in Navy Suit',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Mateo_in_Navy_Suit_ko_video, en: Mateo_in_Navy_Suit_en_video },
    thumbnailUrl: {
      ko: Mateo_in_Navy_Suit_ko_thumb,
      en: Mateo_in_Navy_Suit_en_thumb,
    },
  },
  {
    label: 'Mateo in Gray Blazer',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Mateo_in_Gray_Blazer_ko_video, en: Mateo_in_Gray_Blazer_en_video },
    thumbnailUrl: {
      ko: Mateo_in_Gray_Blazer_ko_thumb,
      en: Mateo_in_Gray_Blazer_en_thumb,
    },
  },
  {
    label: 'Mateo in Black Blazer',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Mateo_in_Black_Blazer_ko_video, en: Mateo_in_Black_Blazer_en_video },
    thumbnailUrl: {
      ko: Mateo_in_Black_Blazer_ko_thumb,
      en: Mateo_in_Black_Blazer_en_thumb,
    },
  },
  {
    label: 'Blake in Navy Suit',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Blake_in_Navy_Suit_ko_video, en: Blake_in_Navy_Suit_en_video },
    thumbnailUrl: {
      ko: Blake_in_Navy_Suit_ko_thumb,
      en: Blake_in_Navy_Suit_en_thumb,
    },
  },
  {
    label: 'Blake in Beige Suit',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Blake_in_Beige_Suit_ko_video, en: Blake_in_Beige_Suit_en_video },
    thumbnailUrl: {
      ko: Blake_in_Beige_Suit_ko_thumb,
      en: Blake_in_Beige_Suit_en_thumb,
    },
  },
  {
    label: 'Blake in Black Blazer',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Blake_in_Black_Blazer_ko_video, en: Blake_in_Black_Blazer_en_video },
    thumbnailUrl: {
      ko: Blake_in_Black_Blazer_ko_thumb,
      en: Blake_in_Black_Blazer_en_thumb,
    },
  },
  {
    label: 'Dylan in Navy Suit',
    plans: ['BASIC', 'PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Dylan_in_Navy_Suit_ko_video, en: Dylan_in_Navy_Suit_en_video },
    thumbnailUrl: {
      ko: Dylan_in_Navy_Suit_ko_thumb,
      en: Dylan_in_Navy_Suit_en_thumb,
    },
  },
  {
    label: 'Dylan in Beige Suit',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Dylan_in_Beige_Suit_ko_video, en: Dylan_in_Beige_Suit_en_video },
    thumbnailUrl: {
      ko: Dylan_in_Beige_Suit_ko_thumb,
      en: Dylan_in_Beige_Suit_en_thumb,
    },
  },
  {
    label: 'Dylan in Black Blazer',
    plans: ['PRO', 'EXCLUSIVE'],
    videoUrl: { ko: Dylan_in_Black_Blazer_ko_video, en: Dylan_in_Black_Blazer_en_video },
    thumbnailUrl: {
      ko: Dylan_in_Black_Blazer_ko_thumb,
      en: Dylan_in_Black_Blazer_en_thumb,
    },
  },
]

export default avatarListData
