// eslint-disable no-else-return
import {Swiper, SwiperSlide} from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import clsx from 'clsx'
import {Autoplay, Navigation, Pagination} from 'swiper/modules'
import moment from 'moment'
import referenceImage from '../../../assets/images/1290 X 120_03.png'
import topbanner01_ko from './topbanner-01-ko.png'
import topbanner01_en from './topbanner-01-en.png'
import topbanner02_ko from './topbanner-02-ko.png'
import topbanner02_en from './topbanner-02-en.png'
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {useCurrentWorkspaceDetailQuery} from "../../../hooks/queries";
import SelfCustomAvatarModal from "../../ProjectV2/OptionManager/selfCustomAvatar/SelfCustomAvatarModal";

type Banner = {
    name: string
    linkUrl: string | null
    imageUrl: string
    startDate: string | null
    endDate: string | null
    openModal?: boolean
}

export default function TopBanner() {
    const {i18n} = useTranslation()
    const isKorean = i18n.language.startsWith('ko')

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleBannerClick = (banner: Banner) => {
        if (banner.openModal) {
            setIsModalOpen(true);
        } else if (banner.linkUrl) {
            window.open(banner.linkUrl, '_blank', 'noopener noreferrer');
        }
    };

    const closeModal = () => setIsModalOpen(false);

    const {data: workspace} = useCurrentWorkspaceDetailQuery()
    const isProPlan = workspace?.subscription?.plan?.name?.startsWith('PRO');

    const banners: Banner[] = [
        {
            name: '셀프커스텀아바타',
            linkUrl: 'https://www.aivatar.ai/product/self-custom-avatar',
            imageUrl: isKorean ? topbanner01_ko : topbanner01_en,
            startDate: '2025-03-31 09:00:00',
            endDate: '2025-04-30 23:59:59',
            openModal: true,
        },
        {
            name: '남성아바타',
            linkUrl: null,
            imageUrl: isKorean ? topbanner02_ko : topbanner02_en,
            startDate: '2025-03-31 09:00:00',
            endDate: '2025-04-30 23:59:59',
        },
        {
            name: '레퍼런스',
            linkUrl: 'https://www.aivatar.ai/reference',
            imageUrl: referenceImage,
            startDate: null,
            endDate: null,
        },
    ]

    const slideStyle = clsx('')

    const visibleBanners = banners.filter((banner) => {
        if (banner.startDate && banner.endDate) {
            return moment().isBetween(moment(banner.startDate), moment(banner.endDate))
        }
        if (banner.startDate && !banner.endDate) {
            return moment().isSameOrAfter(moment(banner.startDate))
        }
        if (!banner.startDate && banner.endDate) {
            return moment().isSameOrBefore(moment(banner.endDate))
        }
        return true
    })

    return (
        <>
            <Swiper
                modules={[Navigation, Pagination, Autoplay]}
                navigation
                pagination
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                loop
                style={{
                    ['--swiper-theme-color' as any]: '#fff',
                    ['--swiper-navigation-size' as any]: '32px',
                    borderRadius: 8,
                    height: 120,
                }}
            >
                {visibleBanners.map((banner) => (
                    <SwiperSlide key={banner.imageUrl} className={slideStyle}>
                        <a
                            href={banner.linkUrl ?? '#'}
                            target={banner.linkUrl ? '_blank noreferrer' : undefined}
                            aria-label={banner.name}
                            className={clsx('block h-full w-full', !banner.linkUrl && 'pointer-events-none')}
                            onClick={(e) => {
                                e.preventDefault();
                                handleBannerClick(banner);
                            }}
                        >
                            <img
                                src={banner.imageUrl}
                                alt={banner.name}
                                className="h-full w-full object-cover object-center"
                            />
                        </a>
                    </SwiperSlide>
                ))}
            </Swiper>

            {isModalOpen && <SelfCustomAvatarModal isProPlan={isProPlan} closeModal={closeModal}/>}
        </>
    )
}
