import React from 'react'

type Props = {
  className?: string
}

export default function PlusIcon({ className }: Props) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M18.2166 9.15832H11.3333V2.27498C11.3333 1.81665 10.9583 1.44165 10.5 1.44165C10.0416 1.44165 9.66662 1.81665 9.66662 2.27498V9.15832H2.78328C2.32495 9.15832 1.94995 9.53332 1.94995 9.99165C1.94995 10.45 2.32495 10.825 2.78328 10.825H9.66662V17.7083C9.66662 18.1667 10.0416 18.5417 10.5 18.5417C10.9583 18.5417 11.3333 18.1667 11.3333 17.7083V10.825H18.2166C18.675 10.825 19.05 10.45 19.05 9.99165C19.05 9.53332 18.675 9.15832 18.2166 9.15832Z"
        fill="#3A74F5"
      />
    </svg>
  )
}
