import { useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import AudioControllerSlider from './AudioControllerSlider'
import AudioControllerButtonWrapper from './AudioControllerButtonWrapper'
import { selectedBoxesEstimatedDurationState, selectedBoxIdsState } from '../../../recoil/texteditor/editor/selectors'
import { sentenceBoxIdsState } from '../../../recoil/texteditor/editor/atoms'

function AudioController() {
  const { t } = useTranslation('project')
  /** Recoils */
  const selectedBoxesEstimatedDuration = useRecoilValue(selectedBoxesEstimatedDurationState)
  // const totalBoxesEstimatedDuration = useRecoilValue(totalEstimatedDurationState)
  const selectedBoxIds = useRecoilValue(selectedBoxIdsState)
  const sentenceBoxIds = useRecoilValue(sentenceBoxIdsState)

  const allSelected = selectedBoxIds.length === 0 || sentenceBoxIds.length === selectedBoxIds.length
  /** Life Cycles */
  // useAivatarDidMount(() => {
  // }, []);

  /** Event hanlder */

  /** Display */

  const durationTime = useMemo(() => {
    if (selectedBoxesEstimatedDuration <= 0) return 0

    return dayjs.duration(selectedBoxesEstimatedDuration / 1000, 's').format('mm:ss')
  }, [selectedBoxesEstimatedDuration])

  return (
    <section className="media_controller_height bg-gs01-20 border-t-gs01-200 relative flex h-[92px] w-full items-center justify-center border-t text-white">
      <div className="flex h-full items-center justify-center">
        <AudioControllerButtonWrapper />
        <AudioControllerSlider />
        <div className="text-subtitle02 text-bcblue-500 border-bcblue-500 absolute right-[38px] top-1/2 ml-[16px] grid -translate-y-1/2 place-items-center rounded-[50px] border px-[12px] py-[7px]">
          {`${allSelected ? t('전체 예상 재생 시간') : t('선택 문장 예상 재생 시간')} | ${durationTime}`}
        </div>
      </div>
    </section>
  )
}

export default AudioController
