import {useMemo} from 'react'
import {useRecoilValue} from 'recoil'
import SentenceBoxOptionRoot from './SoundOptionRoot'
import {isAvatarEditorShowState} from '../../../recoil/navigator/selectors'
import VideoOptionRoot from './VideoOptionRoot'
import AivatarBackdrop from '../../../components/AivatarBackdrop'
import {editorValueState} from '../../../recoil/texteditor/editor/selectors'
import {isLoadingAudioState, isValidSentenceBoxState} from '../../../recoil/texteditor/sentenceBox/selectors'
import {isPlayingState} from '../../../recoil/audiocontroller/selectors'
import SelfCustomAvatarButton from "./selfCustomAvatar";

function OptionManager() {
  const isAvatarEditorShow = useRecoilValue(isAvatarEditorShowState)

  const focusedBoxId = useRecoilValue(editorValueState({key: 'focusedBoxId'}))
  const isPlaying = useRecoilValue(isPlayingState)
  const isLoadingAudio = useRecoilValue(isLoadingAudioState({id: focusedBoxId}))
  const isValidSentenceBox = useRecoilValue(isValidSentenceBoxState(focusedBoxId))

  /** Validation */

  const isAvailable = useMemo(
    () => !isPlaying && !isLoadingAudio && isValidSentenceBox,
    [isPlaying, isLoadingAudio, isValidSentenceBox],
  )

  return (
    <div
      className="option_width aivatar_scroll_wrapper bg-gs01-20 border-l-gs01-100 relative flex flex-col items-center overflow-y-auto border-l">
      <div className="relative h-full w-full p-[32px]">
        <AivatarBackdrop isFullScreen={false} open={!isAvailable} backgroundColor="#ffffff60"/>
        {isAvatarEditorShow ? (
          <div className="flex flex-col items-center w-full space-y-4">
            <SelfCustomAvatarButton/>
            <VideoOptionRoot/>
          </div>
        ) : (
          <SentenceBoxOptionRoot/>
        )}
      </div>
    </div>
  )
}

export default OptionManager
