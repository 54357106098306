import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import useURI from '../../../hooks/useURI'

export default function NewFeature() {
  const { t } = useTranslation('banner-new-feature')
  const { uri } = useURI()
  const [closed, setClosed] = useState(false)

  return (
    <div
      className={twMerge(
        'bg-bcblue-500 fixed left-0 top-0 flex h-[50px] w-full items-center justify-center',
        !closed && '[&+div]:pt-[50px]',
      )}
      style={{ display: closed ? 'none' : 'flex' }}
    >
      {/* <div className="bg-bcblue-500 flex h-[100px] items-center justify-center"> */}
      <span className="text-headline04 font-[500] text-white">
        {t('새로워진 에디터 • 음성 옵션 UI, 자세한 사용 방법을 확인해 보세요.')}
      </span>
      <a
        href={uri['guide-type-text']}
        target="_blank"
        rel="noreferrer"
        className="text-bcblue-500 text-subtitle02 ml-[40px] grid h-[30px] place-items-center rounded-[32px] bg-white px-[16px]"
      >
        {t('사용자 가이드 보러가기↗')}
      </a>
      <button
        type="button"
        className="text-subtitle02 ml-[10px] h-[30px] rounded-[32px] border border-white px-[16px] text-white"
        onClick={() => setClosed(true)}
      >
        {t('닫기')}
      </button>
    </div>
  )
}
