/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useQuery } from '@tanstack/react-query'
import React, { useContext, useEffect, useState } from 'react'
import { useDebounce } from 'react-use'
import { twMerge } from 'tailwind-merge'
import { useParams } from 'react-router-dom'
import { CustomsContext } from '..'
import Radio from '../components/Radio'
import { workspaceQueries } from '../../../../../../../../../services/workspace'
import { Tooltip } from '@mui/material'
import sampleNoise from './sample_noise_file.wav'
import sampleNoiseLow from './sample_noise_expected_low.wav'
import sampleNoiseMedium from './sample_noise_expected_medium.wav'
import sampleNoiseHigh from './sample_noise_expected_high.wav'
import { useTranslation } from 'react-i18next'

function ValidationIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00008 1.16666C4.22675 1.16666 1.16675 4.22666 1.16675 7.99999C1.16675 11.7733 4.22675 14.8333 8.00008 14.8333C11.7734 14.8333 14.8334 11.7733 14.8334 7.99999C14.8334 4.22666 11.7734 1.16666 8.00008 1.16666ZM12.2934 6.02666L7.49341 11.2067C7.41341 11.2933 7.30008 11.34 7.18675 11.3467H7.18008C7.06675 11.3467 6.96008 11.3 6.87341 11.22L3.72008 8.06666C3.55341 7.89999 3.55341 7.62666 3.72008 7.45999C3.88675 7.29332 4.16008 7.29332 4.32675 7.45999L7.16008 10.2933L11.6601 5.43999C11.8201 5.26666 12.0934 5.25332 12.2667 5.41999C12.4401 5.57999 12.4534 5.85332 12.2867 6.02666H12.2934Z"
        fill="#C5D0D9"
      />
    </svg>
  )
}

export default function CustomsWriteInfo() {
  const [validation, setValidation] = useState({
    character: false, // 한글 및 로마 알파벳
    length: false, // 15자 이내ㅇ
    unique: false, // 기존 커스텀 목소리 이름과 중복되지 않음
  })

  const { data, changeData, changeCanProceed } = useContext(CustomsContext)
  const { t } = useTranslation('voice-conversion')

  const { workspaceId } = useParams()
  const { data: customVoiceNames } = useQuery({
    ...workspaceQueries.detail(Number(workspaceId))._ctx.voiceConversions,
    select: (data) =>
      //! status: READY, PROCESSING, FAIL, COMPLETED
      data.filter((item: any) => ['COMPLETED'].includes(item.status)).map((item: any) => item.name),
    staleTime: 0,
    gcTime: 0,
  })
  // const voiceNames = voices.map(item => item.voice)

  useDebounce(
    () => {
      if (/^[ㄱ-ㅎ가-힣a-zA-Z0-9]+$/.test(data.name)) {
        setValidation((prev) => ({ ...prev, character: true }))
      } else {
        setValidation((prev) => ({ ...prev, character: false }))
      }

      if (data.name.length > 0 && data.name.length <= 15) {
        setValidation((prev) => ({ ...prev, length: true }))
      } else {
        setValidation((prev) => ({ ...prev, length: false }))
      }

      if (data.name.length && !customVoiceNames.includes(data.name)) {
        setValidation((prev) => ({ ...prev, unique: true }))
      } else {
        setValidation((prev) => ({ ...prev, unique: false }))
      }
    },
    300,
    [data],
  )

  useEffect(() => {
    if (Object.values(validation).every(Boolean)) {
      changeCanProceed(true)
    } else {
      changeCanProceed(false)
    }
  }, [changeCanProceed, validation])

  useEffect(() => {
    const modalFooter = document.getElementById('voice-modal-footer')

    if (modalFooter) {
      modalFooter.style.display = 'none'
    }

    return () => {
      if (modalFooter) {
        modalFooter.style.display = ''
      }
    }
  }, [])

  return (
    <div className="grid h-[492px] grid-cols-[auto_1fr] gap-[24px] px-[32px] py-[20px]">
      <div className="border-gs01-100 w-[408px] border p-[24px]">
        <div className="text-headline06 mb-[15px]">{t('이름')}</div>

        <div className="mb-[12px]">
          <input
            type="text"
            placeholder={t("목소리 이름을 입력해 주세요")}
            className="border-gs01-200 text-placeholder placeholder:text-placeholder placeholder:text-gs01-300 h-[40px] w-full rounded-[4px] border px-[12px]"
            value={data.name}
            onChange={(e) => changeData({ ...data, name: e.currentTarget.value })}
          />
        </div>

        <div className="bg-gs01-20 text-body02 text-gs01-300 mb-[32px] flex flex-col gap-[5px] rounded-[6px] p-[16px]">
          <div className="flex items-center gap-[4px]">
            <ValidationIcon className={twMerge(validation.character && '[&_path]:fill-green-400')} />
            <span className={twMerge(validation.character && 'text-green-400')}>
              {t('글자는 한글, 로마 알파벳 및 숫자로 제한됩니다.')}
            </span>
          </div>
          <div className="flex items-center gap-[4px]">
            <ValidationIcon className={twMerge(validation.length && '[&_path]:fill-green-400')} />
            <span className={twMerge(validation.length && 'text-green-400')}>{t('15자 이내로 작성할 수 있습니다.')}</span>
          </div>
          <div className="flex items-center gap-[4px]">
            <ValidationIcon className={twMerge(validation.unique && '[&_path]:fill-green-400')} />
            <span className={twMerge(validation.unique && 'text-green-400')}>
              {t('기존 커스텀 목소리 이름과 중복되지 않아야 합니다.')}
            </span>
          </div>
        </div>

        <div className="text-headline06 mb-[4px]">{t('성별')}</div>
        <div className="text-body02 text-gs01-600 mb-[12px]">{t('업로드하는 음성의 성별을 선택해주세요.')}</div>

        <div className="flex gap-[20px]">
          <Radio
            name="gender"
            value="female"
            checked={data.gender === 'female'}
            onChange={(e) => changeData({ ...data, gender: e.target.value as 'male' | 'female' })}
          >
            {t('여성')}
          </Radio>
          <Radio
            name="gender"
            value="male"
            checked={data.gender === 'male'}
            onChange={(e) => changeData({ ...data, gender: e.target.value as 'male' | 'female' })}
          >
            {t('남성')}
          </Radio>
        </div>
      </div>
      <div className="border-gs01-100 flex border py-[24px] pl-[40px] pr-[24px]">
        <div className="w-[296px]">
          <div className="flex items-center gap-[4px]">
            <div className="text-headline06">{t('잡음 제거 강도')}</div>
          </div>
          <div className="bg-gs01-20 text-body01 text-gs01-800 mt-[20px] p-[24px]">
            {t('업로드 음성의 잡음 정도에 따라 잡음 제거 강도를 선택해주세요.')}
            <br />
            <br />
            {t("잡음이 적은 음성은 제거 강도를 '낮음', 보통은 '중간', 심한 경우 '높음'을 선택하시면 됩니다.")}
            <br />
            <br />{t('각 옵션의 샘플은 동일한 음성에 다른 강도의 잡음 제거를 적용한 예시입니다.')}
            <br />
            <br />{t('* 강도가 높을수록 음성이 부자연스러울 수 있습니다')}
          </div>
          <div className="text-headline06 mb-[18px] mt-[32px]">{t('샘플 파일')}</div>
          <audio src={sampleNoise} controls />
        </div>
        <div className="border-gs01-100 mx-[20px] border-l" />
        <div className="flex flex-col justify-between">
          <div>
            <Radio
              name="denoising"
              value={0.3}
              checked={data.denoising === 0.3}
              onChange={(e) => changeData({ ...data, denoising: Number(e.target.value) })}
            >
              {t('낮음')}
            </Radio>
            <div className="text-subtitle02 bg-gs01-50 text-gs01-900 mt-[8px] flex items-center gap-[20px] rounded-[8px] px-[24px] py-[14px]">
              <span>{t('예상 결과')}</span>
              <audio src={sampleNoiseLow} controls />
            </div>
          </div>
          <div>
            <Radio
              name="denoising"
              value={0.6}
              checked={data.denoising === 0.6}
              onChange={(e) => changeData({ ...data, denoising: Number(e.target.value) })}
            >
              {t('중간')}
            </Radio>
            <div className="text-subtitle02 bg-gs01-50 text-gs01-900 mt-[8px] flex items-center gap-[20px] rounded-[8px] px-[24px] py-[14px]">
              <span>{t('예상 결과')}</span>
              <audio src={sampleNoiseMedium} controls />
            </div>
          </div>
          <div>
            <Radio
              name="denoising"
              value={0.9}
              checked={data.denoising === 0.9}
              onChange={(e) => changeData({ ...data, denoising: Number(e.target.value) })}
            >
              {t('높음')}
            </Radio>
            <div className="text-subtitle02 bg-gs01-50 text-gs01-900 mt-[8px] flex items-center gap-[20px] rounded-[8px] px-[24px] py-[14px]">
              <span>{t('예상 결과')}</span>
              <audio src={sampleNoiseHigh} controls />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
