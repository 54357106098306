import { createContext, useCallback, useContext, useMemo, useState } from 'react'
import Confirm from '../components/Dialog/Confirm'
import Prompt from '../components/Dialog/Prompt'

export const DialogContext = createContext({
  dialogList: [],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  addDialog: (dialog) => {},
  removeDialog: () => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setDialogList: (dialogList) => {},
  clearDialogList: () => {},
})

export function DialogProvider({ children }) {
  const [dialogList, setDialogList] = useState([])

  const addDialog = useCallback(
    (dialog) => {
      setDialogList([...dialogList, dialog])
    },
    [dialogList],
  )

  const removeDialog = useCallback(() => {
    console.log('seop dialog', dialogList)
    setDialogList(dialogList.slice(0, Math.max(dialogList.length - 1, 0)))
  }, [dialogList])

  const clearDialogList = useCallback(() => {
    setDialogList([])
  }, [])

  const context = useMemo(
    () => ({
      dialogList,
      addDialog,
      removeDialog,
      setDialogList,
      clearDialogList,
    }),
    [dialogList, addDialog, removeDialog, setDialogList, clearDialogList],
  )

  return (
    <DialogContext.Provider value={context}>
      {children}
      {dialogList}
    </DialogContext.Provider>
  )
}

export default function useDialog() {
  const { dialogList, addDialog, removeDialog, setDialogList, clearDialogList } = useContext(DialogContext)

  const open = useCallback(
    (Dialog) => {
      addDialog(Dialog)
    },
    [addDialog],
  )

  const close = useCallback(() => {
    removeDialog()
  }, [removeDialog])

  //! 주의: 아랫놈들 호출시 default용으로만 사용할것. useDialog 설계가 잘못됐다. 이런식으로 함수호출로 모달 띄우면 안됨
  const restoreOriginalModalList = useCallback(() => {
    setDialogList(dialogList)
  }, [dialogList, setDialogList])

  // 이놈들은 클로저 호출로 모달을 생성하는 특수한 케이스임
  // 따라서 close 함수를 따로 준비해놓고 전달하도록 해야된다.
  const alert = useCallback(
    ({ onClickCancel = restoreOriginalModalList, onClickConfirm = restoreOriginalModalList, ...otherProps }) => {
      addDialog(<Confirm {...otherProps} onClickCancel={onClickCancel} onClickConfirm={onClickConfirm} />)
    },
    [addDialog, restoreOriginalModalList],
  )

  const confirm = useCallback(
    ({ onClickCancel = restoreOriginalModalList, onClickConfirm = restoreOriginalModalList, ...otherProps }) => {
      addDialog(<Confirm {...otherProps} onClickCancel={onClickCancel} onClickConfirm={onClickConfirm} useCancel />)
    },
    [restoreOriginalModalList, addDialog],
  )

  const prompt = useCallback(
    ({ onClickCancel = restoreOriginalModalList, onClickConfirm = restoreOriginalModalList, ...otherProps }) => {
      addDialog(<Prompt {...otherProps} onClickCancel={onClickCancel} onClickConfirm={onClickConfirm} useCancel />)
    },
    [restoreOriginalModalList, addDialog],
  )

  return useMemo(
    () => ({
      open,
      alert,
      confirm,
      prompt,
      close,
      clearDialogList,
    }),
    [alert, close, confirm, open, prompt, clearDialogList],
  )
}
