/* eslint-disable arrow-body-style */
/* eslint-disable import/order */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
import React, {
  ChangeEventHandler,
  MouseEventHandler,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { EditorContent, useEditor } from '@tiptap/react'
import History from '@tiptap/extension-history'
import Document from '@tiptap/extension-document'
import Text from '@tiptap/extension-text'
import { TextSelection } from '@tiptap/pm/state'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
// import { v4 as uuidV4 } from 'uuid'
import { Attrs } from '@tiptap/pm/model'
import { v4 as uuidV4 } from 'uuid'
import Paragraph from './nodes/Paragraph'
import Sentence from './nodes/Sentence'
import Space from './nodes/Space'
import {
  editorValueState,
  focusedBoxState,
  isValidFocusedBoxState,
  isValidSetenceBoxInEditorState,
  selectedBoxIdsState,
  sentenceBoxesWithOptionState,
} from '../../../recoil/texteditor/editor/selectors'
import {
  editorState,
  paragraphBoxIdsState,
  sentenceBoxIdsState,
  selectedParagraphBoxIdsState,
} from '../../../recoil/texteditor/editor/atoms'
import { isPlayingState } from '../../../recoil/audiocontroller/selectors'
// eslint-disable-next-line import/no-cycle
import useSentenceBoxCallbacks from '../../../recoil/texteditor/sentenceBox/useSentenceBoxCallbacks'
import useAivatarModal from '../../../hooks/useAivatarModal'
import SentenceBoxErrorModalV2 from '../TextEditor/SentenceBoxErrorModalV2'
import Checkbox from '../../../components/inputs/Checkbox'
import AivatarButtonV2 from '../../../components/buttons/AivatarButtonV2'
import TrashBin from './components/icons/TrashBinIcon'
import useDialog from '../../../hooks/useDialog'
import { EditorContext } from '../ProjectLayoutV2'
import PlusIcon from './components/icons/PlusIcon'
import { HotkeysContextV2 } from '../../../providers/AivatarHotKeysRootV2'
import useUpdateProject from '../../../services/project/useUpdateProject'
import { defaultVoiceState } from '../../../recoil/workspace/selectors'
import { useTranslation } from 'react-i18next'
import { getSplitedCreatorTexts } from '../../../recoil/texteditor/sentenceBox/sentenceBoxHelpers'
import { debounce, isEqualWith } from 'lodash'
import { useDebounce } from 'react-use'
import useDebouncedCallback from '../../../hooks/useDebouncedCallback'
import { SentenceBoxValuesForFetch } from '../../../recoil/texteditor/sentenceBox/atoms'
import { projectValueState } from '../../../recoil/project/selectors'
import WarningMessage from './components/WarningMessage'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    paragraph: {
      goToLeftSentence: () => ReturnType
      createSentence: () => ReturnType
    }
    sentence: {
      splitSentence: () => ReturnType
      deleteCharacters: (cut?: boolean) => ReturnType
    }
  }
}

function useDeepCompareMemoize(value: any) {
  const previousValueRef = useRef<any>(undefined)

  console.log('seop diff data', previousValueRef.current, value)
  const isSame = useMemo(
    () =>
      isEqualWith(previousValueRef.current, value, (a, b, key) => {
        if (key === 'isSelected' || key === 'sentenceId' || key === 'options') {
          return true
        }

        return undefined
      }),
    [value],
  )

  if (!isSame) {
    previousValueRef.current = value
  }

  return previousValueRef.current
}

function useDeepCompareDebounce(callback: React.EffectCallback, ms: number, dependencies: any[]) {
  const memoizedDependencies = useDeepCompareMemoize(dependencies)
  useDebounce(callback, ms, [memoizedDependencies])
}

export default function TextEditorV2() {
  const sentenceBoxesWithOption = useRecoilValue(sentenceBoxesWithOptionState)
  const setSentenceBoxIds = useSetRecoilState(sentenceBoxIdsState)
  const paragraphBoxIds = useRecoilValue(paragraphBoxIdsState)
  const setParagraphBoxIds = useSetRecoilState(paragraphBoxIdsState)
  const selectedParagraphBoxIds = useRecoilValue(selectedParagraphBoxIdsState)
  const setSelectedParagraphBoxIds = useSetRecoilState(selectedParagraphBoxIdsState)
  const sentenceBoxIds = useRecoilValue(sentenceBoxIdsState)
  const { selectBox, deselectAllBoxes, selectMultipleBoxesCallback, selectBoxesRange } = useSentenceBoxCallbacks()
  const selectedSentenceBoxIds = useRecoilValue(selectedBoxIdsState)
  const editorContainerRef = useRef<HTMLDivElement>(null)
  const [sentenceBoxValues, setSentenceBoxValues] = useRecoilState(SentenceBoxValuesForFetch)
  const { fetchProject } = useUpdateProject()
  const { t, i18n } = useTranslation('project')
  const defaultVoice = useRecoilValue(
    defaultVoiceState({ language: i18n.language.startsWith('ko') ? 'ko-KR' : 'en-US' }),
  )

  const groupedData = useMemo(
    () =>
      sentenceBoxesWithOption.reduce(
        (acc, item) => {
          const lastGroup = acc[acc.length - 1] // 현재까지 생성된 그룹 중 마지막 그룹
          if (!lastGroup || lastGroup.voiceId !== item.options.voiceId || lastGroup.paragraph !== item.paragraph) {
            // 새 그룹 생성
            acc.push({ voiceId: item.options.voiceId as number, paragraph: item.paragraph, items: [item] })
          } else {
            // 현재 그룹에 아이템 추가
            lastGroup.items.push(item)
          }
          return acc
        },
        [] as Array<{ voiceId: number; paragraph: number; items: Array<(typeof sentenceBoxesWithOption)[0]> }>,
      ),
    [sentenceBoxesWithOption],
  )

  const content = useMemo(
    () => ({
      type: 'doc',
      content: groupedData.map((voiceGroup) => ({
        type: 'paragraph',
        attrs: { 'data-voice-id': voiceGroup.voiceId },
        content: voiceGroup.items
          .map((item) => [
            {
              type: 'sentence',
              attrs: { 'data-sentencebox-id': item.id },
              content: [{ type: 'text', text: item.text }],
            },
            { type: 'space' },
          ])
          .flat(),
      })),
    }),
    [groupedData],
  )

  // useDebounce(
  //   () => {
  //     fetchProject(undefined, true)
  //   },
  //   500,
  //   [
  //     //   .map((item) => ({
  //     //   ...item,
  //     //   items: item.items.map((innerItem) => {
  //     //     const { sentenceId, isSelected, ...rest } = innerItem
  //     //     return rest
  //     //   }),
  //     // })),
  //   ],
  // )

  const editor = useEditor({
    extensions: [Document, Paragraph, Sentence, Text, Space, History],
    content,
    // {
    //   type: 'doc',
    //   content,
    //   [
    //   {
    //     type: 'paragraph',
    //     attrs: { 'data-voice-id': 1 },
    //     content: [
    //       { type: 'sentence', attrs: { 'data-sentence-id': 'test' }, content: [{ type: 'text', text: '01234' }] },
    //       { type: 'space', attrs: { 'data-space': '1.5' } },
    //       { type: 'sentence', attrs: { 'data-sentence-id': 'test' }, content: [{ type: 'text', text: '56789' }] },
    //       { type: 'space', attrs: { 'data-space': '1.5' } },
    //     ],
    //   },
    //   {
    //     type: 'paragraph',
    //     content: [
    //       { type: 'sentence', attrs: { 'data-sentence-id': 'test' }, content: [{ type: 'text', text: '01234' }] },
    //       { type: 'space', attrs: { 'data-space': '1.5' } },
    //       { type: 'sentence', attrs: { 'data-sentence-id': 'test' }, content: [{ type: 'text', text: '56789' }] },
    //       { type: 'space', attrs: { 'data-space': '1.5' } },
    //     ],
    //   },
    // ],
    // }
    editorProps: {
      handlePaste: (view, event) => {
        setEditorState((prev) => ({
          ...prev,
          touched: true,
        }))

        const { state } = view
        const { selection, tr } = state
        const { $from, $to } = selection

        const clipboardText = event.clipboardData?.getData('text')
        if (!clipboardText) return false // 붙여넣을 내용이 없으면 기본 동작

        const fromParent = $from.parent
        const toParent = $to.parent

        if (fromParent.type.name === 'sentence' || toParent.type.name === 'sentence') {
          console.log('seop clipboardtext', clipboardText)
          if ($from.pos === $from.start() && $to.pos === $to.end()) {
            console.log('📌 문장 전체 선택됨 → 기존 문장을 새로운 문장으로 교체')

            // 첫 번째 문장만 사용하여 기존 문장을 교체
            const newSentenceNode = state.schema.nodes.sentence?.create(
              { 'data-sentencebox-id': uuidV4() },
              state.schema.text(clipboardText.trim()),
            )

            tr.replaceWith($from.before(), $to.after(), newSentenceNode!)
            view.dispatch(tr) // 트랜잭션 적용
            view.focus() // 에디터로 포커스 이동
            return true
          }

          tr.replaceWith($from.pos, $to.pos, state.schema.text(clipboardText!))
          view.dispatch(tr)
          return true
        }

        // mod + a 선택
        if (paragraphBoxIds.length >= 2 && $from.pos <= 1 && $to.pos >= state.doc.nodeSize - 3) {
          console.log('📌 문장 전체 선택됨 → 기존 문장을 새로운 문장으로 교체')

          // 첫 번째 문장만 사용하여 기존 문장을 교체
          const newSentenceNode = state.schema.nodes.sentence?.create(
            { 'data-sentencebox-id': uuidV4() },
            state.schema.text(clipboardText.trim()),
          )

          tr.replaceWith($from.before(), $to.after(), newSentenceNode!)
          view.dispatch(tr) // 트랜잭션 적용
          view.focus() // 에디터로 포커스 이동
          return true
        }

        event.preventDefault() // 기본 붙여넣기 방지

        const sentences = getSplitedCreatorTexts(clipboardText)
        console.log('seop paste', sentences)

        sentences.forEach((sentence: any) => {
          // 각 문장을 Sentence 노드로 변환하여 삽입
          const sentenceNode = state.schema.nodes.sentence?.create(
            { 'data-sentencebox-id': uuidV4() },
            state.schema.text(sentence.trim()),
          )
          tr.insert(tr.selection.from, sentenceNode!)
        })

        view.dispatch(tr) // 트랜잭션 적용
        view.focus() // 에디터로 포커스 이동

        return true // 이벤트 처리 완료
      },
      handleDOMEvents: {
        //! 블록 선택 한글 입력 대처법: 첫번째에 공백넣기. 현재로썬 이 대처밖에 없다.
        compositionstart: (view, event) => {
          const { state } = view
          const { tr } = state
          const { $from, $to } = state.selection
          // 선택 영역이 현재 블록의 시작부터 끝까지인 경우
          if ($from.pos === $from.start() && $to.pos === $to.end()) {
            // 트랜잭션을 만들어 블록 전체를 삭제합니다.
            // tr.delete(Math.max($from.start() - 2, 0), Math.min(state.doc.nodeSize - 2, $to.end() + 2))
            // const newSentence = state.schema.nodes.sentence!.create({
            //   'data-sentencebox-id': uuidV4(),
            // })

            // // 삽입 위치 계산 (문서의 끝부분)
            // // 트랜잭션 생성 및 적용
            // tr.insert($from.start() - 1, newSentence)
            const newSelection = TextSelection.create(tr.doc, $from.start() + 1)
            tr.setSelection(newSelection)
            tr.replaceWith($from.start(), $from.end(), state.schema.text(' '))
            view.dispatch(tr)
            // 이벤트 처리가 완료되었음을 알립니다.
            return true
          }
          return false
        },

        compositionend: (view) => {
          const { state } = view
          const { tr } = state

          let targetPos = null // 커서를 이동시킬 위치

          console.log('seop compositionend')
          state.doc.descendants((node, pos, parent) => {
            if (parent?.type.name === 'paragraph' && node.type.name === 'text' && node.text) {
              const sentenceNode = state.schema.nodes.sentence!.create(null, state.schema.text(node.text))

              // `sentenceNode`가 삽입될 위치의 끝을 계산
              const endPos = pos + sentenceNode.nodeSize

              tr.replaceWith(pos, pos + node.nodeSize, sentenceNode)

              // 커서를 `sentenceNode`의 끝으로 설정
              targetPos = endPos - 1 // `sentenceNode`의 마지막 text 위치
            }
          })

          console.log('seop trdocchanged in compositionend', tr.docChanged)
          if (tr.docChanged) {
            if (targetPos !== null) {
              const newSelection = TextSelection.create(tr.doc, targetPos)
              tr.setSelection(newSelection)
            }

            view.dispatch(tr) // 변경 사항 적용
          }
        },
        // compositionend: (view) => {
        //   const { state } = view
        //   const { tr } = state

        //   state.doc.descendants((node, pos, parent) => {
        //     // Text 노드만 처리
        //     if (parent?.type.name === 'paragraph' && node.type.name === 'text' && node.text) {
        //       // 텍스트 노드를 Sentence 노드로 감쌉니다.
        //       const sentenceNode = state.schema.nodes.sentence!.create(null, state.schema.text(node.text))
        //       // 트랜잭션을 통해 문서를 업데이트합니다.
        //       tr.replaceWith(pos, pos + node.nodeSize, sentenceNode)
        //     }
        //   })
        //   if (tr.docChanged) {
        //     // view.dispatch(tr) // 변경 사항 적용
        //     const newSelection = TextSelection.create(tr.doc, state.selection.from)
        //     view.dispatch(tr.setSelection(newSelection))
        //   }
        // },
      },
    },
    // eslint-disable-next-line @typescript-eslint/no-shadow
    onUpdate: ({ editor, transaction }) => {
      // transaction.selectionSet이 변화 감지함
      if (transaction.selectionSet) {
        setEditorState((prev) => ({
          ...prev,
          touched: true,
        }))
      }

      const { state, composing, dispatch } = editor.view
      const { tr } = state

      let targetPos = null // 커서를 이동시킬 위치

      state.doc.descendants((node, pos, parent) => {
        if (!composing && parent?.type.name === 'paragraph' && node.type.name === 'text' && node.text) {
          const sentenceNode = state.schema.nodes.sentence!.create(null, state.schema.text(node.text))

          // `sentenceNode`가 삽입될 위치의 끝을 계산
          const endPos = pos + sentenceNode.nodeSize

          tr.replaceWith(pos, pos + node.nodeSize, sentenceNode)

          // 커서를 `sentenceNode`의 끝으로 설정
          targetPos = endPos - 1 // `sentenceNode`의 마지막 text 위치
        }
      })

      // setSentenceBoxIds(sentenceBoxIds)
      // console.log('seop sentenceboxids inner', sentenceBoxIds)

      console.log(
        'seop trdocchanged in update',
        tr.docChanged,
        tr.selectionSet,
        transaction.selectionSet,
        transaction.docChanged,
      )
      if (tr.docChanged) {
        if (targetPos !== null) {
          const newSelection = TextSelection.create(tr.doc, targetPos)
          tr.setSelection(newSelection)
        }

        dispatch(tr) // 변경 사항 적용
      }
    },
    onTransaction: ({ editor, transaction }) => {
      const { state } = editor.view
      const { tr, selection } = state

      const sentenceBoxIds = [] as string[]
      const paragraphBoxIds = [] as string[]
      const selectedSentencesBoxIdsByParagraphCheck = [] as string[]
      const selectedParagraphBoxIds = [] as string[]

      // if (!focusedBoxId) {
      deselectAllBoxes()
      // }
      state.doc.descendants((node, pos, parent) => {
        if (node.type.name === 'sentence') {
          const sentenceBoxId = node.attrs['data-sentencebox-id']
          if (sentenceBoxId) {
            sentenceBoxIds.push(sentenceBoxId)

            if (parent?.attrs['data-checked'] === 'true') {
              selectedSentencesBoxIdsByParagraphCheck.push(sentenceBoxId)
            }
          } else {
            const generatedSentenceBoxId = uuidV4() // 고유한 ID 생성 함수
            const updatedAttrs = { ...node.attrs, 'data-sentencebox-id': generatedSentenceBoxId }

            // 노드 속성 업데이트
            tr.setNodeMarkup(pos, undefined, updatedAttrs)
          }
        }
        if (node.type.name === 'paragraph') {
          const paragraphBoxId = node.attrs['data-paragraphbox-id']
          if (paragraphBoxId) {
            paragraphBoxIds.push(paragraphBoxId)
            if (node.attrs['data-checked'] === 'true') {
              selectedParagraphBoxIds.push(paragraphBoxId)
            } else {
              setParagraphAllChecked(false)
            }
          } else {
            const generatedParagraphBoxId = uuidV4() // 고유한 ID 생성 함수
            const updatedAttrs = { ...node.attrs, 'data-paragraphbox-id': generatedParagraphBoxId }

            // 노드 속성 업데이트
            tr.setNodeMarkup(pos, undefined, updatedAttrs)
          }

          const voiceId = node.attrs['data-voice-id']
          if (!voiceId && sentenceBoxValues.voiceId && defaultVoice?.id) {
            const updatedAttrs = { ...node.attrs, 'data-voice-id': sentenceBoxValues.voiceId ?? defaultVoice.id }

            // 노드 속성 업데이트
            tr.setNodeMarkup(pos, undefined, updatedAttrs)
          }
        }
      })

      //! 커서를 문장에 뒀을때 선택되도록
      // 커서 위치에서 노드 확인
      let startCursor = state.doc.resolve(selection.from)
      let endCursor = state.doc.resolve(selection.to)
      console.log('seop start end', startCursor, endCursor)
      if (startCursor.pos > 1 && endCursor.pos > 1) {
        if (startCursor.pos === endCursor.pos) {
          // empty
          const cursorNode = startCursor.node(startCursor.depth)

          if (cursorNode.type.name === 'sentence') {
            const sentenceBoxId = cursorNode.attrs['data-sentencebox-id']
            selectBox({ id: sentenceBoxId, isSelected: true })
          } else {
            deselectAllBoxes()
          }
        } else {
          while (startCursor.nodeAfter && startCursor.nodeAfter?.type.name !== 'text') {
            startCursor = state.doc.resolve(startCursor.pos + 1)
          }
          while (endCursor.nodeAfter && endCursor.nodeAfter?.type.name !== 'text') {
            endCursor = state.doc.resolve(Math.max(endCursor.pos - 1))
          }

          const startNode = startCursor.node(startCursor.depth)
          const endNode = endCursor.node(endCursor.depth)

          if (startNode && startNode.type.name === 'sentence') {
            const sentenceBoxId = startNode.attrs['data-sentencebox-id']
            selectBox({ id: sentenceBoxId, isSelected: true })
          }

          if (startNode && startNode.type.name === 'sentence' && endNode) {
            const startSentenceBoxId = startNode.attrs['data-sentencebox-id']
            let endSentenceBoxId: string

            if (endNode.type.name === 'sentence') {
              endSentenceBoxId = endNode.attrs['data-sentencebox-id']
            } else {
              // paragraph. 셀렉션 마지막이 문장 말고 그냥 문단 끝에 걸쳐있을때 마지막 문장을 가져온다.
              const { childCount } = endNode
              if (childCount - 2 >= 0) {
                endSentenceBoxId = endNode.child(childCount - 2).attrs['data-sentencebox-id']
              } else {
                endSentenceBoxId = startSentenceBoxId
              }
            }

            selectBoxesRange({ fromId: startSentenceBoxId, toId: endSentenceBoxId, isSelected: true })
          }
        }
      } else {
        const startNode = startCursor.node(startCursor.depth)
        const endNode = endCursor.node(endCursor.depth)

        console.log('seop start end', startNode, endNode)
        // 전체선택
        if (
          startCursor.pos !== endCursor.pos &&
          startNode.type.name === 'paragraph' &&
          endNode &&
          endNode.type.name === 'paragraph'
        ) {
          selectBoxesRange({
            fromId: sentenceBoxIds.at(0)!,
            toId: sentenceBoxIds.at(-1)!,
            isSelected: true,
          })
        }
      }

      //! 문단을 선택했을때 선택처리
      selectMultipleBoxesCallback({ boxIds: selectedSentencesBoxIdsByParagraphCheck, isSelected: true })
      setSentenceBoxIds(sentenceBoxIds)
      setParagraphBoxIds(paragraphBoxIds)
      setSelectedParagraphBoxIds(selectedParagraphBoxIds)
      // if (focusedBoxId) {
      //   selectBox({i})
      // }

      console.log(
        'seop tr in trchange',
        tr.docChanged,
        tr.selectionSet,
        transaction.docChanged,
        transaction.selectionSet,
      )
      if (tr.docChanged || tr.selectionSet) {
        editor.view.dispatch(tr)
      }
      return true
    },
    onSelectionUpdate: ({ editor: { view, state } }) => {
      const { selection, tr } = state

      // // 커서 위치에서 노드 확인
      // let startCursor = state.doc.resolve(selection.from)
      // let endCursor = state.doc.resolve(selection.to)
      // if (startCursor.pos === endCursor.pos) {
      //   // empty
      // } else {
      //   while (startCursor.nodeAfter && startCursor.nodeAfter?.type.name !== 'text') {
      //     startCursor = state.doc.resolve(startCursor.pos + 1)
      //   }
      //   while (endCursor.nodeAfter?.type.name !== 'text') {
      //     endCursor = state.doc.resolve(endCursor.pos - 1)
      //   }
      // }
      // const startNode = startCursor.node(startCursor.depth)
      // const endNode = endCursor.node(endCursor.depth)

      // if (startNode && startNode.type.name === 'sentence') {
      //   const sentenceBoxId = startNode.attrs['data-sentencebox-id']
      //   selectBox({ id: sentenceBoxId, isSelected: true })
      // }

      // if (startNode && startNode.type.name === 'sentence' && endNode && endNode.type.name === 'sentence') {
      //   const startSentenceBoxId = startNode.attrs['data-sentencebox-id']
      //   const endSentenceBoxId = endNode.attrs['data-sentencebox-id']

      //   selectBoxesRange({ fromId: startSentenceBoxId, toId: endSentenceBoxId, isSelected: true })
      // }

      // 삭제할 위치를 기록
      const unCheckParagraphs: { pos: number; attrs: Attrs }[] = []
      // 문서의 모든 노드를 순회
      state.doc.descendants((node, pos) => {
        // 특정 노드 타입 (예: paragraph)에 대해서만 처리
        if (node.type.name === 'paragraph') {
          // 속성을 업데이트
          unCheckParagraphs.push({ pos, attrs: { ...node.attrs, 'data-checked': 'false' } })
        }
      })

      console.log('seop selectionupdate')

      unCheckParagraphs.reverse().forEach(({ pos, attrs }) => {
        tr.setNodeMarkup(pos, undefined, attrs)
      })
      setParagraphAllChecked(false)

      // 트랜잭션을 적용하여 속성 변경 반영
      if (tr.docChanged) {
        view.dispatch(tr)
      }
    },
    // onCreate: ({ editor: editorInstance }) => {
    //   const { schema } = editorInstance.view.state

    //   if (!schema.nodes.sentence) throw Error('sentence node가 정의되어있지 않습니다.')

    //   editorInstance.registerPlugin(
    //     inputRulesPlugin({
    //       editor: editorInstance,
    //       rules: [nodeInputRule({ find: /.{2,}/, type: schema.nodes.sentence })],
    //     }),
    //   )
    // },
  })

  // const getSentenceNodes = (editor: Editor) => {
  //   const { doc } = editor.state

  //   const sentenceBoxIds = [] as string[]
  //   doc.descendants((node) => {
  //     if (node.type.name === 'sentence') {
  //       const sentenceBoxId = node.attrs['data-sentencebox-id']
  //       if (sentenceBoxId) {
  //         sentenceBoxIds.push(sentenceBoxId)
  //       }
  //     }
  //   })

  //   return sentenceBoxIds
  // }

  // React state가 변경되면 Tiptap 콘텐츠 업데이트: 단방향, 조건을 살펴보면 주로 초기화에만 사용할 것이라는걸 알 수 있음
  useEffect(() => {
    editor?.commands.setContent(content)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isPlaying = useRecoilValue(isPlayingState)

  const isAvailable = useMemo(() => !isPlaying, [isPlaying])

  const { playButtonRef } = useContext(HotkeysContextV2)

  const handleBackdropClicked = () => {
    if (!isAvailable) {
      console.log('seop playbutton', playButtonRef)
      ;(playButtonRef as any).current?.click()
    }
  }

  /** Modal */
  const onClickClose = () => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    closeSentenceBoxErrorModal()
  }
  const {
    modalComponent: SentenceBoxErrorModalComponent,
    openModal: openSentenceBoxErrorModal,
    closeModal: closeSentenceBoxErrorModal,
  } = useAivatarModal({
    children: <SentenceBoxErrorModalV2 type onClickClose={onClickClose} />,
    closeOnDocumentClick: false,
    contentStyle: {
      marginTop: '3%',
      padding: 0,
      width: 'fit-content',
      backgroundColor: 'transparent',
      // pointerEvents: "none"
    },
    overlayStyle: {
      // pointerEvents: "none",
      backgroundColor: 'transparent',
    },
  })

  const isValidSetenceBoxInEditor = useRecoilValue(isValidSetenceBoxInEditorState)
  const [errorModalInitialized, setErrorModalInitialized] = useState(false)
  const focusedBoxId = useRecoilValue(editorValueState({ key: 'focusedBoxId' }))
  const isValidFocusedBox = useRecoilValue(isValidFocusedBoxState)

  useEffect(() => {
    if (!errorModalInitialized && !isValidSetenceBoxInEditor) {
      openSentenceBoxErrorModal()
      setErrorModalInitialized(true)
    }

    if (!isValidFocusedBox) {
      openSentenceBoxErrorModal()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidSetenceBoxInEditor, focusedBoxId])

  const setEditorState = useSetRecoilState(editorState)

  const handleCheckboxChanged: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { view } = editor ?? {}
    const { state } = view ?? {}
    if (!state) return
    if (!view) return

    const { tr } = state

    // 문서의 모든 노드를 순회
    state.doc.descendants((node, pos) => {
      // 특정 노드 타입 (예: paragraph)에 대해서만 처리
      if (node.type.name === 'paragraph') {
        const updatedAttrs = {
          ...node.attrs, // 기존 속성 유지
          'data-checked': e.currentTarget.checked ? 'true' : 'false', // 새로운 속성 추가
        }

        // 속성을 업데이트
        tr.setNodeMarkup(pos, undefined, updatedAttrs)
      }
    })
    setParagraphAllChecked(e.target.checked)

    setEditorState((prev) => ({
      ...prev,
      focusedBoxId: null,
    }))

    // 트랜잭션을 적용하여 속성 변경 반영
    if (tr.docChanged) {
      view.dispatch(tr)
    }
  }

  // const checked = useMemo(() => {
  //   const { view } = editor ?? {}
  //   const { state } = view ?? {}
  //   if (!state) return false
  //   if (!view) return false

  //   let result = true
  //   // 문서의 모든 노드를 순회
  //   state.doc.descendants((node, pos) => {
  //     // 특정 노드 타입 (예: paragraph)에 대해서만 처리
  //     if (node.type.name === 'paragraph') {
  //       console.log('seop checked', node.attrs['data-checked'])
  //       if (node.attrs['data-checked'] === 'false') {
  //         result = false
  //       }
  //     }
  //   })

  //   return result
  // }, [editor])
  const handleDeleteClicked = () => {
    const { view } = editor ?? {}
    const { state } = view ?? {}
    if (!state) return
    if (!view) return

    const { tr } = state

    // 삭제할 위치를 기록
    const deletePositions: { from: number; to: number }[] = []

    // 문서 전체를 순회하며 삭제할 위치를 기록
    state.doc.descendants((node, pos) => {
      if (node.type.name === 'paragraph' && node.attrs['data-checked'] === 'true') {
        deletePositions.push({ from: pos, to: pos + node.nodeSize })
      }
    })

    // 삭제 위치를 역순으로 처리
    deletePositions.reverse().forEach(({ from, to }) => {
      tr.delete(from, to)
    })

    // 트랜잭션 적용
    if (tr.docChanged) {
      view.dispatch(tr)
    }
  }

  const handleNewParagraphButtonClicked = () => {
    const { view } = editor ?? {}
    const { state } = view ?? {}
    if (!state) return
    if (!view) return

    /// 문서의 마지막 문단 찾기
    // let lastVoiceId = null
    // state.doc.descendants((node) => {
    //   if (node.type.name === 'paragraph' && node.attrs['voice-id']) {
    //     lastVoiceId = node.attrs['voice-id'] // 가장 마지막 문단의 voice-id 저장
    //   }
    // })

    console.log('seop newvoice', sentenceBoxValues)
    // 새로운 문단 생성
    const newParagraph = state.schema.nodes.paragraph!.create({
      'data-voice-id': sentenceBoxValues.voiceId,
    })

    // 삽입 위치 계산 (문서의 끝부분)
    const insertPosition = state.doc.content.size
    // 트랜잭션 생성 및 적용
    const tr = state.tr.insert(state.doc.content.size, newParagraph)
    tr.setSelection(TextSelection.create(tr.doc, insertPosition + 1)) // 포커스를 새 문단으로 이동 (+1은 텍스트 삽입 위치 조정)
    tr.scrollIntoView()
    view.dispatch(tr) // 새로운 문단으로 스크롤 이동
    view.focus()

    if (editorContainerRef.current) {
      editorContainerRef.current.scrollTop = editorContainerRef.current.scrollHeight
    }
  }

  console.log('seop', groupedData)
  console.log('seop schema', content.content)
  console.log('seop schema JSON', editor?.getJSON())
  console.log('seop schema json equal', editor && JSON.stringify(editor.getJSON()) === JSON.stringify(content))
  console.log('seop sentenceboxids', sentenceBoxIds)
  console.log('seop selected sentence box ids:', selectedSentenceBoxIds)
  console.log('seop focused box id', focusedBoxId)
  console.log('seop paragraphboxids', paragraphBoxIds)

  const [paragraphAllChecked, setParagraphAllChecked] = useState(false)

  const dialog = useDialog()

  const { setEditor } = useContext(EditorContext)
  useEffect(() => {
    console.log('seop useeffect', editor, setEditor)
    if (editor) setEditor(editor)
  }, [editor, setEditor])

  return (
    <>
      {SentenceBoxErrorModalComponent}

      <div className="relative h-full w-full">
        <div className="bg-gs01-50 absolute left-0 right-0 top-0 flex h-[50px] items-center justify-between rounded-[8px] px-[20px]">
          <div className="flex gap-[20px]">
            <Checkbox onChange={handleCheckboxChanged} checked={paragraphAllChecked} />
            <WarningMessage />
          </div>
          <AivatarButtonV2
            variant="outlined-rounded"
            size="M"
            onClick={() =>
              dialog.confirm({
                title: t('문단 삭제하기'),
                message: t('해당 문단을 삭제하시겠습니까?'),
                confirmButtonName: t('삭제'),
                onClickConfirm: () => {
                  dialog.close()
                  handleDeleteClicked()
                },
              })
            }
            disabled={selectedParagraphBoxIds.length === 0}
            className="disabled:!border-gs01-500 [&_path]:disabled:!fill-gs01-500"
          >
            <span>{t('선택 삭제하기')}</span>
            <TrashBin className="h-[16px] w-[16px]" />
          </AivatarButtonV2>
        </div>
        <div className="h-full pt-[50px]">
          <div id="editor-area" className="max-h-full w-full overflow-y-auto" ref={editorContainerRef}>
            <div className="relative">
              {!isAvailable && (
                <button
                  id="button-cancel-play"
                  type="button"
                  onClick={handleBackdropClicked}
                  aria-label="재생취소"
                  className="absolute bottom-0 left-0 right-0 top-0 z-[999] bg-[#ffffff00]"
                />
              )}
              <EditorContent
                editor={editor}
                className="!focus-visible:outline-none h-full w-full border-none [&>.tiptap]:h-full [&_.ProseMirror]:outline-none"
                onKeyDown={(e) => {
                  if ((e.metaKey || e.ctrlKey) && e.key.toLowerCase() === 's') {
                    e.preventDefault()
                    fetchProject()
                    return true
                  }
                  return true
                }}
              />
              <AivatarButtonV2
                variant="outlined-square"
                size="L"
                type="button"
                className="mt-[20px] w-full"
                onClick={handleNewParagraphButtonClicked}
              >
                <span>{t('문단 추가')}</span>
                <PlusIcon />
              </AivatarButtonV2>
            </div>
          </div>
        </div>
        {/* <button type="button" className="border-main-1 rounded-[8px] border">
          <span className="text-button01">문단 추가</span>
        </button> */}
      </div>
    </>
  )
}
