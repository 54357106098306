import AivatarClient from '../AivatarClient'
import { PaginationParams } from '../types'

export async function getAudioExportList(workspaceId: number, params: PaginationParams) {
  const response = await AivatarClient().get(`/audios/workspace/${workspaceId}`, { params })

  return response.data
}

export async function voiceConversion({
  workspaceId,
  name,
  gender,
  denosing,
  userFile,
}: {
  workspaceId: number
  name: string
  gender: 'male' | 'female'
  denosing: number
  userFile: File
}) {
  const response = await AivatarClient().postForm(`/audios/${workspaceId}/voice-conversion`, {
    name,
    gender,
    denosing,
    userFile,
  })

  return response.data
}

export async function getVoiceConversionList(workspaceId: number) {
  const response = await AivatarClient().get(`/audios/${workspaceId}/voice-conversion`)

  return response.data
}
