import React from 'react'

export default function IconStarFilled() {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.7836 1.9748L12.5669 7.5748C12.6086 7.6998 12.7253 7.78314 12.8503 7.78314H18.6336C18.9253 7.78314 19.0419 8.1498 18.8086 8.3248L14.1169 11.7998C14.0169 11.8748 13.9669 12.0081 14.0086 12.1331L15.8003 17.7498C15.8919 18.0248 15.5669 18.2581 15.3336 18.0831L10.6669 14.6248C10.5586 14.5498 10.4169 14.5498 10.3086 14.6248L5.64194 18.0831C5.4086 18.2581 5.09194 18.0248 5.17527 17.7498L6.96694 12.1331C7.0086 12.0081 6.96694 11.8748 6.8586 11.7998L2.16694 8.3248C1.9336 8.1498 2.0586 7.78314 2.34194 7.78314H8.12527C8.2586 7.78314 8.36694 7.6998 8.4086 7.5748L10.1919 1.9748C10.2836 1.6998 10.6753 1.6998 10.7586 1.9748H10.7836Z"
        fill="white"
      />
    </svg>
  )
}
