/* eslint-disable @typescript-eslint/no-unused-vars */
import { mergeAttributes, Node } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import SpaceComponent from './SpaceComponent'

const Space = Node.create({
  name: 'space',
  inline: true,
  group: 'inline',
  selectable: false,
  atom: true, // 이 노드를 하나의 블록처럼 다루도록 설정
  // addAttributes() {
  //   return {
  //     'data-sentencebox-id': {
  //       parseHTML: (element) => element.getAttribute('data-sentencebox-id'),
  //     },
  //     'data-space': {
  //       default: 0.5,
  //     },
  //   }
  // },
  // content: 'text*',

  // renderText({ node }) {
  //   console.log(node)
  //   return `@${node.attrs['data-space']}`
  // },

  renderHTML({ HTMLAttributes, node }) {
    return ['space-component', mergeAttributes(HTMLAttributes), ` `] // 시각적으로 공간을 제공
  },

  addNodeView() {
    return ReactNodeViewRenderer(SpaceComponent)
  },
})

export default Space
