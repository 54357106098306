import React from 'react'

export default function IconProBadge() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="16" rx="2" fill="url(#paint0_linear_822_2205)" />
      <path
        d="M3.28512 7.73519C6.01012 7.22562 6.07512 5.38715 6.54512 3.164C6.60012 2.89423 6.89512 2.89423 6.95012 3.164C7.42012 5.38215 7.52512 7.41046 10.2101 7.73519C10.4201 7.76017 10.4101 8.13985 10.2101 8.21979C8.65512 8.83428 7.35512 9.169 6.95012 12.791C6.92012 13.0658 6.60012 13.0608 6.54512 12.791C6.07512 10.5728 5.96012 9.164 3.28512 8.21979C3.08512 8.14985 3.07512 7.77516 3.28512 7.73519Z"
        fill="white"
      />
      <path
        d="M9.68495 10.5279C10.965 10.2881 10.995 9.42882 11.21 8.38469C11.235 8.25979 11.375 8.25979 11.4 8.38469C11.62 9.42382 11.67 10.373 12.925 10.5279C13.025 10.5379 13.02 10.7177 12.925 10.7527C12.195 11.0425 11.59 11.1973 11.4 12.8959C11.385 13.0258 11.235 13.0208 11.21 12.8959C10.99 11.8568 10.935 11.1973 9.68495 10.7527C9.58995 10.7177 9.58495 10.5429 9.68495 10.5279Z"
        fill="white"
      />
      <defs>
        <linearGradient id="paint0_linear_822_2205" x1="14" y1="16" x2="4.5" y2="2.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#18C95D" />
          <stop offset="1" stopColor="#0086FF" />
        </linearGradient>
      </defs>
    </svg>
  )
}
