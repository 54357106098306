import React from 'react'

export default function IconWarning() {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 2.625C9.51 2.625 2.625 9.51 2.625 18C2.625 26.49 9.51 33.375 18 33.375C26.49 33.375 33.375 26.49 33.375 18C33.375 9.51 26.49 2.625 18 2.625ZM18.69 9.15C18.96 9.15 19.185 9.375 19.185 9.66L19.02 21.75H16.98L16.815 9.66C16.815 9.39 17.04 9.15 17.31 9.15H18.675H18.69ZM18 26.85C17.145 26.85 16.425 26.145 16.455 25.275C16.425 24.42 17.16 23.73 18 23.73C18.84 23.73 19.545 24.435 19.545 25.275C19.545 26.115 18.84 26.85 18 26.85Z"
        fill="#485060"
      />
    </svg>
  )
}
