import { atom } from 'recoil'
import { CALL_STATE } from '../../../services/constants'

export const sentenceBoxIdsState = atom({
  key: 'sentenceBoxIdsState',
  default: [] as string[], // sentenceBoxState Ids
})

export const editorState = atom({
  key: 'editorState',
  default: {
    enableCmdSelect: false,
    enableShiftSelect: false,
    focusedBoxId: null,

    // v2
    //! paragraph 초기화하는 과정에서 문조건 sentenceid 초기화되므로 true로 시작해야됨
    touched: true,
  },
})

export const editorAudioState = atom({
  key: 'editorAudioState',
  default: {
    played: 0,
    playing: false,
    audioCreationState: CALL_STATE.NEW,
  },
})

// v2
export const paragraphBoxIdsState = atom({
  key: 'paragraphBoxIdsState',
  default: [] as string[],
})

export const selectedParagraphBoxIdsState = atom({
  key: 'selectedParagraphBoxIdsState',
  default: [] as string[],
})
